import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaskedInput from "react-text-mask";
import MiniPreload from "../preload/MiniPreload";
import * as staticActions from "../../redux/actions/staticActions";

class PhoneDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = createRef();
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.props.actions.getPhoneCode();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      !this.dropdownRef.current ||
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ isOpen: null }); // Dışarı tıklanınca kapat
    }
  };

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option) => {
    this.props.actions.selectPhoneCode(option);
    this.setState({
      isOpen: false,
    });
  };

  phoneCodes() {
    return this.props.phoneCode.map((code) => (
      <li
        className="option"
        key={code.code}
        onClick={() => this.handleOptionClick(code)}
      >
        <div className="img-drop">
          <img className="non_select" src={code.flag} alt={code.code} />
        </div>
        <div className="txt">
          <p className="b_2 --m non_select">{code.dial_code}</p>
        </div>
      </li>
    ));
  }

  render() {
    return (
      <div className="phone-dropdown" ref={this.dropdownRef}>
        <div
          className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
          onClick={(e) => this.toggleDropdown(e)}
        >
          <div className="content-drop">
            <div className="img-drop">
              <img
                className="non_select"
                src={this.props.selectedPhoneCode.flag}
                alt={this.props.selectedPhoneCode.code}
              />
            </div>
            <div className={`arrow ${this.state.isOpen ? "open" : ""}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
              </svg>
            </div>
            <div className="txt">
              <p className="b_2 --m non_select">
                {this.props.selectedPhoneCode.dial_code}
              </p>
            </div>
          </div>
        </div>
        {this.state.isOpen && (
          <ul className="options hide-scroll">
            {this.props.phoneCode.length === 0 ? (
              <MiniPreload />
            ) : (
              this.phoneCodes()
            )}
          </ul>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    phoneCode: state.getPhoneCodesReducer,
    selectedPhoneCode: state.selectPhoneCodeReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getPhoneCode: bindActionCreators(staticActions.getPhoneCode, dispatch),
      selectPhoneCode: bindActionCreators(
        staticActions.selectPhoneCode,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneDropdown);
