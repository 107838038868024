import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as userActions from "../../redux/actions/userActions";
import * as appJs from "../../js/app";
import MiniPreload from "../preload/MiniPreload";
import Match from "../components/matches/Match";
import { URL_MATCHES } from "../../config";

class Prediction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      btnLoad: false,
      btnDeleteLoad: false,
    };
  }

  componentDidMount() {
    this.tokenControl();
    if (appJs.tokenControl(this.props.token)) {
      this.props.actions.getBasket(this.props.token).then(() => {
        // console.log(this.props.basket);
      });
      this.props.actions.getUserDetails(this.props.token).then(() => {
        // console.log(this.props.userDetails);
      });
    }
  }

  tokenControl() {
    if (appJs.tokenControl(this.props.token)) {
      this.setState({
        isRedirect: false,
      });
    } else {
      this.setState({
        isRedirect: true,
      });
    }
  }

  deleteToBasket = (matchId) => {
    this.setState({ btnDeleteLoad: matchId });
    this.props.actions
      .deleteToBasket({ match_id: matchId }, this.props.token)
      .then(() => {
        this.setState({ btnDeleteLoad: false });
        window.location.reload();
      });
  };

  createPrediction = () => {
    this.setState({ btnLoad: true });
    this.props.actions.createPrediction(this.props.token).then((e) => {
      this.setState({ btnLoad: false });
      if (e.status === 200) {
        window.location.reload();
      }
    });
  };

  render() {
    // if (this.state.isRedirect) return <Navigate to="/login" />;

    return this.props.basket.status === 200 ? (
      <div className="prediction_page">
        <h5 className="hood h_5">Tahmin Paketi</h5>
        {this.props.basket.result.basket.length <= 0 ? (
          <p className="hood h_8">
            Bu sayfada tahmin ettiğiniz maç sonuçlarını görebileceksiniz. Tahmin
            yaparak puan kazanabilir ve ödülleri kapabilirsiniz!
          </p>
        ) : (
          <></>
        )}
        <div
          className="prediction_page_content"
          style={
            this.props.basket.result.basket.length <= 0
              ? { alignItems: "center" }
              : {}
          }
        >
          <div
            className="right_to_and_points"
            style={
              this.props.basket.result.basket.length <= 0
                ? { justifyContent: "center" }
                : {}
            }
          >
            <div className="right_to_predictions">
              <div className="hood">
                <figure>
                  {/* <img src="/img/package.png" alt="Prediction Img" /> */}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 290">
                    <path d="M275.1,289.5c-1.7,0-3.5-.2-5.3-.6-1.8-.4-3.6-1.2-5.4-2.4l-50.3-29.2c-3.2-2.1-5.7-4.7-7.5-7.8-1.8-3.1-2.7-6.7-2.7-10.6v-57.8c0-3.9.9-7.4,2.7-10.6,1.8-3.1,4.3-5.8,7.5-7.8l50.3-29.5c1.8-.9,3.6-1.6,5.4-2,1.8-.4,3.6-.6,5.3-.6s3.4.3,5.1.9c1.7.6,3.4,1.2,5.1,1.7l50.3,29.5c3.3,2.1,5.9,4.7,7.8,7.8,2,3.1,2.9,6.7,2.9,10.6v57.8c0,3.9-1,7.4-2.9,10.6-2,3.1-4.6,5.8-7.8,7.8l-50.3,29.2c-1.7.9-3.5,1.6-5.2,2.1-1.7.6-3.4.8-5.1.8ZM3.6,261.4v-29c0-9.4,2.3-17.3,7-23.8,4.7-6.5,11.5-11.9,20.5-16.1,19.7-8.9,37.9-15.5,54.7-20,16.8-4.4,36.2-6.6,58-6.6h4.1c1.5,0,2.9-.1,4.3-.4-4.7,11.6-6.8,28-6.4,49.1.4,21.1,3.5,36.7,9.3,46.9,0,0-151.6,0-151.6,0ZM143.8,116c-16.5,0-30.3-5.4-41.3-16.3-11-10.9-16.5-24.7-16.5-41.5s5.5-30.3,16.5-41.3C113.5,6,127.3.5,143.8.5s30.3,5.5,41.3,16.5c11,11,16.5,24.8,16.5,41.3s-5.5,30.6-16.5,41.5c-11,10.9-24.8,16.3-41.3,16.3ZM229.1,171.5l46.1,27,46.1-27-46.1-26.5-46.1,26.5ZM283.1,270.2l48-27.3v-58.5l-48,29.1v56.7ZM219.3,242.9l48.3,28v-56.9l-48.3-28.6v57.5Z" />
                  </svg>
                </figure>
                <h6 className="h_8">
                  Kalan Paket Hakkı
                  <span className="info">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                      </svg>
                    </figure>
                    <span className="modal b_4 non_select">
                      Her Salı Saat 05:00'da Yenilenir.
                    </span>
                  </span>
                </h6>
              </div>
              <p className="remaining_prediction h_8">
                {this.props.userDetails.coupons_right} / Hafta
              </p>
            </div>
            {this.props.basket.result.basket.length > 0 ? (
              <div className="total_points">
                <div className="hood">
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 380"
                    >
                      <path d="M70.3,379.4v-141.8L2.7,127.9,81.1.6h157.7l78.5,127.3-67.6,109.7v141.8l-89.7-31.1-89.7,31.1ZM89.1,15.9L19.9,127.9l69.2,112h141.7l69.2-112L230.9,15.9H89.1ZM139.1,191.8l-53.4-53,11.1-11.1,42.4,42.4,84.2-84.7,11.1,10.6-95.3,95.8Z" />
                    </svg>
                  </figure>
                  <h6 className="h_8">
                    Kazanılacak Toplam Puan
                    <span className="info">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                        </svg>
                      </figure>
                      <span
                        className="modal b_4 non_select"
                        style={{ width: "10vw" }}
                      >
                        Tüm tahminler doğru olduğu durumda, katsayılar dahilinde
                        kazanılacak puan
                      </span>
                    </span>
                  </h6>
                </div>
                <p className="remaining_prediction h_8">
                  {this.props.basket.result.total_points}
                </p>
              </div>
            ) : null}
            <div className="match_count">
              <div className="hood">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360">
                    <path d="M146.1,340.9l6.8-28.6c1.2-4.4,3.3-8.2,6.4-11.4,3.1-3.2,7-5,11.7-5.3l64.1-5c4.5-.3,8.6.6,12.5,2.8,3.8,2.2,6.6,5.3,8.2,9.4l6.9,19.5c13.5-8.1,25.5-17.7,36.2-28.6,10.6-10.9,19.4-23.2,26.4-37l-5-3.6c-4.1-2.7-6.9-6-8.6-10-1.7-4-2-8.2-1.1-12.5l14.2-63.2c.9-4.1,3.1-7.5,6.4-10.2,3.4-2.6,7.1-4,11.2-4-1.7-8.7-3.8-17.2-6.5-25.6-2.7-8.4-6.2-16.5-10.5-24.4-2.7,1.7-5.7,2.6-9,2.6s-6.5-1-9.6-3.1l-54.4-32.6c-3.7-2.3-6.3-5.5-8-9.5-1.7-4-2-8.4-1.1-13l4.5-17.2c-10.7-5.1-21.7-8.8-33-11.2-11.3-2.4-22.9-3.5-34.7-3.5s-10,.1-14.9.4c-4.9.3-9.7.8-14.5,1.4l15.4,35.2c1.7,4.3,2.1,8.7,1,13.1s-3.4,8.1-6.8,11.2l-48.3,42.4c-3.5,2.6-7.5,4.1-12,4.4-4.5.4-8.8-.6-12.9-3.1l-46.3-28c-8.4,13.1-14.7,27.1-18.8,41.8-4.2,14.8-6.2,29.9-6.2,45.3s.7,14.6,2,26.6l45.4-4.5c4.7-.4,8.9.6,12.8,2.9,3.9,2.4,6.9,5.6,8.9,9.8l24,59.1c1.7,4.1,2.2,8.2,1.4,12.3-.8,4.1-3,7.7-6.4,10.8l-19,16.8c9.1,6.7,18.7,12.2,28.9,16.7,10.2,4.5,21,7.9,32.4,10.3ZM183.6,252.2c-4.7.3-9-.6-12.8-2.8-3.8-2.2-6.6-5.5-8.4-9.8l-28-63.7c-1.7-4.3-1.9-8.7-.6-13,1.2-4.3,3.7-7.8,7.5-10.5l52.4-44.7c3.1-3.3,6.8-5,11.2-4.9,4.4,0,8.6,1.1,12.7,3.2l57.4,34.3c3.7,2.3,6.6,5.5,8.5,9.5,1.9,4,2.4,8.3,1.4,12.7l-15.7,67.2c-1.1,4.3-3.2,7.9-6.5,10.9-3.2,3-6.8,4.7-10.7,5,0,0-68.2,6.7-68.2,6.7ZM180.1,359.7c-24.9,0-48.2-4.7-70.1-14.1-21.9-9.4-40.9-22.2-57.1-38.4-16.2-16.2-29-35.2-38.4-57C5,228.3.3,204.9.3,180.1s4.7-48.2,14.1-70.1c9.4-21.9,22.2-40.9,38.4-57.1,16.2-16.2,35.2-29,57-38.4C131.7,5,155.1.3,179.9.3s48.2,4.7,70.1,14.1c21.9,9.4,40.9,22.2,57.1,38.4,16.2,16.2,29,35.2,38.4,57,9.4,21.9,14.2,45.2,14.2,70.1s-4.7,48.2-14.1,70.1c-9.4,21.9-22.2,40.9-38.4,57.1-16.2,16.2-35.2,29-57,38.4-21.9,9.4-45.2,14.2-70.1,14.2Z" />
                  </svg>
                </figure>
                <h6 className="h_8">
                  Paketteki Tahmin Sayısı
                  <span className="info">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                      </svg>
                    </figure>
                    <span
                      className="modal b_4 non_select"
                      style={{ width: "10vw" }}
                    >
                      Paketinizdeki Tahmin Sayısı / Tahmin Hakkınız
                    </span>
                  </span>
                </h6>
              </div>
              <p className="remaining_prediction h_8">
                {this.props.basket.result.basket_item_count}/10
              </p>
            </div>
          </div>
          <div className="predictions_and_settings">
            <div className="predictions">
              {this.props.basket.result.basket.length > 0 ? (
                this.props.basket.result.basket.map((prediction, i) => (
                  <Match
                    match={prediction}
                    basket={this.props.basket}
                    isPrediction={true}
                    deleteToBasket={this.props.actions.deleteToBasket}
                    token={this.props.token}
                    key={i}
                    user={this.props.userDetails}
                  />
                ))
              ) : (
                <div className="no_prediction">
                  <h6 className="h_7">Henüz Tahminde Bulunmadınız!</h6>
                  <Link to={URL_MATCHES} className="b_3 --b outline_btn">
                    Tahmin Yap
                  </Link>
                </div>
              )}
            </div>
            {this.props.basket.result.basket.length > 0 ? (
              <button
                className="background_btn"
                onClick={this.createPrediction}
                disabled={this.state.btnLoad ? true : false}
                data-loader={this.state.btnLoad ? true : false}
              >
                <span className="btn-loader"></span>
                <p className="h_8">Tahminleri Paketle</p>
              </button>
            ) : (
              <></>
            )}
            {/* {this.props.basket.result.basket.length > 0 ? (
              <div className="settings">
                <span>
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z" />
                    </svg>
                  </figure>
                </span>
              </div>
            ) : (
              <></>
            )} */}
          </div>
        </div>
      </div>
    ) : (
      <MiniPreload />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
      deleteToBasket: bindActionCreators(
        predictionActions.deleteToBasket,
        dispatch
      ),
      getUserDetails: bindActionCreators(userActions.userDetails, dispatch),
      createPrediction: async (token) => {
        return await dispatch(predictionActions.createPrediction(token));
      },
    },
  };
}

function mapStateToProps(state) {
  return {
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
    userDetails: state.getUserDetailsReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Prediction);
