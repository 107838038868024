import React, { Component } from "react";
import { Link } from "react-router-dom";
import Leagues from "../leagues/Leagues";
import MainAdsorImg from "../components/MainAdsorImg";
import { connect } from "react-redux";
import FavoriteMatch from "../Match/FavoriteMatch";
import * as appJs from "../../js/app";
import * as userActions from "../../redux/actions/userActions";
import { bindActionCreators } from "redux";
import { URL_COMING_SOON, URL_LEADERBOARD, URL_PACKAGES } from "../../config";

class UserHomepage extends Component {
  componentDidMount() {
    this.dailyStreak();
    appJs.loadScrollPos();
    window.scrollTo(0, window.history.state.scrollY);
  }

  dailyStreak = () => {
    if (!sessionStorage.getItem("daily")) {
      this.props.actions.daily(this.props.token).then((response) => {
        if (response === 200) sessionStorage.setItem("daily", true);
      });
    }
  };

  render() {
    return (
      <div className="user_homepage">
        <MainAdsorImg />
        <div className="homepage_buttons">
          <Link to={URL_LEADERBOARD}>
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 320">
                <path d="M89.6,312.2c-24.7,0-45.7-8.7-63.2-26.2C8.9,268.5.2,247.4.2,222.7s8.5-45.1,25.5-62.5c17-17.4,37.6-26.3,61.9-26.8,2.6,0,5.1.2,7.7.6,2.6.4,5.1.8,7.7,1.3L37.1,3.2h95.3l47.6,95.3L227.6,3.2h95.3l-65.3,130.8c2.3-.5,4.7-.9,7.1-1.2,2.4-.3,4.9-.4,7.5-.4,24.5.6,45.2,9.6,62.1,27,17,17.4,25.5,38.3,25.5,62.8s-8.7,46.2-26.2,63.7c-17.5,17.5-38.7,26.2-63.7,26.2s-7.6-.2-11.5-.6c-3.9-.4-7.6-1.2-11.2-2.4,15.3-7.5,26.2-19.4,32.8-35.8,6.6-16.4,9.9-33.4,9.9-51.1,0-30.7-10.6-56.6-31.9-77.9-21.3-21.3-47.3-31.9-77.9-31.9s-56.6,10.6-77.9,31.9c-21.3,21.3-31.9,47.3-31.9,77.9s3.1,35.5,9.2,52.1c6.1,16.6,17.3,28.2,33.4,34.7-3.8,1.2-7.6,2-11.4,2.4-3.8.4-7.7.6-11.7.6ZM180.2,316.8c-26.2,0-48.5-9.2-67-27.5-18.5-18.3-27.7-40.6-27.7-66.8s9.2-48.5,27.5-67c18.3-18.5,40.6-27.7,66.8-27.7s48.5,9.2,67,27.5c18.5,18.3,27.7,40.6,27.7,66.8s-9.2,48.5-27.5,67c-18.3,18.5-40.6,27.7-66.8,27.7ZM148.8,268l31.2-23.7,31.2,23.7-11.7-38.5,31.2-22.2h-38.5l-12.2-40.7-12.2,40.7h-38.5l31.2,22.2s-11.7,38.5-11.7,38.5Z" />
              </svg>
            </figure>
            <p className="h_8">Liderlik Tablosu</p>
          </Link>
          {!this.props.isMobile && (
            <>
              <Link to={URL_PACKAGES}>
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 360">
                    <path d="M151.2,354.9L19,278.6c-4.5-2.5-7.9-6-10.3-10.3-2.4-4.3-3.6-8.9-3.6-13.7V105.5c0-4.8,1.2-9.4,3.6-13.7,2.4-4.3,5.8-7.8,10.3-10.3L151.2,5.1c4.5-2.4,9.1-3.6,13.9-3.6s9.3,1.2,13.7,3.6l132.1,76.4c4.5,2.5,7.9,6,10.3,10.3,2.4,4.3,3.6,8.9,3.6,13.7v149c0,4.8-1.2,9.4-3.6,13.7-2.4,4.3-5.8,7.8-10.3,10.3l-132.1,76.4c-4.5,2.4-9.1,3.6-13.9,3.6s-9.3-1.2-13.7-3.6ZM157.3,184.3v156.6l2.3,1.2c1.8,1,3.6,1.5,5.4,1.5s3.6-.5,5.4-1.5l2.3-1.2v-156.7l136.7-79.1v-2.3c0-1.3-.3-2.6-1-3.8-.6-1.3-1.6-2.3-2.9-3.1l-5.5-3-135.1,78.5L29.8,92.9l-5.3,2.8c-1.3.8-2.2,1.8-2.9,3.1-.6,1.3-1,2.6-1,3.8v2.6s136.7,79,136.7,79Z" />
                  </svg>
                </figure>
                <p className="h_8">Paketlerim</p>
              </Link>
              <Link to="coming-soon">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 150">
                    <path d="M128.7,96.8l35.1-30.3,14.6,1.4-37.9,32.6,11.2,49.4-12.4-7.5s-10.6-45.6-10.6-45.6ZM109.4,37.2l-9.8-23.2L105.1.2l16.1,38.1-11.8-1.1ZM30.8,145.4l12.5-53.7L1.7,55.6l54.9-4.8L78,.2l21.5,50.6,54.9,4.8-41.7,36.1,12.5,53.7-47.2-28.5-47.2,28.5Z" />
                  </svg>
                </figure>
                <p className="h_8">Başarımlarım</p>
              </Link>
            </>
          )}

          <Link to="coming-soon">
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                <path d="M162.31-520q-29.83 0-51.07-21.24Q90-562.48 90-592.31v-125.38q0-29.83 21.24-51.07Q132.48-790 162.31-790H520v270H162.31Zm0 350q-29.83 0-51.07-21.24Q90-212.48 90-242.31v-125.38q0-29.83 21.24-51.07Q132.48-440 162.31-440H600v270H162.31ZM680-170v-350h-80v-270h265.38l-80 202.31h79.23L680-170ZM195-275h60v-60h-60v60Zm0-350h60v-60h-60v60Z" />
              </svg>
            </figure>
            <p className="h_8">Lig Liderlik Tablosu</p>
          </Link>
        </div>
        <Leagues />
        <FavoriteMatch user={this.props.userDetails} />
        {this.props.userDetails ? (
          !this.props.userDetails.is_premium ? (
            <div className="premium_discover">
              <figure>
                <img src="/img/premium.png" alt="Premium" />
              </figure>
              <div className="premium_discover_content">
                <h6 className="h_7">
                  <span className="h_5">Premium Pakete</span>
                  Geç ve Avrupa Liglerinde Oyna!
                </h6>
                <p className="b_1">
                  Premium üyelikle Şampiyonlar Ligi ve Avrupa Ligine katıl, daha
                  büyük ödüller için yarış!
                  <span className="b_3">
                    (örn. özel ligler, büyük ödüller, ekstra puan)
                  </span>
                </p>
              </div>
              <Link to={URL_COMING_SOON} className="background_btn h_8">
                Premium Paketi Keşfet
              </Link>
            </div>
          ) : null
        ) : null}

        {/* <Achievements /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userDetails: state.getUserDetailsReducer,
    token: state.registerorLoginReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      // daily: bindActionCreators(userActions.dailyStreak, dispatch),
      daily: (token) => {
        return dispatch(userActions.dailyStreak(token));
      },
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserHomepage);
