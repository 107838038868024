import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import LeaderboardCard from "../components/leaderboard/LeaderboardCard";
import LeaderboardKingCard from "../components/leaderboard/LeaderboardKingCard";
import WeekDropdown from "../components/WeekDropdown";
import ReportUser from "../components/ReportUser";
import UserLeagueDropdown from "../components/UserLeagueDropdown";
import PeriodDropdown from "../components/PeriodDropdown";
import MiniPreload from "../preload/MiniPreload";
import MonthDropdown from "../components/MonthDropdown";
import SeasonDropdown from "../components/SeasonDropdown";
import { remainingTimeFormat, formatPoint } from "../../js/app";

class Leaderboard extends Component {
  splitWeek = window.location.search.match(/week=([^&]*)/);
  splitMonth = window.location.search.match(/month=([^&]*)/);
  splitSeason = window.location.search.match(/season=([^&]*)/);
  splitLeague = window.location.search.match(/league=([^&]*)/);
  splitPeriod = window.location.search.match(/period=([^&]*)/) || [1, 1];
  isAll = window.location.search.match(/all([^&]*)/);
  constructor(props) {
    super(props);
    this.state = {
      leagues: [],
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.getuserLeagues();
    this.props.actions.getUserDetails(this.props.token).then(() => {
      if (!this.splitLeague)
        this.splitLeague = [1, this.props.userDetails.user_league_id];
      if (this.isAll)
        this.getAllLeaderboard(this.props.userDetails.user_league_id);
      else this.getLeaderboard(this.props.userDetails.user_league_id);
    });
  }

  getuserLeagues = async () => {
    this.props.actions.getUserLeagues(this.props.token).then((league) => {
      let newArray = [];
      for (let i = 0; i < league.length; i++) {
        newArray.push({ id: league[i].id, name: league[i].name });
      }
      this.setState((prevState) => ({
        leagues: [...prevState.leagues, ...newArray],
      }));
    });
  };

  getLeaderboard = () => {
    // const pNo = this.whichPeriodNumber();
    this.props.actions
      .getLeaderboard(
        this.props.token,
        this.splitLeague[1],
        this.splitPeriod[1],
        this.whichPeriodNumber()
      )
      .then(() => {
        // console.log(this.props.leaderboard);
      });
  };

  getAllLeaderboard = () => {
    this.props.actions
      .getAllLeaderboard(
        this.props.token,
        this.splitLeague[1],
        this.splitPeriod[1],
        this.whichPeriodNumber()
      )
      .then(() => {
        // console.log(this.props.leaderboard);
      });
  };

  whichPeriodNumber = () => {
    switch (this.splitPeriod[1]) {
      case 1:
      case "1":
        return this.splitWeek && this.splitWeek[1];
      case 2:
      case "2":
        return this.splitMonth && this.splitMonth[1];
      case 3:
      case "3":
        return this.splitSeason && this.splitSeason[1];
      default:
        break;
    }
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleKeyDownCloseModal = (e) => {
    if (e.key === "Escape") {
      this.closeModal();
    }
  };

  allLeaderboard = () => {
    const href = "all";

    let search = window.location.search.replace(/^\?&/, "?");
    let newSearch = search
      .replace(/(\?|&)week=[^&]*(&|$)/, (_, start, end) =>
        start === "?" ? "?" : end ? start : ""
      )
      .replace(/(\?|&)month=[^&]*(&|$)/, (_, start, end) =>
        start === "?" ? "?" : end ? start : ""
      )
      .replace(/(\?|&)season=[^&]*(&|$)/, (_, start, end) =>
        start === "?" ? "?" : end ? start : ""
      )
      .replace(/\?&/, "?")
      .replace(/^&/, "?")
      .replace(/^\?$/, "");

    if (window.location.search.includes("all")) {
      // if (this.state.selectedPeriod.i === 0) {
      //   newSearch = newSearch.replace(/&?all[^&]*/, "").replace(/\?&/, "?");
      // } else {
      //   newSearch = newSearch.replace(/all[^&]*/, href);
      // }
      window.location.search = newSearch;
    } else {
      // if (this.state.selectedPeriod.i !== 0)
      window.location.search += `${
        window.location.search.includes("?") ? "&" : "?"
      }${href}`;
    }
  };

  noLeaderboard = () => {
    return (
      <div className="no_leaderboard">
        <h3 className="h_3">Burada Sıralama Henüz Oluşmamış!</h3>
        <h6 className="h_8">Başka Sıralamalara Bakmaya Ne Dersin?</h6>
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
          </svg>
        </figure>
      </div>
    );
  };

  render() {
    return (
      <div className="leaderboard_page">
        {/* {this.state.isModalOpen && (
          <ReportUser
            user={{ id: 1, img: "/img/1m1.svg", name: "enes" }}
            handleKey={this.handleKeyDownCloseModal}
            closeModal={this.closeModal}
          />
        )} */}
        <h5 className="h_5 hood">Liderlik Tablosu</h5>
        {this.props.leaderboard ? (
          !this.props.leaderboard.status ? (
            <MiniPreload />
          ) : (
            <>
              {this.props.leaderboard.status === 200 && (
                <div className="info_leaderboard">
                  <div className="top">
                    <div className="total_player total">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 380 260"
                        >
                          <path d="M1.9,259.6v-28.8c0-9.3,2.4-17.3,7.2-24,4.8-6.7,11.7-11.9,20.5-15.8,18.9-8.3,36.5-14.8,52.7-19.4,16.2-4.7,35.9-7,59.1-7s43.2,2.3,59.2,7c16.1,4.7,33.7,11.1,52.8,19.4,8.6,3.9,15.3,9.2,20.2,15.8,4.9,6.7,7.3,14.7,7.3,24v28.8H1.9ZM316.3,259.6v-27.7c0-14.5-2.9-26.9-8.8-37.2-5.9-10.3-13.5-18.8-22.9-25.6,12.4,2.4,24.5,5.4,36,9,11.6,3.6,21.8,7.6,30.6,11.9,7.9,4.3,14.4,10.1,19.4,17.4,5,7.3,7.6,15.4,7.6,24.4v27.7h-61.8ZM141.5,115.4c-16.4,0-30.1-5.5-41.1-16.4-10.9-10.9-16.4-24.6-16.4-41.1s5.5-30.5,16.4-41.3C111.3,5.8,125,.4,141.5.4c16.7,0,30.5,5.4,41.3,16.2,10.8,10.8,16.2,24.6,16.2,41.3s-5.4,30.1-16.2,41.1c-10.8,10.9-24.6,16.4-41.3,16.4ZM277.2,57.9c0,16.4-5.4,30.1-16.2,41.1-10.8,10.9-24.6,16.4-41.3,16.4s-1.6,0-2.2-.2c-.7-.1-1.4-.3-2.2-.6,6.3-7.2,11-15.7,14.2-25.3,3.2-9.6,4.8-20.1,4.8-31.5s-1.8-21.6-5.3-30.8c-3.5-9.2-8.1-17.9-13.8-26.3.6,0,1.3,0,2.2-.2.9-.2,1.7-.2,2.2-.2,16.7,0,30.5,5.4,41.3,16.2,10.8,10.8,16.2,24.6,16.2,41.3Z" />
                        </svg>
                      </figure>
                      <div className="content_total">
                        <p className="b_1">
                          Ligde Bulunan Toplam Oyuncu Sayısı
                        </p>
                        <h6 className="h_7">
                          {this.props.leaderboard.result.total_league_users
                            ? `${this.props.leaderboard.result.total_league_users}`
                            : "Hesaplanmadı"}
                        </h6>
                      </div>
                    </div>
                    <div className="average_point total">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 420 220"
                        >
                          <path d="M28.2,219.7c-7.6,0-14-2.7-19.5-8.2-5.4-5.4-8.1-11.9-8.1-19.5,0-7.8,2.7-14.4,8.1-19.6,5.4-5.3,11.9-7.9,19.4-7.9s4.5.3,6.8.8c2.3.6,4.7,1.4,7.1,2.6l103.9-103.9c-1.2-2.4-2-4.8-2.6-7.1-.5-2.3-.8-4.6-.8-6.8,0-7.8,2.7-14.3,8.1-19.6,5.4-5.3,11.9-7.9,19.5-7.9s14.4,2.7,19.6,8c5.3,5.3,7.9,11.9,7.9,19.7s-.9,5.9-2.6,12.9l61.7,61.7c2.4-.9,4.6-1.6,6.7-2,2-.4,4.2-.6,6.3-.6s4.3.3,6.5.8c2.2.5,4.3,1.4,6.5,2.6l84.7-84.7c-1.2-2.1-2-4.3-2.6-6.5-.5-2.2-.8-4.4-.8-6.6,0-7.8,2.7-14.3,8.2-19.6,5.4-5.3,11.9-7.9,19.5-7.9s14.4,2.7,19.6,8c5.3,5.3,7.9,11.9,7.9,19.7s-2.6,14-7.9,19.5c-5.3,5.4-11.8,8.1-19.6,8.1s-4.5-.3-6.6-.8c-2.2-.6-4.3-1.4-6.5-2.6l-84.7,84.7c1.2,2.1,2,4.3,2.6,6.5s.8,4.4.8,6.6c0,7.5-2.7,14-8,19.4s-11.9,8.1-19.7,8.1-14-2.7-19.5-8.1c-5.4-5.4-8.1-11.9-8.1-19.4s.3-4.5.8-6.8c.5-2.3,1.4-4.7,2.6-7.1l-61.7-61.7c-2.4,1.2-4.8,2-7.1,2.6-2.3.5-4.6.8-6.8.8s-5.9-.9-12.9-2.6l-103.9,103.9c.9,2.4,1.6,4.6,2,6.7.4,2,.6,4.2.6,6.4,0,7.5-2.7,14-8,19.4-5.3,5.4-11.9,8.1-19.7,8.1Z" />
                        </svg>
                      </figure>
                      <div className="content_total">
                        <p className="b_1">Kazanılan Toplam Puan Ortalaması</p>
                        <h6 className="h_7">
                          {this.props.leaderboard.result.summary
                            .average_points_won
                            ? `${formatPoint(
                                this.props.leaderboard.result.summary
                                  .average_points_won
                              )} Puan`
                            : "Hesaplanmadı"}
                        </h6>
                      </div>
                    </div>
                    <div className="remaining_time">
                      <div className="remaining_time_content">
                        <h6 className="h_6">SEZONUN BİTMESİNE KALAN SÜRE</h6>
                        <p className="h_6">
                          <span className="day">
                            <span>
                              {this.props.userDetails &&
                                remainingTimeFormat(
                                  this.props.userDetails.season_end_date
                                ).days}
                              {/* {this.props.userDetails &&
                                console.log(this.props.userDetails)} */}
                            </span>
                            <span className="h_8">Gün</span>
                          </span>
                          :
                          <span className="hours">
                            <span>
                              {this.props.userDetails &&
                                remainingTimeFormat(
                                  this.props.userDetails.season_end_date
                                ).hours}
                            </span>
                            <span className="h_8">Saat</span>
                          </span>
                        </p>
                      </div>
                      {/* <p className="b_1">
                        Her üyelik liginin , kendi içersindeki birinciler
                        toplamda 4 farklı birinci olmak üzere süpriz ödülleri
                        kazanacak
                      </p> */}
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="correct_package total">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 360 400"
                        >
                          <path d="M160,173.4L25,95l-5.3,3c-1.3.8-2.2,1.8-2.9,3.1-.6,1.3-1,2.6-1,3.8v2.4l144.3,83.2,144.3-83.3v-2.3c0-1.3-.3-2.6-1-3.8-.6-1.3-1.6-2.3-2.9-3.1l-5.4-3-135,78.5ZM146.2,356.7L14.3,280.4c-4.5-2.5-7.9-6-10.3-10.3-2.4-4.4-3.5-8.9-3.5-13.7V107.5c0-4.8,1.2-9.3,3.5-13.7,2.4-4.4,5.8-7.8,10.3-10.3L146.2,7.2c4.5-2.4,9.1-3.6,13.9-3.6,4.8,0,9.3,1.2,13.7,3.6l132,76.3c4.5,2.5,7.9,6,10.3,10.3,2.4,4.4,3.5,8.9,3.5,13.7v97.4c-6-2.3-12.1-3.9-18.3-4.9-6.2-1-12.6-1.5-19.3-1.5-33.2,0-61.5,11.8-85.1,35.3-23.6,23.5-35.4,52-35.4,85.4,0,7.2.6,14.2,1.9,20.9,1.3,6.7,3.1,13.1,5.6,19.3-3.9,1.2-7.8,1.6-11.7,1-3.9-.5-7.6-1.8-11.2-3.7ZM282,396.4c-21.3,0-39.6-7.5-54.7-22.4-15.2-15-22.8-33.3-22.8-55.1s7.6-39.7,22.8-54.8c15.2-15.1,33.4-22.7,54.7-22.7s39.6,7.5,54.7,22.6c15.2,15.1,22.8,33.4,22.8,55s-7.6,40.1-22.8,55c-15.2,14.9-33.4,22.4-54.7,22.4ZM240.7,333.3h0c0,3.2,1.9,6,4.8,7.2l32.8,13.5c3.6,1.5,7.6,0,9.4-3.4l31.2-58.3c2.2-4.1-.8-9-5.4-9h0c-3.3,0-6.3,1.9-7.8,4.8l-25.3,49.6c-.7,1.4-2.4,2-3.9,1.5l-28.1-11.1c-3.7-1.5-7.7,1.3-7.7,5.3Z" />
                        </svg>
                      </figure>
                      <div className="content_total">
                        <p className="b_1">Paket Doğruluk Oranı</p>
                        <h6 className="h_7">
                          {this.props.leaderboard.result.summary
                            .correct_bet_percentage
                            ? `%${this.props.leaderboard.result.summary.correct_bet_percentage}`
                            : "Hesaplanmadı"}
                        </h6>
                      </div>
                    </div>
                    <div className="correct_home total">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 280 320"
                        >
                          <path d="M140,205.2c13.2,0,24.4-4.6,33.7-13.9,9.2-9.2,13.8-20.5,13.8-33.7s-4.6-24.4-13.9-33.7c-9.2-9.2-20.5-13.8-33.7-13.8s-24.4,4.6-33.7,13.9c-9.2,9.2-13.8,20.5-13.8,33.7s4.6,24.4,13.9,33.7c9.2,9.2,20.5,13.8,33.7,13.8ZM15.8,301.8h246.7v-1.2c0-4.6-.7-8.9-2.1-13.2-1.4-4.2-3.8-7.8-7.1-10.7-16.8-12.5-34.8-22.1-54.2-28.7-19.4-6.6-39.5-9.9-60.5-9.9s-39.6,3.4-58.8,10.1c-19.1,6.7-36.7,16.3-52.8,28.9-3.8,2.8-6.5,6.3-8.4,10.5-1.8,4.2-2.7,8.7-2.7,13.4,0,0,0,.8,0,.8ZM.5,317.1V107.9L140,2.9l139.5,105v209.3H.5Z" />
                        </svg>
                      </figure>
                      <div className="content_total">
                        <p className="b_1">Ev Kazanır Doğruluk Oranı</p>
                        <h6 className="h_7">
                          {this.props.leaderboard.result.summary
                            .correct_home_bet_percentage
                            ? `%${this.props.leaderboard.result.summary.correct_home_bet_percentage}`
                            : "Hesaplanmadı"}
                        </h6>
                      </div>
                    </div>
                    <div className="correct_draw total">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 460 320"
                        >
                          <path d="M313.1,65.3L226.4.3l-84.3,63.8c11.4,4.4,21.7,11.3,31.1,20.7,17.8,17.8,26.7,39.4,26.7,64.9s-7.2,42.8-21.7,59.5c1.2.4,2.5.7,3.7,1.1.6.2,1.2.3,1.8.5,16,5,31.3,11.5,46.2,19.9,7.2-4.2,14.5-8.1,21.8-11.3,7.7-3.3,15.7-6.4,24-9.1-7.2-8.1-12.6-17.3-16.5-27.7-3.7-10.4-5.7-21.4-5.7-33,0-19.3,5.6-36.6,16.5-52,11-15.4,25.4-26.2,43.1-32.4ZM148.1,113.9c-9.9-9.8-21.8-14.8-35.8-14.8s-26,5-35.8,14.8c-9.8,9.9-14.8,21.8-14.8,35.9s5,25.9,14.8,35.8c9.9,9.8,21.8,14.7,35.8,14.7s26-4.9,35.8-14.8c9.8-9.8,14.8-21.7,14.8-35.8s-5-26-14.8-35.8ZM383.5,113.9c-9.9-9.8-21.8-14.8-35.8-14.8s-26,5-35.8,14.8c-9.8,9.9-14.8,21.8-14.8,35.9s5,25.9,14.8,35.8c9.9,9.8,21.8,14.7,35.8,14.7s26-4.9,35.8-14.8c9.8-9.8,14.8-21.7,14.8-35.8s-5-26-14.8-35.8ZM454.8,279.1c-2.7-4.1-6.3-7.6-10.5-10.2-14.7-8.8-30.1-15.5-46.4-19.7-16.3-4.3-33-6.6-50.2-6.6s-33.9,2.2-50.1,6.6c-16.3,4.3-31.8,10.9-46.5,19.7-4.2,2.6-7.7,6.1-10.5,10.2-2.7,4.2-4.1,8.8-4.1,13.7v26.9h222.5v-26.9c0-4.9-1.3-9.4-4.1-13.7ZM219.4,279.1c-2.7-4.1-6.3-7.6-10.5-10.2-14.7-8.8-30.1-15.5-46.4-19.7-16.3-4.3-33-6.6-50.2-6.6s-33.9,2.2-50.1,6.6c-16.3,4.3-31.8,10.9-46.5,19.7-4.2,2.6-7.7,6.1-10.5,10.2-2.7,4.2-4.1,8.8-4.1,13.7v26.9h222.5v-26.9c0-4.9-1.3-9.4-4.1-13.7Z" />
                        </svg>
                      </figure>
                      <div className="content_total">
                        <p className="b_1">Beraberlik Doğruluk Oranı</p>
                        <h6 className="h_7">
                          {this.props.leaderboard.result.summary
                            .correct_draw_bet_percentage
                            ? `%${this.props.leaderboard.result.summary.correct_draw_bet_percentage}`
                            : "Hesaplanmadı"}
                        </h6>
                      </div>
                    </div>
                    <div className="correct_away total">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 380 320"
                        >
                          <path d="M154.7,319.4v-26.8c0-4.9,1.4-9.5,4.1-13.6,2.8-4.2,6.3-7.6,10.5-10.2,14.7-8.8,30.2-15.4,46.4-19.7,16.2-4.3,32.9-6.5,50-6.5s33.8,2.2,50,6.5c16.2,4.3,31.7,10.9,46.4,19.7,4.2,2.7,7.7,6.1,10.5,10.2,2.8,4.2,4.1,8.7,4.1,13.6v26.8h-222.1ZM3.2,319.4V107.4L144.6.6l86.6,64.9c-17.7,6.2-32,17-43,32.3-11,15.3-16.5,32.7-16.5,51.9s1.9,22.5,5.7,32.9c3.8,10.4,9.2,19.6,16.4,27.7-8.2,2.7-16.2,5.7-23.9,9.1-7.7,3.4-15.3,7.3-22.9,11.8-11.3,6.6-20.1,15.4-26.4,26.3-6.3,11-9.5,22.7-9.5,35.2v26.7H3.2ZM265.8,200.2c-14,0-25.9-4.9-35.8-14.7-9.8-9.8-14.8-21.7-14.8-35.7s4.9-25.9,14.7-35.8c9.8-9.8,21.7-14.8,35.7-14.8s25.9,4.9,35.8,14.7c9.8,9.8,14.8,21.7,14.8,35.7s-4.9,25.9-14.7,35.8c-9.8,9.8-21.7,14.8-35.7,14.8Z" />
                        </svg>
                      </figure>
                      <div className="content_total">
                        <p className="b_1">Deplasman Kazanır Doğruluk Oranı</p>
                        <h6 className="h_7">
                          {this.props.leaderboard.result.summary
                            .correct_away_bet_percentage
                            ? `%${this.props.leaderboard.result.summary.correct_away_bet_percentage}`
                            : "Hesaplanmadı"}
                        </h6>
                      </div>
                    </div>
                    <div className="correct_score total">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 370 310"
                        >
                          <path d="M34.1,307.4c-7.6,0-14-2.7-19.3-8-5.3-5.3-8-11.8-8-19.3V69.3c0-7.6,2.7-14,8-19.3,5.3-5.3,11.8-8,19.3-8h64.4V2.6h15.2v39.4h142.5V2.6h15.2v39.4h64.4c7.6,0,14,2.7,19.3,8,5.3,5.3,8,11.8,8,19.3v210.8c0,7.6-2.7,14-8,19.3-5.3,5.3-11.8,8-19.3,8,0,0-301.7,0-301.7,0ZM177.6,289.9h15.2v-25h-15.2s0,25,0,25ZM242.8,225.3h49.2c3.3,0,6.3-1.2,8.8-3.6,2.6-2.4,3.9-5.3,3.9-8.7v-78.8c0-3.3-1.3-6.3-3.9-8.8-2.6-2.6-5.5-3.9-8.8-3.9h-49.2c-3.4,0-6.3,1.3-8.7,3.9-2.4,2.6-3.6,5.5-3.6,8.8v78.8c0,3.4,1.2,6.3,3.6,8.7,2.4,2.4,5.3,3.6,8.7,3.6ZM65.4,225.3h74.2v-15.2h-59v-28.2h45.2c3.3,0,6.5-1.5,9.4-4.4,2.9-2.9,4.4-6.1,4.4-9.4v-34c0-3.3-1.2-6.3-3.6-8.8-2.4-2.6-5.3-3.9-8.7-3.9h-61.9v15.2h59v28.5h-44.8c-3.3,0-6.4,1.5-9.6,4.4-3.1,2.9-4.7,6.1-4.7,9.4,0,0,0,46.3,0,46.3ZM177.6,216.4h15.2v-17.4h-15.2s0,17.4,0,17.4ZM245.6,210.2v-73.5h43.9v73.5s-43.9,0-43.9,0ZM177.6,150.5h15.2v-17.4h-15.2v17.4ZM177.6,84.5h15.2v-25h-15.2v25Z" />
                        </svg>
                      </figure>
                      <div className="content_total">
                        <p className="b_1">Skor Doğruluk Oranı</p>
                        <h6 className="h_7">
                          {this.props.leaderboard.result.summary
                            .correct_score_bet_percentage
                            ? `%${this.props.leaderboard.result.summary.correct_score_bet_percentage}`
                            : "Hesaplanmadı"}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="leaderboard_sect">
                {this.props.leaderboard.status === 200 && (
                  <p className="h_7 hood">
                    {this.splitLeague && (
                      <span>
                        {this.state.leagues.map(
                          (l) => l.id == this.splitLeague[1] && l.name
                        )}
                      </span>
                    )}
                    Sıralaması
                  </p>
                )}
                <div className="filter">
                  <UserLeagueDropdown splitLeague={this.splitLeague} />
                  <PeriodDropdown splitPeriod={this.splitPeriod} />
                  {this.splitPeriod[1] == 1 && (
                    <WeekDropdown splitWeek={this.splitWeek} default />
                  )}
                  {this.splitPeriod[1] == 2 ? (
                    <MonthDropdown splitMonth={this.splitMonth} default />
                  ) : null}
                  {this.splitPeriod[1] == 3 ? (
                    <SeasonDropdown splitSeason={this.splitSeason} default />
                  ) : null}
                </div>
                {this.props.leaderboard.status === 200 ? (
                  <>
                    <div className="leaderboard">
                      {this.props.leaderboard.result.users
                        .slice(0, 3)
                        .map((user) => (
                          <LeaderboardKingCard
                            openModal={this.openModal}
                            user={user}
                            key={user.id}
                            myDetail={this.props.userDetails}
                          />
                        ))}
                      {this.props.leaderboard.result.users
                        .slice(3, !this.isAll ? 10 : undefined)
                        .map((user) => (
                          <LeaderboardCard
                            openModal={this.openModal}
                            user={user}
                            key={user.id}
                            myDetail={this.props.userDetails}
                            total={
                              this.props.leaderboard.result.users.length.toString()
                                .length
                            }
                          />
                        ))}
                      {!this.isAll &&
                        this.props.leaderboard.result.users.length > 10 && (
                          <div className="user_queue">
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            {this.props.leaderboard.result.users
                              .slice(10)
                              .map((user) => (
                                <LeaderboardCard
                                  openModal={this.openModal}
                                  user={user}
                                  key={user.id}
                                  myDetail={this.props.userDetails}
                                  total={
                                    this.props.leaderboard.result.users
                                      .at(-1)
                                      .position.toString().length
                                  }
                                />
                              ))}
                          </div>
                        )}
                    </div>
                    {!this.isAll && (
                      <button
                        className="outline_btn"
                        onClick={() => this.allLeaderboard()}
                      >
                        <span className="btn-loader s"></span>
                        <p className="h_8">Tüm Sıralamayı Görüntüle</p>
                      </button>
                    )}
                  </>
                ) : (
                  this.noLeaderboard()
                )}
              </div>
            </>
          )
        ) : (
          <MiniPreload />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
    leaderboard: state.getLeaderboardReducer,
    userDetails: state.getUserDetailsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getUserDetails: bindActionCreators(userActions.userDetails, dispatch),
      getLeaderboard: bindActionCreators(userActions.leaderboard, dispatch),
      getAllLeaderboard: bindActionCreators(
        userActions.leaderboardAll,
        dispatch
      ),
      getUserLeagues: bindActionCreators(userActions.getUserLeagues, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
