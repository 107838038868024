import * as actionTypes from "./actionTypes";
import * as userActions from "./userActions";
import initialState from "../reducers/initialState";
import alertify from "alertifyjs";

export function getBasketSuccess(basket) {
  return {
    type: actionTypes.GET_BASKET_SUCCESS,
    payload: basket,
  };
}

export function getBasket(token) {
  return async function (dispatch) {
    let url = initialState.url + "/coupons/basket";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          return dispatch(userActions.loginSuccess(response.status));
        } else {
          throw new Error(`Error! status: ${response.status}`);
        }
      }

      const result = await response.json();
      dispatch(getBasketSuccess({ result: result, status: response.status }));
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function packagesListSuccess(packages) {
  return {
    type: actionTypes.PACKAGES_LIST_SUCCESS,
    payload: packages,
  };
}

export function packagesList(token, filter, week, page) {
  return async function (dispatch) {
    dispatch(packagesListSuccess([]));
    let url = initialState.url + "/coupons/coupon-list";
    if (filter) {
      url += "?filter=" + filter;
      if (week !== 0) url += "&week=" + week;
      if (page) url += "&page=" + page;
    }
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          // "Content-Type": "application/json",
          ...initialState.ngrok,
        },
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const result = await response.json();
      dispatch(
        packagesListSuccess({ result: result, status: response.status })
      );
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function createPrediction(token) {
  return async function (dispatch) {
    let url = initialState.url + "/coupons/create-coupon";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.statusText}`);
      }

      const result = await response.json();
      return { result, status: response.status };
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function deleteToBasketSuccess(match) {
  return {
    type: actionTypes.DELETE_TO_BASKET_SUCCESS,
    payload: match,
  };
}

export function deleteToBasket(basketItem, token) {
  return async function (dispatch) {
    let url = initialState.url + "/coupons/delete-basket-item";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(basketItem),
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.statusText}`);
      }

      const result = await response.json();
      dispatch(
        deleteToBasketSuccess({ result: result, status: response.status })
      );
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function packageDetail(packageID, token) {
  return async function () {
    let url = initialState.url + "/coupons/details?coupon_id=" + packageID;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          ...initialState.ngrok,
        },
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.statusText}`);
      }

      const result = await response.json();
      return { result, status: response.status };
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}

export function addToBasket(match, token) {
  return async function (dispatch) {
    let url = initialState.url + "/coupons/new-basket-item";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
          ...initialState.ngrok,
        },
        body: JSON.stringify(match),
      });

      const result = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          return { result: result, status: response.status };
        } else if (response.status === 401) {
          dispatch(userActions.loginSuccess(response.status));
        } else if (response.status === 400) {
          return { result: result, status: response.status };
        } else {
          throw new Error(`Error! status: ${response.status}`);
        }
      }

      return { result: result, status: response.status };
    } catch (error) {
      alertify.error("Api Hatasi: " + error.message);
    }
  };
}
