import React, { Component } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../js/app";
import { URL_PACKAGE_DETAIL } from "../../../config";

export default class PackageItem extends Component {
  render() {
    const { packages, id } = this.props;

    return (
      <div className={"package_item " + (packages.completed ? "active" : "")}>
        <Link to={`${URL_PACKAGE_DETAIL}?id=${packages.id}`}>
          <div className="package_info">
            <p className="b_1 id">#{packages.id}</p>
            <p className="b_1 date">{formatDate(packages.created_at)}</p>
            <p className="b_1 --m point">+{packages.total_won_points} Puan</p>
            <p className="b_1 correct_prediction">
              {packages.correct_bets === 0
                ? "Doğru Tahmin Yok"
                : `${packages.correct_bets} Doğru Tahmin`}
            </p>
            <p className="b_1 completed">
              {`${packages.completed_bets}/${packages.bet_count} Tamamlandı`}
            </p>
          </div>
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
          </figure>
        </Link>
      </div>
    );
  }
}
