import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Form from "./forgotPassword/Form";
import VerifyCodePage from "./forgotPassword/VerifyCodePage";
import NotFound from "../pages/NotFound";
import SetPassword from "./forgotPassword/SetPassword";
import { URL_SET_PASSWORD, URL_VERIFY_CODE } from "../../config";

export default class ForgotPassword extends Component {
  componentDidMount() {
    document.getElementsByClassName("main-hood")[0].style.display = "none";
  }

  render() {
    return (
      <div className="forgot-password">
        <h4 className="h_6">Şifremi Unuttum</h4>
        <Routes>
          <Route path="/" element={<Form />} />
          <Route path={URL_VERIFY_CODE} element={<VerifyCodePage />} />
          <Route path={URL_SET_PASSWORD} element={<SetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    );
  }
}
