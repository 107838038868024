import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import * as predictionActions from "../../redux/actions/predictionActions";
import Match from "../components/matches/Match";
import { URL_PACKAGES } from "../../config";

class PackageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      couponId: window.location.search.match(/id=([^&]*)/) || null,
      pckage: null,
    };
  }

  componentDidMount() {
    if (this.state.couponId) {
      this.getPackageDetail();
    } else {
      this.setState({ isRedirect: true });
    }
  }

  getPackageDetail = async () => {
    this.props.actions
      .getPackageDetails(this.state.couponId[1], this.props.token)
      .then((p) => {
        this.setState({ pckage: p });
      });
  };

  render() {
    if (this.state.isRedirect) return <Navigate to={URL_PACKAGES} />;
    return (
      <div className="package_detail">
        <div className="hood">
          <h5 className="h_5">Paket #{this.state.couponId[1]}</h5>
        </div>
        <div className="matches">
          {this.state.pckage &&
          this.state.pckage.result &&
          this.state.pckage.status === 200
            ? this.state.pckage.result.bets.map((match) => (
                <Match
                  match={match}
                  isPackageDetail
                  token={this.props.token}
                  key={match.match_id}
                  class={
                    match.canceled
                      ? "canceled"
                      : match.completed
                      ? match.correct
                        ? "correct"
                        : "wrong"
                      : null
                  }
                />
              ))
            : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getPackageDetails: bindActionCreators(
        predictionActions.packageDetail,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageDetail);
