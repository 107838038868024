import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import VerifyCodeInput from "../../verifyCode/VerifyCodeInput";
import { connect } from "react-redux";
import { withRouter } from "../../withRouter";
import { bindActionCreators } from "redux";
import * as userActions from "../../../redux/actions/userActions";
import alertify from "alertifyjs";
import { URL_SET_PASSWORD } from "../../../config";

class VerifyCodePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      btnLoad: false,
      timeLeft: this.props.endTime,
      // timeLeft: 50,
      code: null,
      // isAuthenticated: false,
      isWrong: null,
    };
    this.interval = null;
  }

  componentDidMount() {
    this.startTimer();
    // console.log(this.props.location?.state.form);
  }

  componentWillUnmount() {
    this.clearTimer(); // Bileşen unmount olduğunda interval temizlenir
  }

  startTimer = () => {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.timeLeft > 0) {
            return { timeLeft: prevState.timeLeft - 1 }; // 1 saniye azalt
          } else {
            this.clearTimer(); // Süre dolunca timer durdurulur
            return { timeLeft: 0 };
          }
        });
      }, 1000);
    }
  };

  // Timer'ı durduran fonksiyon
  clearTimer = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  handleInputChange = (value) => {
    this.setState({ code: value });
  };

  send = (e) => {
    e.preventDefault();

    this.setState({ btnLoad: true });

    this.props.actions
      .sendForm(
        this.props.location?.state.form.mail,
        this.props.location?.state.form.last_phone,
        this.state.code
      )
      .then((result) => {
        this.setState({ btnLoad: false });

        if (result.payload === 200) {
          this.setState({ isRedirect: true, isWrong: false });
        } else if (result.payload === 400) {
          this.setState({ isRedirect: false, isWrong: true });
        } else {
          this.setState({ isRedirect: false, isWrong: false });
          alertify.error("Gonderilirken Bir Hata Olustu: " + result.payload);
        }
      });
  };

  sendCodeAgain = (e) => {
    e.preventDefault();

    this.props.actions
      .sendCodeAgain(
        this.props.location?.state.form.mail,
        this.props.location?.state.form.last_phone
      )
      .then(() => {
        // this.setState({ btnLoad: false });
        if (this.props.endTime === 404) {
          alertify.error("Gonderilirken Bir Hata Olustu");
        } else {
          // console.log(this.props.endTime);
          this.setState({ timeLeft: this.props.endTime });
          this.startTimer();
          // this.setState({ isRedirect: true });
        }
      });
  };

  render() {
    // Dakika ve saniyeye dönüştürme
    const minutes = Math.floor(this.state.timeLeft / 60); // Dakika hesaplama
    const seconds = Math.floor(this.state.timeLeft % 60); // Kalan saniyeyi hesaplama

    if (this.state.isRedirect) {
      return (
        <Navigate
          to={`../${URL_SET_PASSWORD}`}
          state={{ form: this.props.location?.state.form }}
        />
      );
    }

    return (
      <div
        className={"verify-code-page " + (this.state.isWrong ? "wrong" : "")}
      >
        <form>
          <VerifyCodeInput
            value={this.state.code}
            onChange={this.handleInputChange}
          />
          <div className="wrong-txt">
            <p className="h_8">Kodu Yanlış Girdiniz</p>
          </div>
          <div className="content-verify">
            <div className="content-verify-inner phone">
              <p className="b_1">
                Şifrenizi sıfırlamak için telefon numaranıza bir doğrulama kodu
                gönderdik. Hesabınıza güvenle erişim sağlamak için lütfen kodu
                girin.
              </p>
              <div className="links">
                <button
                  type="submit"
                  onClick={this.sendCodeAgain}
                  disabled={this.state.timeLeft <= 0 ? false : true}
                  className="b_2"
                >
                  Tekrar Mesaj Gönder
                </button>
              </div>
            </div>
            <p className="counter b_2">
              Kalan Süre: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </p>
          </div>
          <button
            type="submit"
            onClick={this.send}
            className="background_btn"
            disabled={
              this.state.timeLeft <= 0 || this.state.btnLoad ? true : false
            }
            data-loader={this.state.btnLoad ? true : false}
          >
            <span className="btn-loader"></span>
            <h4 className="b_1">Devam et</h4>
          </button>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      sendForm: bindActionCreators(
        userActions.resetPasswordCodeVerify,
        dispatch
      ),
      sendCodeAgain: bindActionCreators(
        userActions.resetPasswordCode,
        dispatch
      ),
      // code_email_verify: bindActionCreators(userActions.verifyEmail, dispatch),
      // code_phone_verify: bindActionCreators(userActions.verifyPhone, dispatch),
      // verifyEmailCode: bindActionCreators(
      //   userActions.verifyEmailCode,
      //   dispatch
      // ),
      // verifyPhoneCode: bindActionCreators(
      //   userActions.verifyPhoneCode,
      //   dispatch
      // ),
    },
  };
}

function mapStateToProps(state) {
  return {
    endTime: state.resetPasswordCodeReducer,
    // form_obj: state.sendFormObjectReducer,
    // verify_code: state.verifyPhoneorMailReducer,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyCodePage));
