import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import RegisterandLogin from "../pages/RegisterandLogin";
import NotFound from "../pages/NotFound";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import * as appJs from "../../js/app";
import { URL_REGISTER, URL_LOGIN, URL_ABOUT } from "../../config";
import About from "../pages/About";

function App() {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 480px)").matches
  );

  useEffect(() => {
    appJs.browserControl();

    window
      .matchMedia("(max-width: 480px)")
      .addEventListener("change", (e) => setIsMobile(e.matches));

    return () => {
      //unmount calisir
    };
  }, []);

  return (
    <>
      {/* <InactiveHandler /> */}
      {/* <Nav /> */}
      {/* <Navigation /> */}
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<Dashboard isMobile={isMobile} />} />
        <Route path={`${URL_REGISTER}/*`} element={<RegisterandLogin />} />
        <Route path={`${URL_LOGIN}/*`} element={<RegisterandLogin />} />
        <Route path={`${URL_ABOUT}`} element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <FooterI /> */}
      {/* <Footer /> */}
    </>
  );
}

export default App;
