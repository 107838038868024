export const URL_LEADERBOARD = "/liderlik-tablosu";
export const URL_LEAGUES = "/ligler";
export const URL_MATCHES = "/maclar";
export const URL_PACKAGE_DETAIL = "/paket-detayi";
export const URL_PACKAGES = "/paketlerim";
export const URL_PREDICTION = "/tahminlerim";
export const URL_REGISTER = "/kayit-ol";
export const URL_FAVORITE_L_AND_T = "favorilerim";
export const URL_VERIFY_CODE = "kod-dogrula";
export const URL_SET_PASSWORD = "sifremi-guncelle";
export const URL_LOGIN = "/giris-yap";
export const URL_LOGIN_FORGOT_PASSWORD = "sifremi-unuttum";
export const URL_CONDITIONS_OF_USE = "/kullanim-kosullari";
export const URL_PRIVACY_POLICY = "/gizlilik-politikasi";
export const URL_PRIVACY_POLICY_EN = "/privacy-policy";
export const URL_FAQ_S = "/SSS";
export const URL_COMING_SOON = "/coming-soon";
export const URL_ABOUT = "/hakkimizda";
export const URL_PROFILE = "/profil";
