import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import * as AppJs from "../../../js/app";

export default class LeaderboardKingCard extends Component {
  constructor(props) {
    super(props);
    this.fadeRef = createRef();
    this.state = {
      fade: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (!this.fadeRef.current || !this.fadeRef.current.contains(event.target)) {
      this.setState({ fade: null }); // Dışarı tıklanınca kapat
    }
  };

  toggleFlip = () => {
    this.setState((prevState) => ({ fade: !prevState.fade }));
  };

  queueToTxt(queue) {
    if (queue === 1) {
      return "first";
    } else if (queue === 2) {
      return "second";
    } else if (queue === 3) {
      return "thirth";
    }
  }

  render() {
    const { user, openModal } = this.props;

    return (
      <div
        className={`leaderboard_king_card ${this.queueToTxt(user.position)}`}
        ref={this.fadeRef}
      >
        {console.log(user)}
        <div className="leaderboard_card_inner">
          <div
            className={`leaderboard_card_front ${
              this.state.fade ? "hidden" : "visible"
            }`}
          >
            <div
              className="leaderboard_king_card_content"
              onClick={this.toggleFlip}
            >
              <div className="left">
                <figure className="queue">
                  <img
                    src={`/img/leaderboard_${this.queueToTxt(
                      user.position
                    )}.png`}
                    alt="badge"
                  />
                </figure>
                <figure className="profile">
                  <img src={user.profile_picture} alt="User Profile" />
                </figure>
              </div>
              <div className="right">
                <div className="txt">
                  <p className="h_8">{user.username}</p>
                  <p className="h_8">
                    <span>{AppJs.formatPoint(user.points)}</span>Puan
                  </p>
                </div>
              </div>
            </div>
            <div className="report">
              <figure>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                </svg>
              </figure>
              <div className="content">
                <Link className="b_1">Profile Git</Link>
                <Link className="b_1" onClick={openModal}>
                  Şikayet Et
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`leaderboard_card_back ${
              this.state.fade ? "visible" : "hidden"
            }`}
            onClick={this.toggleFlip}
          >
            <div className="percents">
              <p className="b_3 --m">
                Ev:
                <span className="--bb">{`%${user.correct_home_bet_percentage}`}</span>
              </p>
              <p className="b_3 --m">
                Berabere:
                <span className="--bb">{`%${user.correct_draw_bet_percentage}`}</span>
              </p>
              <p className="b_3 --m">
                Deplasman:
                <span className="--bb">{`%${user.correct_away_bet_percentage}`}</span>
              </p>
            </div>
            <div className="percents">
              <p className="b_3 --m">
                Skor:
                <span className="--bb">{`%${user.correct_score_bet_percentage}`}</span>
              </p>
              <p className="b_3 --m">
                Paket:
                <span className="--bb">{`%${user.correct_bet_percentage}`}</span>
              </p>
            </div>
            {/* <div className="percents">
              <p className="b_4 --m">
                Paket Doğruluk Oranı:
                <span className="--bb">{`%${user.correct_bet_percentage}`}</span>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
