import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as matchActions from "../../redux/actions/matchActions";
import initialState from "../../redux/reducers/initialState";
import MiniPreload from "../preload/MiniPreload";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import League from "../components/leagues/League";
import { URL_LEAGUES } from "../../config";

class Leagues extends Component {
  componentDidMount() {
    if (this.props.leagues.length <= 0) {
      this.props.actions.getLeagues();
    }
  }

  render() {
    return (
      <div className={"leagues_component "}>
        <div className="hood_league">
          <h5 className="h_5">Ligler</h5>
          <Link to={URL_LEAGUES} className="outline_btn non_select">
            <p className="b_1 --m non_select">Tüm Ligleri Görüntüle</p>
          </Link>
        </div>
        <div className="leagues">
          {this.props.leagues.length <= 0 ? (
            <MiniPreload />
          ) : (
            this.props.leagues
              .slice(0, 4)
              .map((league) => (
                <League league={league} is_premium={true} key={league.id} />
              ))
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getLeagues: bindActionCreators(matchActions.getLeagueList, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    leagues: state.leagueListReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Leagues);
