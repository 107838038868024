import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import { URL_LOGIN } from "../../config";

export default class Homepage extends Component {
  render() {
    return (
      <Navigate to={URL_LOGIN} />
      // <div className="homepage">
      //   <div className="first" id="homepage">
      //     <div className="img">
      //       <div className="filter"></div>
      //       <img src="/img/homepage2.png" alt="Banner" />
      //     </div>
      //     <div className="txt">
      //       <h4 className="h_6">MAÇ SONUÇLARINI TAHMİN EDİN</h4>
      //       <h4 className="h_6">
      //         YARIŞIN SONUNDA BÜYÜK ÖDÜLLERİN SAHİBİ OLUN!
      //       </h4>
      //       <h4 className="h_7">BİLENLERİ SAHAYA ALALIM</h4>
      //       <Link to="register" className="h_6 background_btn">
      //         ÜCRETSİZ KATILIN
      //       </Link>
      //     </div>
      //   </div>

      //   <div className="rewards" id="rewards">
      //     <div className="content">
      //       <h5 className="h_7">
      //         Sezon boyu sürecek sürükleyici ve heyecan dolu bir mücadele sizi
      //         bekliyor! Liglerdeki en iyi oyuncular arasına girerek, birbirinden
      //         değerli ödüllerin sahibi olabilirsiniz. Bu heyecan dolu rekabete
      //         katılın, yeteneklerinizi gösterin ve büyük ödüllere doğru adım
      //         atın!
      //       </h5>
      //       <Link to="coming-soon" className="background_btn h_8">
      //         Ödülleri Görüntüle
      //       </Link>
      //     </div>
      //     <figure>
      //       <img src="/img/rewards.png" alt="" />
      //     </figure>
      //   </div>
      //   <div className="how-to" id="how-to">
      //     <div className="content">
      //       <figure>
      //         <img src="/img/login.png" alt="" />
      //       </figure>
      //       <h4 className="h_8">Kayıt Ol & Giriş Yap</h4>
      //     </div>
      //     <img src="/img/arrow.png" alt="" />
      //     <div className="content">
      //       <figure>
      //         <img src="/img/package.png" alt="" />
      //       </figure>
      //       <h4 className="h_8">Lig Maçlarına Bak ve Tahminini Oluştur</h4>
      //     </div>
      //     <img src="/img/arrow.png" alt="" />
      //     <div className="content">
      //       <figure>
      //         <img src="/img/score.png" alt="" />
      //       </figure>
      //       <h4 className="h_8">Kazanan / Kaybeden Seç veya Skor Tahmin Et</h4>
      //     </div>
      //     <img src="/img/arrow.png" alt="" />
      //     <div className="content">
      //       <figure>
      //         <img src="/img/win.png" alt="" />
      //       </figure>
      //       <h4 className="h_8">Maç Sonuçlarını Bekle ve Puanları Kap!</h4>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
