import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PackageItem from "../components/packages/PackageItem";
import WeekDropdown from "../components/WeekDropdown";
import StatusDropdown from "../components/packages/StatusDropdown";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as userActions from "../../redux/actions/userActions";
import MiniPreload from "../preload/MiniPreload";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";
import { URL_PREDICTION } from "../../config";

class Packages extends Component {
  filterStatus = [
    "Aktif Paketlerim",
    "Tamamlanmış Paketlerim",
    "Tüm Paketlerim",
  ];
  splitFilter = window.location.search.match(/status=([^&]*)/) || [1, 1];
  splitWeek = window.location.search.match(/week=([^&]*)/) || [0, 0];
  splitPage = window.location.search.match(/page=([^&]*)/) || [1, 1];

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     filterWeek: ["Tüm Haftalar"],
  //   };
  // }

  componentDidMount() {
    // if (this.splitFilter[1] != 1) this.getWeek();

    this.props.actions.getPackages(
      this.props.token,
      this.splitFilter[1],
      this.splitWeek[1],
      this.splitPage[1]
    );
  }

  noPackage = () => {
    return (
      <div className="no_package">
        <h3 className="h_3">Burada Paket Bulunmuyor!</h3>
        <h6 className="h_8">
          Diğer Durumlara Bakmaya veya Paket Oluşturmaya Ne Dersin?
        </h6>
        <Link to={URL_PREDICTION} className="outline_btn h_8">
          Paket Oluştur
        </Link>
      </div>
    );
  };

  render() {
    return (
      <div className="packages_page">
        <div className="packages_hood">
          <h5 className="h_5">Paketlerim</h5>
          <p className="h_8">
            Bu sayfada tahmin ettiğiniz maç sonuçlarını görebileceksiniz. Tahmin
            yaparak puan kazanabilir ve ödülleri kapabilirsiniz!
          </p>
        </div>
        <div className="packages_filter">
          <div className="filter">
            <StatusDropdown
              status={this.filterStatus}
              splitFilter={this.splitFilter}
            />
            {this.splitFilter[1] != 1 && (
              <WeekDropdown splitWeek={this.splitWeek} />
            )}
          </div>
          <div className="order"></div>
        </div>
        <div className="package_items">
          {this.props.packages && this.props.packages.status === 200 ? (
            this.props.packages.result.total_coupon_count > 0 ? (
              <>
                {this.props.packages.result.coupon_data.map((c) => (
                  <PackageItem packages={c} key={c.id} />
                ))}
                {this.props.packages.result.total_page_count > 1 ? (
                  <Pagination
                    totalPages={this.props.packages.result.total_page_count}
                    splitPage={this.splitPage}
                  />
                ) : null}
              </>
            ) : (
              this.noPackage()
            )
          ) : (
            <MiniPreload />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
    packages: state.packagesReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getPackages: bindActionCreators(predictionActions.packagesList, dispatch),
      // getCounters: bindActionCreators(userActions.counters, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
