import React, { Component } from "react";

export default class ConditionsOfUse extends Component {
  render() {
    return (
      <div className="cond-use">
        <h2 className="h_2">Kullanım Koşulları</h2>
        <div className="conditions">
          <div className="condition">
            <h6 className="h_8">ŞARTLARIN KABULÜ</h6>
            <p className="b_1">
              Bu Kullanım Koşulları (“Koşullar”), sizinle 1M1 platformu
              (“Platform”) arasında yapılan yasal olarak bağlayıcı bir
              sözleşmedir. Platforma erişerek veya platformu kullanarak bu
              Koşulları kabul etmiş sayılırsınız. Eğer bu şartları kabul
              etmiyorsanız, lütfen platformu kullanmayı derhal bırakınız.
            </p>
          </div>
          <div className="condition half">
            <h6 className="h_8">YASAL UYARI VE SORUMLULUK REDDİ</h6>
            <p className="b_1">
              1M1 platformu, kullanıcıların tahminlerinden doğabilecek
              sonuçlardan sorumlu tutulamaz. Teknik sorunlar, bağlantı
              kesintileri veya platformdaki hatalar nedeniyle yaşanabilecek
              aksaklıklardan dolayı herhangi bir sorumluluk kabul edilmez.
            </p>
          </div>
          <div className="condition half">
            <h6 className="h_8">HESAP GÜVENLİĞİ</h6>
            <p className="b_1">
              Hesabınızla ilgili tüm aktivitelerden siz sorumlusunuz. Şifrenizi
              güvende tutmanız ve yetkisiz erişimleri önlemek için gerekli
              önlemleri almanız gerekmektedir. Hesabınızın kötüye kullanılması
              durumunda platform derhal bilgilendirilmelidir.
            </p>
          </div>
          <div className="condition">
            <h6 className="h_8">KULLANICI BEYANLARI</h6>
            <h6 className="h_8">
              Platformu kullanarak aşağıdaki şartları kabul edersiniz:
            </h6>
            <div className="desc">
              <p className="b_1">
                <span className="--bb">1. Kayıt Bilgileri: </span>
                Sağladığınız tüm bilgiler doğru, güncel ve eksiksiz olacaktır.
              </p>
              <p className="b_1">
                <span className="--bb">2. Bilgi Güncelleme: </span>
                Bilgilerinizde bir değişiklik olması durumunda bunları zamanında
                güncelleyeceksiniz.
              </p>
              <p className="b_1">
                <span className="--bb">3. Yasal Yeterlilik: </span>
                Bu Kullanım Koşullarına uymayı ve platformu kullanmak için yasal
                yetkiye sahip olduğunuzu beyan edersiniz.
              </p>
              <p className="b_1">
                <span className="--bb">4. Yaş Sınırı: </span>
                Yaşadığınız yerde yasal olarak reşit olduğunuzu ve platformu
                kullanmaya uygun olduğunuzu kabul edersiniz.
              </p>
              <p className="b_1">
                <span className="--bb">5. Bot veya Otomasyon Kullanımı: </span>
                Platforma bot, script veya diğer otomatik yöntemlerle erişim
                sağlamayacaksınız. Platform yalnızca bireysel ve manuel kullanım
                için tasarlanmıştır.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
