import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import { withRouter } from "../../withRouter";
import ModalMessage from "../../modals/ModalMessage";
import * as userActions from "../../../redux/actions/userActions";
import { URL_LOGIN } from "../../../config";

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: 5,
      isRedirect: false,
      modalOpen: false,
      btnLoad: false,
      form: {
        password: "",
        password_again: "",
      },
      isWrong: {
        password: null,
        password_again: null,
      },
      terms: {
        password: {
          term1: false,
          term2: false,
          term3: false,
        },
        password_again: {
          term1: false,
        },
      },
      focus: {
        password: false,
        password_again: false,
      },
    };
  }

  componentWillUnmount() {
    this.clearTimer(); // Bileşen unmount olduğunda interval temizlenir
  }

  startTimer = () => {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.timeLeft > 0) {
            return { timeLeft: prevState.timeLeft - 1 }; // 1 saniye azalt
          } else {
            this.clearTimer(); // Süre dolunca timer durdurulur
            return { timeLeft: 0, isRedirect: true };
          }
        });
      }, 1000);
    }
  };

  // Timer'ı durduran fonksiyon
  clearTimer = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  passwordControl(value) {
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[^A-Za-z0-9]/.test(value);
    const hasRepeatedly = /(.)\1/.test(value);

    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        password: {
          ...prevState.terms.password,
          term1: value.length >= 8 ? true : false,
          term2: hasNumber && hasSpecialChar ? true : false,
          term3: !hasRepeatedly && value !== "" ? true : false,
        },
      },
    }));
  }

  passwordAgainControl(value) {
    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        password_again: {
          ...prevState.terms.password,
          term1:
            this.state.form.password === value && value !== "" ? true : false,
        },
      },
    }));
  }

  handleChange = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.value,
        },
      }),
      () => {
        switch (e.target.name) {
          case "password":
            this.passwordControl(e.target.value);
            this.passwordAgainControl();
            break;
          case "password_again":
            this.passwordAgainControl(e.target.value);
            break;
          default:
            break;
        }
      }
    );
  };

  handleFocus = (e) => {
    switch (e.currentTarget.id) {
      case "password":
        this.setState({
          focus: {
            password: true,
          },
        });
        break;
      case "password-again":
        this.setState({
          focus: {
            password_again: true,
          },
        });
        break;
      default:
        this.setState({
          focus: {
            password: false,
            password_again: false,
          },
        });
        break;
    }
  };

  handleBlur = (e) => {
    this.setState({
      focus: {
        password: false,
        password_again: false,
      },
    });
    switch (e.currentTarget.id) {
      case "password":
        this.setState((prevState) => ({
          isWrong: {
            ...prevState.isWrong,
            password:
              this.state.terms.password.term1 &&
              this.state.terms.password.term2 &&
              this.state.terms.password.term3
                ? false
                : true,
          },
        }));
        break;
      case "password-again":
        this.setState((prevState) => ({
          isWrong: {
            ...prevState.isWrong,
            password_again: this.state.terms.password_again.term1
              ? false
              : true,
          },
        }));
        break;
      default:
        break;
    }
  };

  send = (e) => {
    e.preventDefault();
    if (
      !this.state.isWrong.password &&
      this.state.isWrong.password !== null &&
      !this.state.isWrong.password_again &&
      this.state.isWrong.password_again !== null &&
      ((this.props.location?.state.form.mail !== "" &&
        this.props.location?.state.form.mail !== null) ||
        (this.props.location?.state.form.last_phone !== "" &&
          this.props.location?.state.form.last_phone !== null))
    ) {
      this.props.actions
        .sendForm(
          this.props.location?.state.form.mail,
          this.props.location?.state.form.last_phone,
          this.state.form.password
        )
        .then((result) => {
          if (result.payload === 200) {
            this.setState({ modalOpen: true }, () => {
              this.startTimer();
            });
          } else {
          }
          // console.log(result);
        });
    } else {
      // console.error("yog");
    }
  };

  render() {
    if (this.state.isRedirect) {
      return <Navigate to={URL_LOGIN} />;
    }

    return (
      <div className="set-password">
        {this.state.modalOpen ? (
          <ModalMessage
            message={"Yonlendiriliyorsunuz" + this.state.timeLeft}
          />
        ) : (
          ""
        )}

        <p className="b_1">
          Yeni şifrenizi belirlemek için lütfen şifrenizi iki kere girin.
        </p>
        <form>
          <div className="passwords">
            <div className="password">
              <label htmlFor="password" className="b_2">
                Şifre
              </label>
              <input
                type="password"
                id="password"
                className={
                  "b_2 input " +
                  (this.state.terms.password.term1 &&
                  this.state.terms.password.term2 &&
                  this.state.terms.password.term3
                    ? "correct"
                    : this.state.form.password !== ""
                    ? "wrong"
                    : "")
                }
                placeholder="********"
                name="password"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.password}
                onChange={this.handleChange}
                autoComplete="true"
              />
              <div
                className={
                  "terms non_select " +
                  (this.state.focus.password ? "active" : "")
                }
              >
                <div className="term">
                  <figure>
                    {this.state.terms.password.term1 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b_4 " +
                      (this.state.terms.password.term1 ? "correct" : "wrong")
                    }
                  >
                    En Az 8 Karakter Uzunluğunda Olmalı.
                  </p>
                </div>
                <div className="term">
                  <figure>
                    {this.state.terms.password.term2 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b_4 " +
                      (this.state.terms.password.term2 ? "correct" : "wrong")
                    }
                  >
                    Sayı ve Özel Karakter Ekleyin.
                  </p>
                </div>
                <div className="term">
                  <figure>
                    {this.state.terms.password.term3 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b_4 " +
                      (this.state.terms.password.term3 ? "correct" : "wrong")
                    }
                  >
                    Aynı Karakterleri Art Arda Kullanmayın.
                  </p>
                </div>
              </div>
            </div>
            <div className="password-again">
              <label htmlFor="password-again" className="b_2">
                Tekrar Şifre
              </label>
              <input
                type="password"
                id="password-again"
                className={
                  "b_2 input " +
                  (this.state.terms.password_again.term1
                    ? "correct"
                    : this.state.form.password_again !== ""
                    ? "wrong"
                    : "")
                }
                placeholder="********"
                name="password_again"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.password_again}
                onChange={this.handleChange}
                autoComplete="false"
              />
              <div
                className={
                  "terms non_select " +
                  (this.state.focus.password_again &&
                  this.state.form.password_again !== ""
                    ? "active"
                    : "")
                }
              >
                <div className="term">
                  <p
                    className={"b_4 wrong"}
                    style={
                      this.state.terms.password_again.term1
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    Girdiğiniz Şifreler Eşleşmiyor
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            onClick={this.send}
            className="background_btn"
            disabled={this.state.btnLoad ? true : false}
            data-loader={this.state.btnLoad ? true : false}
          >
            <span className="btn-loader"></span>
            <h4 className="b_1">Onayla</h4>
          </button>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // endTime: state.resetPasswordCodeReducer,
    // form_obj: state.sendFormObjectReducer,
    // verify_code: state.verifyPhoneorMailReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      sendForm: bindActionCreators(userActions.resetPassword, dispatch),
      // sendCodeAgain: bindActionCreators(
      //   userActions.resetPasswordCode,
      //   dispatch
      // ),
      // code_email_verify: bindActionCreators(userActions.verifyEmail, dispatch),
      // code_phone_verify: bindActionCreators(userActions.verifyPhone, dispatch),
      // verifyEmailCode: bindActionCreators(
      //   userActions.verifyEmailCode,
      //   dispatch
      // ),
      // verifyPhoneCode: bindActionCreators(
      //   userActions.verifyPhoneCode,
      //   dispatch
      // ),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SetPassword));
