import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import MiniPreload from "../preload/MiniPreload";

class PeriodDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = createRef();
    this.state = {
      periods: ["Haftalık", "Aylık", "Sezonluk"],
      isOpen: false,
      selectedPeriod: null,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.updateSelectedPeriod();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      !this.dropdownRef.current ||
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ isOpen: null }); // Dışarı tıklanınca kapat
    }
  };

  updateSelectedPeriod = () => {
    if (this.props.splitPeriod && this.props.splitPeriod[1]) {
      this.setState({
        selectedPeriod: {
          name: this.state.periods[this.props.splitPeriod[1] - 1],
          i: this.props.splitPeriod[1] - 1,
        },
      });
    } else {
      this.setState({
        selectedPeriod: {
          name: this.state.periods[0],
          i: 1,
        },
      });
    }
  };

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option, i) => {
    this.setState(
      {
        isOpen: false,
        selectedPeriod: { name: option, i },
      },
      () => {
        const href = "period=" + this.state.selectedPeriod.i;

        let search = window.location.search.replace(/^\?&/, "?");
        let newSearch = search
          .replace(/(\?|&)week=[^&]*(&|$)/, (_, start, end) =>
            start === "?" ? "?" : end ? start : ""
          )
          .replace(/(\?|&)month=[^&]*(&|$)/, (_, start, end) =>
            start === "?" ? "?" : end ? start : ""
          )
          .replace(/(\?|&)season=[^&]*(&|$)/, (_, start, end) =>
            start === "?" ? "?" : end ? start : ""
          )
          .replace(/\?&/, "?")
          .replace(/^&/, "?")
          .replace(/^\?$/, "");

        if (window.location.search.includes("period=")) {
          if (this.state.selectedPeriod.i === 0) {
            newSearch = newSearch
              .replace(/&?period=[^&]*/, "")
              .replace(/\?&/, "?");
          } else {
            newSearch = newSearch.replace(/period=[^&]*/, href);
          }
          window.location.search = newSearch;
        } else {
          if (this.state.selectedPeriod.i !== 0)
            window.location.search += `${
              window.location.search.includes("?") ? "&" : "?"
            }${href}`;
        }
      }
    );
  };

  render() {
    const { periods } = this.state;

    return periods.length > 1 ? (
      <div className="periods">
        <p className="b_1 non_select">Periyod</p>
        <div className="periods-dropdown" ref={this.dropdownRef}>
          <div
            className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
            onClick={(e) => this.toggleDropdown(e)}
          >
            <div className="content-drop">
              <div className="txt">
                <p className="b_3 --m non_select">
                  {this.state.selectedPeriod !== null
                    ? this.state.selectedPeriod.name
                    : null}
                </p>
              </div>
              <figure className={`arrow`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" />
                </svg>
              </figure>
            </div>
          </div>
          {this.state.isOpen && (
            <ul className="options hide-scroll">
              {periods.map((w, i) => (
                <li
                  className="option"
                  key={i}
                  onClick={() => this.handleOptionClick(w, i + 1)}
                >
                  <div className="txt">
                    <p className="b_3 non_select">{w}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    ) : (
      <MiniPreload />
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCounters: bindActionCreators(userActions.counters, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodDropdown);
