import React, { Component } from "react";
import { Routes, Route, Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";

// Register
import RegisterForm from "../register/Form";
import FavTeamAndLeague from "../register/FavTeamAndLeague";
import VerifyCode from "../register/VerifyCode";

// Login
import LoginForm from "../login/Form";
import ForgotPassword from "../login/ForgotPassword";

// IMG
// import imgFottball from "../../img/register.png";
// import imgFottball from "../../img/login_register.png";

import PageScrollToTop from "../scrollToTop/PageScrollToTop";
import NotFound from "./NotFound";
import * as Appjs from "../../js/app";
import {
  URL_FAVORITE_L_AND_T,
  URL_LOGIN_FORGOT_PASSWORD,
  URL_REGISTER,
  URL_VERIFY_CODE,
} from "../../config";

class RegisterandLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
    };
  }

  componentDidMount() {
    // Appjs.registerOverflow();
    this.tokenControl();
    document.getElementById("root").style.padding = 0;
  }

  tokenControl() {
    if (Appjs.tokenControl(this.props.token)) {
      this.setState({ isRedirect: true });
    } else {
      this.setState({ isRedirect: false });
    }
  }

  render() {
    if (this.state.isRedirect) {
      return <Navigate to="/" />;
    }

    return (
      <div className="register-login-page">
        <div className="img">
          <img src="/img/login_register.jpg" alt="Fottball" />
        </div>
        {window.location.pathname.startsWith(URL_REGISTER) ? (
          <div className="content register" id="overflow-register">
            <div className="register_login_hood">
              <Link to="/">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 170">
                    <path d="M15.1,154.3h33.4v-59.3h53v59.3h33.4v-89.8l-59.9-45.1L15.1,64.5v89.8ZM.2,169.3V57L75,.7l74.8,56.3v112.2h-63.3v-59.3h-23v59.3H.2Z" />
                  </svg>
                </figure>
              </Link>
              <h6 className="h_6">Kayıt Ol</h6>
            </div>
            <PageScrollToTop targetId={"overflow-register"} />
            <Routes>
              <Route path="/" element={<RegisterForm />} />
              <Route path={URL_VERIFY_CODE} element={<VerifyCode />} />
              <Route
                path={URL_FAVORITE_L_AND_T}
                element={<FavTeamAndLeague />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        ) : (
          <div className="content login" id="overflow-register">
            <div className="register_login_hood main-hood">
              <Link to="/">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 170">
                    <path d="M15.1,154.3h33.4v-59.3h53v59.3h33.4v-89.8l-59.9-45.1L15.1,64.5v89.8ZM.2,169.3V57L75,.7l74.8,56.3v112.2h-63.3v-59.3h-23v59.3H.2Z" />
                  </svg>
                </figure>
              </Link>
              <h4 className="h_6 ">Giriş Yap</h4>
            </div>
            <PageScrollToTop targetId={"overflow-register"} />
            <Routes>
              <Route path="/" element={<LoginForm />} />
              <Route
                path={`${URL_LOGIN_FORGOT_PASSWORD}/*`}
                element={<ForgotPassword />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps)(RegisterandLogin);
