import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import MiniPreload from "../preload/MiniPreload";

class SeasonDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = createRef();
    this.state = {
      filterSeason: this.props.default ? [] : ["Tüm Sezonlar"],
      isOpen: false,
      selectedSeason: null,
      season: null,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getSeason();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      !this.dropdownRef.current ||
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ isOpen: null }); // Dışarı tıklanınca kapat
    }
  };

  getSeason = async () => {
    this.props.actions
      .getCounters(this.props.token, "season")
      .then((season) => {
        let newArray = [];
        this.setState({ season: season.counter });
        for (let i = season.start; i <= season.counter; i++) {
          newArray.push({
            i: i,
            name: i !== season.counter ? `${i}. Sezon` : "Bu Sezon",
          });
        }
        this.setState(
          (prevState) => ({
            filterSeason: [...prevState.filterSeason, ...newArray],
          }),
          () => {
            this.updateSelectedSeason();
          }
        );
      });
  };

  updateSelectedSeason = () => {
    if (this.props.splitSeason && this.props.splitSeason[1]) {
      this.state.filterSeason.map(
        (s) =>
          s.i == this.props.splitSeason[1] &&
          this.setState({
            selectedSeason: {
              i: s.i,
              name: s.name,
            },
          })
      );
    } else {
      this.state.filterSeason.map(
        (s) =>
          s.i == this.state.season &&
          this.setState({
            selectedSeason: {
              i: s.i,
              name: s.name,
            },
          })
      );
    }
  };

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option, i) => {
    this.setState(
      {
        isOpen: false,
        selectedSeason: { name: option, i },
      },
      () => {
        const href = "season=" + this.state.selectedSeason.i;

        if (window.location.search.includes("season=")) {
          let newSearch;
          if (
            (!this.props.default && this.state.selectedSeason.i === 0) ||
            (this.props.default &&
              this.state.selectedSeason.i === this.state.season)
          ) {
            newSearch = window.location.search.replace(/&season=[^&]*/, "");
          } else {
            newSearch = window.location.search.replace(/season=[^&]*/, href);
          }
          window.location.search = newSearch;
        } else {
          if (
            (!this.props.default && this.state.selectedSeason.i !== 0) ||
            (this.props.default &&
              this.state.selectedSeason.i !== this.state.season)
          )
            window.location.search += `&${href}`;
        }
      }
    );
  };

  render() {
    const { filterSeason } = this.state;

    return filterSeason && filterSeason.length > 0 ? (
      <div className="week">
        <p className="b_1 non_select">Sezon</p>
        <div className="week-dropdown" ref={this.dropdownRef}>
          <div
            className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
            onClick={(e) => this.toggleDropdown(e)}
          >
            <div className="content-drop">
              <div className="txt">
                <p className="b_3 --m non_select">
                  {this.state.selectedSeason !== null
                    ? this.state.selectedSeason.name
                    : null}
                </p>
              </div>
              <figure className={`arrow`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" />
                </svg>
              </figure>
            </div>
          </div>
          {this.state.isOpen && (
            <ul className="options hide-scroll">
              {filterSeason.map(
                (w, i) =>
                  !this.props.default &&
                  i === 0 && (
                    <li
                      className="option"
                      key={w.i}
                      onClick={() => this.handleOptionClick(w.name, w.i)}
                    >
                      <div className="txt">
                        <p className="b_3 non_select">{w.name}</p>
                      </div>
                    </li>
                  )
              )}
              <span
                style={{ display: "flex", flexDirection: "column-reverse" }}
              >
                {filterSeason.map((w, i) =>
                  !this.props.default ? (
                    i !== 0 && (
                      <li
                        className="option"
                        key={w.i}
                        onClick={() => this.handleOptionClick(w.name, w.i)}
                      >
                        <div className="txt">
                          <p className="b_3 non_select">{w.name}</p>
                        </div>
                      </li>
                    )
                  ) : (
                    <li
                      className="option"
                      key={w.i}
                      onClick={() => this.handleOptionClick(w.name, w.i)}
                    >
                      <div className="txt">
                        <p className="b_3 non_select">{w.name}</p>
                      </div>
                    </li>
                  )
                )}
              </span>
            </ul>
          )}
        </div>
      </div>
    ) : (
      <MiniPreload />
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCounters: bindActionCreators(userActions.counters, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SeasonDropdown);
