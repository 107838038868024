import React, { Component } from "react";
import ProgressBar from "../ProgressBar";
import { formatPoint } from "../../../js/app";

export default class AchievementCard extends Component {
  stat = (id) => {
    switch (id) {
      case 0:
        return "bronze";
      case 1:
        return "silver";
      case 2:
        return "gold";
      default:
        break;
    }
  };

  isBlock = (userDetails, id) => {
    if (userDetails) return id < userDetails.completed_level ? "" : "block";
    else return "block";
  };

  render() {
    const { ach, id } = this.props;
    return (
      <div
        className={`achievement_card ${this.stat(id)} ${this.isBlock(
          ach.user_achievement_details,
          id
        )}`}
      >
        {/* {console.log(ach)} */}
        <figure>
          <img src={ach.badge_urls[id]} alt="Achievement" />
        </figure>
        <div className="card_content">
          <h6 className="b_3 --m">{ach.name}</h6>
          <p className="b_4">{ach.desc}</p>
          <div className="bottom">
            <p className="b_2 --m">{`+${formatPoint(ach.points[id])} Puan`}</p>
            <div className="bar">
              <p className="b_4">{`%${ach.level_completed_percent[id]} Tamamlandi`}</p>
              <ProgressBar
                point={ach.level_completed_percent[id]}
                percent="100"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
