import React, { Component } from "react";
import { Link } from "react-router-dom";
import { URL_MATCHES } from "../../../config";

export default class League extends Component {
  premiumControl(league) {
    if (league.is_premium) {
      return true;
    } else {
      return false;
    }
  }

  leagueDesc(id) {
    switch (id) {
      case 1:
        return "Fenerbahçe, Galatasaray, Beşiktaş, Trabzonspor, Başakşehir ve daha fazlası";
      case 2:
        return "Arsenal, Chelsea, Liverpool, Manchester City, Manchester United ve daha fazlası";
      case 3:
        return "PSG, Lille, Lens, Monaco, Lyon, Bres, Rennes, Marsellie ve daha fazlası";
      case 4:
        return "FC Bayern Münih, Leipzig, SC Freiburg, Bayer 04 Leverkusen ve daha fazlası";
      case 5:
        return "Braga, Porto, Rio Ave, Estoril, Benfica, Farence, Boavista ve daha fazlası";
      case 6:
        return "AC Milan, AS Roma, Atalanta, Bologna, Empoli, Fiorentina ve daha fazlası";
      case 7:
        return "Athletico Madrid, Barcelona FC, Real Madrid, Sevilla FC ve daha fazlası";
      case 8:
        return "Genk, Gent, Standard Liege, Mechelen, Sint-Truiden ve daha fazlası";
      case 9:
        return "Olympiacos, Panathinaikos, Panionios F.C, Niki Volos, AEK, PAOK ve daha fazlası";
      case 10:
        return "Krasnodar, Lokomotiv Moscow, Zenit, Spartak Moskova ve daha fazlası";
      case 11:
        return "Arsenal , Monaco, Milan , PSG, Real Madrid, Juventus, Bologna ve daha fazlası";
      case 12:
        return "Porto, Beşiktaş JK, Ajax, Fenerbahçe, Frankfurt, Roma, Slavia Praha ve daha fazlası";
      default:
        return "";
    }
  }
  render() {
    const { league } = this.props;

    return (
      <div
        className={
          "league_component " + (this.premiumControl(league) ? "premium" : "")
        }
      >
        <span className="block">
          <figure className="non_select--img">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6l277.2 0c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
            </svg>
          </figure>
        </span>
        <div className="content_league">
          <figure className="non_select--img">
            <img src={"/media/" + league.flag} alt={league.name + " Flag"} />
          </figure>
          <h6 className="h_8">{league.name}</h6>
          <p className="b_2">{this.leagueDesc(league.id)}</p>
        </div>
        <div className="bottom">
          <p className="b_2 --m">
            {league.match_count === 0
              ? "Hiç Maç Bulunmuyor"
              : `${league.match_count} Maç Bulunuyor`}
          </p>
          <Link
            to={`${URL_MATCHES}?${league.name.replace(/ /g, "")}=${league.id}`}
            reloadDocument
            className="background_btn b_1"
          >
            Görüntüle
          </Link>
        </div>
      </div>
    );
  }
}
