import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div className="about_page">
        <img src="/img/1m1.svg" alt="logo" />
        <p className="h_8">
          Bir Milyonda Bir, spor tutkusunu bir adım öteye taşıyan interaktif bir
          tahmin dünyasıdır. Burada, kullanıcılarımız maç tahminleri yaparak
          bilgilerini ve öngörülerini sergileme fırsatı buluyor. Her doğru
          tahmininiz size puan kazandırıyor; bu puanlar, heyecan dolu liderlik
          tablosunda sıralamanızı belirliyor. Hedefimiz, 1 milyon puana ulaşarak
          büyük ödülü kazanacak olan kullanıcıyı belirlemek!
          <span style={{ marginTop: "20px" }}>
            Neden Bir Milyonda Bir?
            <span>
              Eğlenceli ve Rekabetçi: Sporun heyecanını, dostça rekabetin
              coşkusuyla birleştiriyoruz.
            </span>
            <span>
              Ödüllü Yarışma: Her maç, sizi büyük ödül yolunda bir adım daha
              ileri taşımak için bir fırsat.
            </span>
            <span>
              Kullanıcı Dostu: Güvenli kayıt sistemi ve sezgisel arayüzümüzle,
              her seviyeden kullanıcıya hitap ediyoruz.
            </span>
            Bir Milyonda Bir, spor tahminlerini sadece bir oyun olmaktan
            çıkarıp, bilgi ve şansınızı sergileyebileceğiniz bir arenaya
            dönüştürüyor. Siz de tahmin yeteneklerinizle liderlik tablosunda
            yerinizi almaya hazır mısınız? Sporun, rekabetin ve eğlencenin
            buluştuğu bu eşsiz deneyimin parçası olun!
          </span>
        </p>
        {/* <p className="h_8">
          Neden Bir Milyonda Bir?
          <span>
            Eğlenceli ve Rekabetçi: Sporun heyecanını, dostça rekabetin
            coşkusuyla birleştiriyoruz.
          </span>
          <span>
            Ödüllü Yarışma: Her maç, sizi büyük ödül yolunda bir adım daha ileri
            taşımak için bir fırsat.
          </span>
          <span>
            Kullanıcı Dostu: Güvenli kayıt sistemi ve sezgisel arayüzümüzle, her
            seviyeden kullanıcıya hitap ediyoruz.
          </span>
          Bir Milyonda Bir, spor tahminlerini sadece bir oyun olmaktan çıkarıp,
          bilgi ve şansınızı sergileyebileceğiniz bir arenaya dönüştürüyor. Siz
          de tahmin yeteneklerinizle liderlik tablosunda yerinizi almaya hazır
          mısınız? Sporun, rekabetin ve eğlencenin buluştuğu bu eşsiz deneyimin
          parçası olun!
        </p> */}
      </div>
    );
  }
}
