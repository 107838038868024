import { Navigate, Route, Router, Routes } from "react-router-dom";
import { connect } from "react-redux";
import Leaderboard from "../pages/Leaderboard";
import Matches from "../pages/Matches";
import NotFound from "../pages/NotFound";
import Homepage from "../pages/Homepage";
import UserHomepage from "../pages/UserHomepage";
import RegisterandLogin from "../pages/RegisterandLogin";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer";
import ConditionsOfUse from "../declarations/ConditionsOfUse";
import PrivacyPolicy from "../declarations/PrivacyPolicy";
import FAQs from "../declarations/FAQs";
import FooterMini from "../layouts/FooterMini";
import Prediction from "../pages/Prediction";
import BugReport from "../layouts/BugReport";
import Leagues from "../pages/Leagues";
import PackageDetail from "../pages/PackageDetail";
import Packages from "../pages/Packages";
import Profile from "../pages/Profile";
import * as AppJs from "../../js/app";
import * as config from "../../config";
import NavigationMobile from "../layouts/NavigationMobile";
import PrivacyPolicyEN from "../declarations/PrivacyPolicyEN";

function Dashboard(props) {
  return (
    <>
      {/* {console.log(this.props.matches)} */}
      {!props.isMobile ? <Navigation /> : <NavigationMobile />}
      <ScrollToTop />
      <Routes>
        {AppJs.tokenControl(props.token) ? (
          <>
            <Route
              path="/"
              element={<UserHomepage isMobile={props.isMobile} />}
            />
            <Route path={config.URL_LEADERBOARD} element={<Leaderboard />} />
            <Route path={config.URL_LEAGUES} element={<Leagues />} />
            <Route path={config.URL_MATCHES} element={<Matches />} />
            <Route
              path={config.URL_PACKAGE_DETAIL}
              element={<PackageDetail />}
            />
            <Route path={config.URL_PACKAGES} element={<Packages />} />
            <Route path={config.URL_PREDICTION} element={<Prediction />} />
            <Route path={config.URL_PROFILE} element={<Profile />} />
          </>
        ) : (
          <Route path="/" element={<Homepage />} />
        )}
        <Route
          path={config.URL_CONDITIONS_OF_USE}
          element={<ConditionsOfUse />}
        />
        <Route path={config.URL_PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route
          path={config.URL_PRIVACY_POLICY_EN}
          element={<PrivacyPolicyEN />}
        />
        <Route path={config.URL_FAQ_S} element={<FAQs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!props.isMobile &&
        (AppJs.tokenControl(props.token) ? (
          <>
            {/* <BugReport /> */}
            <FooterMini />
          </>
        ) : (
          <Footer />
        ))}
    </>
  );
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps)(Dashboard);
