import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function getPointHistoryReducer(
  state = initialState.achievements,
  action
) {
  switch (action.type) {
    case actionTypes.GET_POINT_HISTORY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
