import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function getLeaderboardReducer(
  state = initialState.leaderboard,
  action
) {
  switch (action.type) {
    case actionTypes.GET_LEADERBOARD_SUCCESS:
      return action.payload;
    case actionTypes.GET_LEADERBOARD_ALL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
