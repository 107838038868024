import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import MiniPreload from "../preload/MiniPreload";

class UserLeagueDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = createRef();
    this.state = {
      leagues: [],
      isOpen: false,
      selectedLeague: null,
    };
  }

  componentDidMount() {
    // this.updateSelectedLeague();
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getuserLeagues();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      !this.dropdownRef.current ||
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ isOpen: null }); // Dışarı tıklanınca kapat
    }
  };

  getuserLeagues = async () => {
    this.props.actions.getUserLeagues(this.props.token).then((league) => {
      // console.log(league);
      let newArray = [];
      // this.setState({ month: month.counter });
      for (let i = 0; i < league.length; i++) {
        // newArray.push(i + 1 !== month.counter ? i + 1 + ". Ay" : "Bu Ay");
        // console.log(league[i]);
        newArray.push({ id: league[i].id, name: league[i].name });
      }
      this.setState(
        (prevState) => ({
          leagues: [...prevState.leagues, ...newArray],
        }),
        () => {
          // console.log(this.state.leagues);
          this.updateSelectedLeague();
        }
      );
    });
  };

  updateSelectedLeague = () => {
    if (this.props.splitLeague && this.props.splitLeague[1]) {
      this.state.leagues.map(
        (l) =>
          l.id == this.props.splitLeague[1] &&
          this.setState({
            selectedLeague: {
              name: l.name,
              i: l.id,
            },
          })
      );
    }
  };

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option, i) => {
    this.setState(
      {
        isOpen: false,
        selectedLeague: { name: option, i },
      },
      () => {
        window.location.search = "league=" + this.state.selectedLeague.i;
      }
    );
  };

  render() {
    const { leagues } = this.state;

    return leagues.length > 0 ? (
      <div className="user_leagues">
        <p className="b_1 non_select">Ligler</p>
        <div className="leagues-dropdown" ref={this.dropdownRef}>
          <div
            className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
            onClick={(e) => this.toggleDropdown(e)}
          >
            <div className="content-drop">
              <div className="txt">
                <p className="b_3 --m non_select">
                  {this.state.selectedLeague !== null
                    ? this.state.selectedLeague.name
                    : null}
                </p>
              </div>
              <figure className={`arrow`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" />
                </svg>
              </figure>
            </div>
          </div>
          {this.state.isOpen && (
            <ul className="options hide-scroll">
              {leagues.map((l) => (
                <li
                  className="option"
                  key={l.id}
                  onClick={() => this.handleOptionClick(l.name, l.id)}
                >
                  <div className="txt">
                    <p className="b_3 non_select">{l.name}</p>
                  </div>
                </li>
              ))}
              {/* <span
                style={{ display: "flex", flexDirection: "column-reverse" }}
              >
                {leagues.map(
                  (w, i) =>
                    i !== 0 && (
                      <li
                        className="option"
                        key={i}
                        onClick={() => this.handleOptionClick(w, i)}
                      >
                        <div className="txt">
                          <p className="b_3 non_select">{w}</p>
                        </div>
                      </li>
                    )
                )}
              </span> */}
            </ul>
          )}
        </div>
      </div>
    ) : (
      <MiniPreload />
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      // getCounters: bindActionCreators(userActions.counters, dispatch),
      getUserLeagues: bindActionCreators(userActions.getUserLeagues, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLeagueDropdown);
