import React, { Component } from "react";

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.splitPage[1],
      maxPageNumbersToShow: 5, // Maksimum gösterilecek sayfa numarası
    };
  }

  // Sayfa değiştirme fonksiyonu
  handlePageChange = (newPage) => {
    if (this.props.splitPage)
      this.setState({ currentPage: newPage }, () => {
        const href = "page=" + newPage;

        if (window.location.search.includes("page=")) {
          let newSearch;
          newSearch = window.location.search.replace(/page=[^&]*/, href);

          window.location.search = newSearch;
        } else {
          window.location.search += `&${href}`;
        }
      });
  };

  // Sayfa numaralarını hesapla
  getPageNumbers = () => {
    const { currentPage, maxPageNumbersToShow } = this.state;
    const totalPages = this.props.totalPages;

    const pageNumbers = [];

    // Sayfanın başı ve sonu
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxPageNumbersToShow / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return { pageNumbers, totalPages };
  };

  render() {
    const { pageNumbers, totalPages } = this.getPageNumbers();

    return (
      <div className="pagination">
        <button
          className="arrow_btn"
          onClick={() => this.handlePageChange(1)}
          disabled={this.state.currentPage == 1}
        >
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160zm352-160l-160 160c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 438.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z" />
            </svg>
          </figure>
        </button>

        <button
          className="arrow_btn"
          onClick={() => this.handlePageChange(this.state.currentPage - 1)}
          disabled={this.state.currentPage == 1}
        >
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
          </figure>
        </button>

        {pageNumbers[0] > 1 && <span className="b_3 --m">...</span>}

        {pageNumbers.map((num) => (
          <button
            className={`b_3 --m ${
              this.state.currentPage == num ? "active --bb" : ""
            }`}
            key={num}
            onClick={() => this.handlePageChange(num)}
          >
            {num}
          </button>
        ))}

        {pageNumbers[pageNumbers.length - 1] < totalPages && (
          <span className="b_3 --m">...</span>
        )}

        <button
          className="arrow_btn"
          onClick={() =>
            this.handlePageChange(parseInt(this.state.currentPage) + 1)
          }
          disabled={this.state.currentPage == totalPages}
        >
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
            </svg>
          </figure>
        </button>

        <button
          className="arrow_btn"
          onClick={() => this.handlePageChange(totalPages)}
          disabled={this.state.currentPage == totalPages}
        >
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z" />
            </svg>
          </figure>
        </button>
      </div>
    );
  }
}
