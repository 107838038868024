import React, { Component } from "react";
import { Link } from "react-router-dom";
import Match from "../components/matches/Match";
import MiniPreload from "../preload/MiniPreload";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as matchActions from "../../redux/actions/matchActions";
import * as predictionActions from "../../redux/actions/predictionActions";
import { URL_MATCHES } from "../../config";

class FavoriteMatch extends Component {
  componentDidMount() {
    if (
      this.props.favorite_matches &&
      this.props.favorite_matches.length <= 0
    ) {
      this.props.actions.getPopularMatches(this.props.token);
    }
    if (this.props.token) this.props.actions.getBasket(this.props.token);
  }

  render() {
    return (
      <div className="favorite_match">
        <div className="hood_match">
          <h5 className="h_5">Maçlar</h5>
          <Link to={URL_MATCHES} className="outline_btn non_select">
            <p className="b_1 --m non_select">Tüm Maçları Görüntüle</p>
          </Link>
        </div>
        <div className="matches">
          {this.props.basket.state !== 200 &&
          this.props.favorite_matches.length <= 0 ? (
            <MiniPreload />
          ) : (
            this.props.favorite_matches.matches.map((match) => (
              <Match
                match={match}
                basket={this.props.basket}
                deleteToBasket={this.props.actions.deleteToBasket}
                token={this.props.token}
                key={match.id}
                user={this.props.user}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
      getPopularMatches: bindActionCreators(
        matchActions.getPopularMatches,
        dispatch
      ),
      deleteToBasket: bindActionCreators(
        predictionActions.deleteToBasket,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    favorite_matches: state.popularMatchesReducer,
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteMatch);
