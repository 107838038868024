import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="privacy-policy">
        <h2 className="h_2">Gizlilik Politikası</h2>
        <div className="policies">
          <div className="policy">
            <p className="b_1">
              Bu gizlilik politikası, mobil cihazlar için 1M1 uygulaması (burada
              “Uygulama” olarak anılacaktır) için geçerlidir ve Azelir Brevo
              Yazılım Teknolojileri Ltd. Şti. (burada “Hizmet Sağlayıcı” olarak
              anılacaktır) Reklam Destekli bir hizmet olarak. Bu hizmet “OLDUĞU
              GİBİ” kullanılmak üzere tasarlanmıştır.
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">Bilgi Toplama ve Kullanma</h6>
            <h6 className="b_1">
              Uygulama, onu indirdiğinizde ve kullandığınızda bilgi toplar. Bu
              bilgiler aşağıdaki gibi bilgileri içerebilir
            </h6>
            <div className="desc">
              <p className="b_1">
                <span className="--bb">
                  Cihazınızın İnternet Protokol adresi (örn. IP adresi)
                </span>
              </p>
              <p className="b_1">
                <span className="--bb">
                  Uygulamanın ziyaret ettiğiniz sayfaları, ziyaretinizin saati
                  ve tarihi, bu sayfalarda geçirdiğiniz süre
                </span>
              </p>
              <p className="b_1">
                <span className="--bb">Uygulama için harcanan zaman</span>
              </p>
              <p className="b_1">
                <span className="--bb">
                  Mobil cihazınızda kullandığınız işletim sistemi
                </span>
              </p>
            </div>
          </div>
          <div className="policy half">
            <p className="b_1">
              Uygulama, mobil cihazınızın konumu hakkında kesin bilgi toplamaz.
            </p>
            <p className="b_1">
              Hizmet Sağlayıcı, sağladığınız bilgileri zaman zaman sizinle
              iletişime geçerek önemli bilgiler, gerekli bildirimler ve
              pazarlama promosyonları sağlamak için kullanabilir.
            </p>
            <p className="b_1">
              Daha iyi bir deneyim için, Uygulamayı kullanırken, Hizmet
              Sağlayıcı bize ad, soyad, kimlik numarası, cinsiyet, kullanıcı
              adı, şifre, e-posta, telefon numarası, doğum tarihi dahil ancak
              bunlarla sınırlı olmamak üzere kişisel olarak tanımlanabilir
              belirli bilgileri sağlamanızı isteyebilir. Hizmet Sağlayıcının
              talep ettiği bilgiler kendileri tarafından saklanacak ve bu
              gizlilik politikasında açıklandığı şekilde kullanılacaktır.
            </p>
          </div>
          <div className="policy">
            <h6 className="h_8">Üçüncü Taraf Erişimi</h6>
            <p className="b_1">
              Yalnızca toplu, anonimleştirilmiş veriler, Hizmet Sağlayıcının
              Uygulamayı ve hizmetlerini geliştirmesine yardımcı olmak için
              harici hizmetlere periyodik olarak iletilir. Hizmet Sağlayıcı,
              bilgilerinizi bu gizlilik bildiriminde açıklanan şekillerde üçüncü
              taraflarla paylaşabilir.
            </p>
            <p className="b_1">
              Uygulamanın, verileri işleme konusunda kendi Gizlilik
              Politikalarına sahip üçüncü taraf hizmetleri kullandığını lütfen
              unutmayın. Aşağıda, Uygulama tarafından kullanılan üçüncü taraf
              hizmet sağlayıcılarının Gizlilik Politikası bağlantıları
              bulunmaktadır:
            </p>
            <p className="b_1">Google Play Services</p>
            <p className="b_1">AdMob</p>
            <p className="b_1">Google Analytics for Firebase</p>
            <p className="b_1">Facebook</p>
            <p className="b_1">Expo</p>
            <p className="b_1">
              Hizmet Sağlayıcı, Kullanıcı Tarafından Sağlanan ve Otomatik Olarak
              Toplanan Bilgileri ifşa edebilir:
            </p>
            <p className="b_1">
              Bir mahkeme celbine veya benzer bir yasal sürece uymak gibi
              yasaların gerektirdiği şekilde; haklarını korumak, sizin veya
              başkalarının güvenliğini korumak, dolandırıcılığı araştırmak veya
              bir devlet talebine yanıt vermek için ifşanın gerekli olduğuna iyi
              niyetle inandıklarında; kendi adlarına çalışan, kendilerine ifşa
              ettiğimiz bilgileri bağımsız olarak kullanmayan ve bu gizlilik
              bildiriminde belirtilen kurallara uymayı kabul eden güvenilir
              hizmet sağlayıcılarıyla.
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">Ayrılma Hakları</h6>
            <h6 className="b_1">
              Uygulamayı kaldırarak Uygulama tarafından tüm bilgilerin
              toplanmasını kolayca durdurabilirsiniz. Mobil cihazınızın bir
              parçası olarak veya mobil uygulama marketi ya da ağı aracılığıyla
              mevcut olabilecek standart kaldırma işlemlerini kullanabilirsiniz.
            </h6>
          </div>
          <div className="policy half">
            <h6 className="h_8">Veri Saklama Politikası</h6>
            <p className="b_1">
              Hizmet Sağlayıcı, Kullanıcı Tarafından Sağlanan verileri
              Uygulamayı kullandığınız sürece ve sonrasında makul bir süre
              boyunca saklayacaktır. Uygulama aracılığıyla sağladığınız
              Kullanıcı Tarafından Sağlanan Verileri silmelerini istiyorsanız,
              lütfen info@1m1.com.tr adresinden onlarla iletişime geçin ve makul
              bir süre içinde yanıt vereceklerdir.
            </p>
          </div>
          <div className="policy">
            <h6 className="h_8">Çocuklar</h6>
            <p className="b_1">
              Hizmet Sağlayıcı, Uygulamayı 13 yaşın altındaki çocuklardan
              bilerek veri istemek veya onlara pazarlamak için kullanmaz.
            </p>
            <p className="b_1">
              Uygulama 13 yaşın altındaki hiç kimseye hitap etmemektedir. Hizmet
              Sağlayıcı, 13 yaşından küçük çocuklardan bilerek kişisel olarak
              tanımlanabilir bilgi toplamaz. Hizmet Sağlayıcının 13 yaşından
              küçük bir çocuğun kişisel bilgi verdiğini tespit etmesi durumunda,
              Hizmet Sağlayıcı bu bilgileri derhal sunucularından silecektir.
              Ebeveyn veya veli iseniz ve çocuğunuzun bize kişisel bilgi
              verdiğinin farkındaysanız, lütfen gerekli işlemleri yapabilmeleri
              için Hizmet Sağlayıcı (info@1m1.com.tr) ile iletişime geçin.
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">Güvenlik</h6>
            <h6 className="b_1">
              Hizmet Sağlayıcı, bilgilerinizin gizliliğini korumakla ilgilenir.
              Hizmet Sağlayıcı, Hizmet Sağlayıcının işlediği ve muhafaza ettiği
              bilgileri korumak için fiziksel, elektronik ve prosedürel
              güvenceler sağlar.
            </h6>
          </div>
          <div className="policy half">
            <h6 className="h_8">Değişiklikler</h6>
            <p className="b_1">
              Bu Gizlilik Politikası herhangi bir nedenle zaman zaman
              güncellenebilir. Hizmet Sağlayıcı, bu sayfayı yeni Gizlilik
              Politikası ile güncelleyerek Gizlilik Politikasında yapılan
              değişiklikleri size bildirecektir. Kullanmaya devam etmeniz tüm
              değişiklikleri onayladığınız anlamına geleceğinden, değişiklikler
              için bu Gizlilik Politikasına düzenli olarak başvurmanız tavsiye
              edilir.
            </p>
            <p className="b_1">
              Bu gizlilik politikası 2025-03-26 tarihinden itibaren geçerlidir
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">Sizin Onayınız</h6>
            <h6 className="b_1">
              Uygulamayı kullanarak, bilgilerinizin şu anda bu Gizlilik
              Politikasında belirtildiği ve tarafımızca değiştirildiği şekilde
              işlenmesine izin vermiş olursunuz.
            </h6>
          </div>
          <div className="policy half">
            <h6 className="h_8">Bize Ulaşın</h6>
            <p className="b_1">
              Uygulamayı kullanırken gizlilikle ilgili herhangi bir sorunuz
              varsa veya uygulamalar hakkında sorularınız varsa, lütfen
              info@1m1.com.tr adresinden e-posta yoluyla Hizmet Sağlayıcı ile
              iletişime geçin.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
