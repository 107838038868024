import React, { Component, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import MiniPreload from "../preload/MiniPreload";

class MonthDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = createRef();
    this.state = {
      filterMonth: this.props.default ? [] : ["Tüm Aylar"],
      isOpen: false,
      selectedMonth: null,
      month: null,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getMonth();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      !this.dropdownRef.current ||
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ isOpen: null }); // Dışarı tıklanınca kapat
    }
  };

  getMonth = async () => {
    this.props.actions.getCounters(this.props.token, "month").then((month) => {
      let newArray = [];
      this.setState({ month: month.counter });
      for (let i = 0; i < month.counter; i++) {
        newArray.push(i + 1 !== month.counter ? i + 1 + ". Ay" : "Bu Ay");
      }
      this.setState(
        (prevState) => ({
          filterMonth: [...prevState.filterMonth, ...newArray],
        }),
        () => {
          this.updateSelectedMonth();
        }
      );
    });
  };

  updateSelectedMonth = () => {
    if (this.props.splitMonth && this.props.splitMonth[1]) {
      this.setState({
        selectedMonth: {
          name: this.props.default
            ? this.state.filterMonth[this.props.splitMonth[1] - 1]
            : this.state.filterMonth[this.props.splitMonth[1]],

          i: this.props.splitMonth[1] - 1,
        },
      });
    } else {
      this.setState({
        selectedMonth: {
          name: this.props.default
            ? this.state.filterMonth[this.state.month - 1]
            : this.state.filterMonth[this.props.splitMonth[1]],
          i: this.props.default ? this.state.month : this.props.splitMonth[1],
        },
      });
    }
  };

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option, i) => {
    this.setState(
      {
        isOpen: false,
        selectedMonth: { name: option, i },
      },
      () => {
        const href = "month=" + this.state.selectedMonth.i;

        if (window.location.search.includes("month=")) {
          let newSearch;
          if (
            (!this.props.default && this.state.selectedMonth.i === 0) ||
            (this.props.default &&
              this.state.selectedMonth.i === this.state.month)
          ) {
            newSearch = window.location.search.replace(/&month=[^&]*/, "");
          } else {
            newSearch = window.location.search.replace(/month=[^&]*/, href);
          }
          window.location.search = newSearch;
        } else {
          if (
            (!this.props.default && this.state.selectedMonth.i !== 0) ||
            (this.props.default &&
              this.state.selectedMonth.i !== this.state.month)
          )
            window.location.search += `&${href}`;
        }
      }
    );
  };

  render() {
    const { filterMonth } = this.state;

    return filterMonth && filterMonth.length > 0 ? (
      <div className="week">
        <p className="b_1 non_select">Ay</p>
        <div className="month-dropdown" ref={this.dropdownRef}>
          <div
            className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
            onClick={(e) => this.toggleDropdown(e)}
          >
            <div className="content-drop">
              <div className="txt">
                <p className="b_3 --m non_select">
                  {this.state.selectedMonth !== null
                    ? this.state.selectedMonth.name
                    : null}
                </p>
              </div>
              <figure className={`arrow`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" />
                </svg>
              </figure>
            </div>
          </div>
          {this.state.isOpen && (
            <ul className="options hide-scroll">
              {/* {filterMonth.map(
                (w, i) =>
                  !this.props.default &&
                  i === 0 && (
                    <li
                      className="option"
                      key={i}
                      onClick={() => this.handleOptionClick(w, i)}
                    >
                      <div className="txt">
                        <p className="b_3 non_select">{w}</p>
                      </div>
                    </li>
                  )
              )} */}
              <span
                style={{ display: "flex", flexDirection: "column-reverse" }}
              >
                {filterMonth.map((w, i) =>
                  !this.props.default ? (
                    i !== 0 && (
                      <li
                        className="option"
                        key={i}
                        onClick={() => this.handleOptionClick(w, i)}
                      >
                        <div className="txt">
                          <p className="b_3 non_select">{w}</p>
                        </div>
                      </li>
                    )
                  ) : (
                    <li
                      className="option"
                      key={i}
                      onClick={() => this.handleOptionClick(w, i + 1)}
                    >
                      <div className="txt">
                        <p className="b_3 non_select">{w}</p>
                      </div>
                    </li>
                  )
                )}
              </span>
            </ul>
          )}
        </div>
      </div>
    ) : (
      <MiniPreload />
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.registerorLoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCounters: bindActionCreators(userActions.counters, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthDropdown);
