import $ from "jquery";
import { Navigate } from "react-router-dom";

$(window).on("resize", function () {
  // registerOverflow();
});

$(document).ready(function () {
  var bgPos = $("body").css("background-position").split(", ");

  var bgPos1 = bgPos[0].split(" ");
  var bgPos2 = bgPos[1].split(" ");

  var x1 = parseFloat(bgPos1[0]);
  var y1 = parseFloat(bgPos1[1]);
  var x2 = parseFloat(bgPos2[0]);
  var y2 = parseFloat(bgPos2[1]);

  $(window).on("scroll", function () {
    var scrollPos = $(window).scrollTop();

    $("body").css({
      "background-position": `${x1 - scrollPos / 10}px ${
        y1 + scrollPos / 10
      }px, ${x2 + scrollPos / 10}px ${y2 + scrollPos / 10}px`,
    });
  });
});

export function matchFormatDate(input) {
  const date = new Date(input);

  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${hours}:${minutes}`;
}

export function formatDate(input) {
  const date = new Date(input);

  const day = String(date.getDate()).padStart(2, "0"); // Günü al ve 2 basamaklı yap
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Ayı al ve 2 basamaklı yap
  const year = date.getFullYear(); // Yılı al

  return `${day}/${month}/${year}`;
}

export function formatLastMatch(match = "NNNNN") {
  let resultArray = match.split("");
  for (let i = resultArray.length; i < 5; i++) {
    resultArray.push("N");
  }

  return resultArray;
}

export function formatMatchSelection(input, home, away) {
  switch (input) {
    case 1:
      return "Ev";
    case 2:
      return "Berabere";
    case 3:
      return "Deplasman";
    case 4:
      if (home === 32) {
        return "Diğer";
      }
      return home + "-" + away;
    default:
      return "-";
  }
}

export function formatPoint(point) {
  const number = parseInt(point, 10);
  if (isNaN(number)) return 0;

  return number.toLocaleString();
}

export function saveScrollPos() {
  window.history.replaceState({ scrollY: window.scrollY }, "");
}

function onScrollEnd() {
  window.history.replaceState({ scrollY: null }, "");
  window.removeEventListener("scroll", onScrollEnd);
}

export function loadScrollPos() {
  window.addEventListener("scroll", onScrollEnd);
}

export function dayCalculate(day) {
  const registrationDate = new Date(day);
  const now = new Date();

  // İki tarih arasındaki fark milisaniye cinsinden
  const diffInMs = now - registrationDate;

  // Milisaniyeyi gün'e çevirmek (1 gün = 24 * 60 * 60 * 1000 milisaniye)
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  return diffInDays;
}

function remainintTimeParse(dateStr) {
  const [day, month, year] = dateStr.split(".");
  return new Date(year, month - 1, day); // JavaScript'te ay 0'dan başladığı için
}

export function remainingTimeFormat(seasonEnd) {
  //const seasonStartDate = remainintTimeParse(seasonStart);
  const now = new Date();
  const seasonEndDate = remainintTimeParse(seasonEnd);

  // İki tarih arasındaki farkı milisaniye cinsinden hesaplıyoruz
  //const diffMs = seasonEndDate - seasonStartDate;
  const diffMs = seasonEndDate - now;
  // Milisaniyeyi gün ve saate çevirme
  const msInDay = 1000 * 60 * 60 * 24;
  const msInHour = 1000 * 60 * 60;

  const days = Math.floor(diffMs / msInDay);
  const hours = Math.floor((diffMs % msInDay) / msInHour);

  return { days, hours };
}

export function pointHistoryFormatType(id) {
  switch (id) {
    case 1:
      return "Başarım";
    case 2:
      return "Tahmin Paketi";
    default:
      break;
  }
}

// {
//   "bets_correct": 0,
//   "score_bets_made": 0,
//   "score_bets_correct": 0,
//   "draw_bets_made": 0,
//   "draw_bets_correct": 0,
//   "away_bets_made": 0,
//   "away_bets_correct": 0,
//   "home_bets_made": 0,
//   "home_bets_correct": 0,
//   "all_coupon_bets_correct": 0,
//   "correct_bet_streak": 0,
//   "correct_score_bet_streak": 0,
//   "weekly_1st_counter": 1,
//   "monthly_1st_counter": 0,
//   "season_1st_counter": 0,
//   "super_lig_bets_made": 0,
//   "super_lig_bets_correct": 0,
//   "super_lig_weekly_1st_counter": 0,
//   "super_lig_monthly_1st_counter": 0,
//   "premier_league_uk_bets_made": 0,
//   "premier_league_uk_bets_correct": 0,
//   "premier_league_uk_weekly_1st_counter": 0,
//   "premier_league_uk_monthly_1st_counter": 0,
//   "ligue_1_bets_made": 0,
//   "ligue_1_bets_correct": 0,
//   "ligue_1_weekly_1st_counter": 0,
//   "ligue_1_monthly_1st_counter": 0,
//   "bundesliga_bets_made": 0,
//   "bundesliga_bets_correct": 0,
//   "bundesliga_weekly_1st_counter": 0,
//   "bundesliga_monthly_1st_counter": 0,
//   "primeira_liga_bets_made": 0,
//   "primeira_liga_bets_correct": 0,
//   "primeira_liga_weekly_1st_counter": 0,
//   "primeira_liga_monthly_1st_counter": 0,
//   "serie_a_bets_made": 0,
//   "serie_a_bets_correct": 0,
//   "serie_a_weekly_1st_counter": 0,
//   "serie_a_monthly_1st_counter": 0,
//   "la_liga_bets_made": 0,
//   "la_liga_bets_correct": 0,
//   "la_liga_weekly_1st_counter": 0,
//   "la_liga_monthly_1st_counter": 0,
//   "jupiler_pro_league_bets_made": 0,
//   "jupiler_pro_league_bets_correct": 0,
//   "jupiler_pro_league_weekly_1st_counter": 0,
//   "jupiler_pro_league_monthly_1st_counter": 0,
//   "super_league_1_bets_made": 0,
//   "super_league_1_bets_correct": 0,
//   "super_league_1_weekly_1st_counter": 0,
//   "super_league_1_monthly_1st_counter": 0,
//   "premier_league_rus_bets_made": 0,
//   "premier_league_rus_bets_correct": 0,
//   "premier_league_rus_weekly_1st_counter": 0,
//   "premier_league_rus_monthly_1st_counter": 0,
//   "uefa_champions_league_bets_made": 0,
//   "uefa_champions_league_bets_correct": 0,
//   "uefa_champions_league_weekly_1st_counter": 0,
//   "uefa_champions_league_monthly_1st_counter": 0,
//   "uefa_europa_league_bets_made": 0,
//   "uefa_europa_league_bets_correct": 0,
//   "uefa_europa_league_weekly_1st_counter": 0,
//   "uefa_europa_league_monthly_1st_counter": 0,
//   "favourite_team_bets_made": 0,
//   "favourite_team_bets_correct": 0,
//   "previous_login_day": "2025-02-27",
//   "login_streak": 2,
//   "profile_completed": false,
//   "Instagram_followed": false,
//   "Facebook_followed": false,
//   "profile_completed_percentage": 30.0
// }

export function statFormat(value) {
  switch (value[0]) {
    case "total_coupons_made":
      return {
        name: "Yapılan Toplam Paket",
        desc: `${value[1]} Paket`,
      };
    case "bets_made":
      return {
        name: "Yapılan Toplam Tahmin",
        desc: `${value[1]} Tahmin`,
      };
    case "max_weekly_points":
      return {
        name: "Haftalık En Yüksek Puan",
        desc: `${value[1]}`,
      };
    case "max_monthly_points":
      return {
        name: "Aylık En Yüksek Puan",
        desc: `${value[1]}`,
      };
    case "correct_bet_percentage":
      return {
        name: "Doğru Tahmin",
        desc: `%${value[1]}`,
      };
    case "correct_home_bet_percentage":
      return {
        name: "Doğru Ev Tahmin",
        desc: `%${value[1]}`,
      };
    case "correct_draw_percentage":
      return {
        name: "Doğru Berabere Tahmin",
        desc: `%${value[1]}`,
      };
    case "correct_away_percentage":
      return {
        name: "Doğru Deplasman Tahmin",
        desc: `%${value[1]}`,
      };
    case "correct_score_percentage":
      return {
        name: "Doğru Skor Tahmin",
        desc: `%${value[1]}`,
      };

    case "Facebook_followed":
      return {
        name: "Facebook Takibi",
        desc: `${value[1] ? "Takip Ediliyor" : "Takip Edilmiyor"}`,
      };

    default:
      return { name: "", desc: `` };
  }
}

// export function registerOverflow() {
//   if (
//     window.location.pathname.split("/")[1] === "register" ||
//     window.location.pathname.split("/")[1] === "login"
//   ) {
//     const container = document.getElementById("overflow-register");
//     if (container.scrollHeight > container.clientHeight) {
//       $("#overflow-register").css({ justifyContent: "flex-start" });
//     } else {
//       $("#overflow-register").css({ justifyContent: "center" });
//     }
//   }
// }

export function tokenControl(token) {
  if (token !== null && token !== "" && typeof token !== "number") {
    return true;
  } else {
    return false;
  }
}

export function browserControl() {
  if (
    window.chrome !== null &&
    (window.navigator.userAgent.indexOf("OPR") > -1 ||
      window.navigator.userAgent.indexOf("Opera") > -1)
  ) {
    document.documentElement.classList.add("opera");
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    document.documentElement.classList.add("edge");
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    document.documentElement.classList.add("chrome");
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    document.documentElement.classList.add("safari");
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    document.documentElement.classList.add("firefox");
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    document.documentElement.classList.add("explorer");
  } else if (
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime) &&
    navigator.userAgent.indexOf("Edg") !== -1
  ) {
    document.documentElement.classList.add("edge_chromium");
  }
}
