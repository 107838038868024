import React, { Component, createRef } from "react";

export default class StatusDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = createRef();
    this.state = {
      isOpen: false,
      selectedStatus: { name: this.props.status[0], i: 1 },
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    if (window.location.search) {
      //   const split = window.location.search.match(/status=([^&]*)/) || [0, 0];
      this.setState({
        selectedStatus: {
          name: this.props.status[this.props.splitFilter[1] - 1],
          i: this.props.splitFilter[1],
        },
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      !this.dropdownRef.current ||
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ isOpen: null }); // Dışarı tıklanınca kapat
    }
  };

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option, i) => {
    this.setState(
      {
        isOpen: false,
        selectedStatus: { name: option, i },
      },
      () => {
        window.location.search = "status=" + this.state.selectedStatus.i;
      }
    );
  };

  render() {
    const { status } = this.props;

    return (
      <div className="status non_select">
        <p className="b_1">Durum</p>
        <div className="packages-dropdown" ref={this.dropdownRef}>
          <div
            className={`select-trigger ${this.state.isOpen ? "open" : ""}`}
            onClick={(e) => this.toggleDropdown(e)}
          >
            <div className="content-drop">
              <div className="txt">
                <p className="b_3 --m non_select">
                  {this.state.selectedStatus.name}
                </p>
              </div>
              <figure className={`arrow`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 224c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z" />
                </svg>
              </figure>
            </div>
          </div>
          {this.state.isOpen && (
            <ul className="options hide-scroll">
              {status.map((w, i) => (
                <li
                  className="option"
                  key={i}
                  onClick={() => this.handleOptionClick(w, i + 1)}
                >
                  <div className="txt">
                    <p className="b_3 non_select">{w}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}
