import React, { Component } from "react";

export default class PrivacyPolicyEN extends Component {
  render() {
    return (
      <div className="privacy-policy">
        <h2 className="h_2">Privacy Policy</h2>
        <div className="policies">
          <div className="policy">
            <p className="b_1">
              This privacy policy applies to the 1M1 app (hereby referred to as
              "Application") for mobile devices that was created by Azelir Brevo
              Yazılım Teknolojileri Ltd. Şti. (hereby referred to as "Service
              Provider") as an Ad Supported service. This service is intended
              for use "AS IS".
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">Information Collection and Use</h6>
            <h6 className="b_1">
              The Application collects information when you download and use it.
              This information may include information such as
            </h6>
            <div className="desc">
              <p className="b_1">
                <span className="--bb">
                  Your device's Internet Protocol address (e.g. IP address){" "}
                </span>
                {/* Ad, soyad, e-posta adresi, telefon numarası gibi bilgiler. */}
              </p>
              <p className="b_1">
                <span className="--bb">
                  The pages of the Application that you visit, the time and date
                  of your visit, the time spent on those pages{" "}
                </span>
                {/* IP adresi, cihaz bilgileri, tarayıcı türü, çerezler ve kullanım
                alışkanlıkları. */}
              </p>
              <p className="b_1">
                <span className="--bb">The time spent on the Application </span>
                {/* Tahminleriniz, kazandığınız puanlar ve site üzerindeki
                aktiviteleriniz. */}
              </p>
              <p className="b_1">
                <span className="--bb">
                  The operating system you use on your mobile device{" "}
                </span>
                {/* Tahminleriniz, kazandığınız puanlar ve site üzerindeki
                aktiviteleriniz. */}
              </p>
            </div>
          </div>
          <div className="policy half">
            <p className="b_1">
              The Application does not gather precise information about the
              location of your mobile device.
            </p>
            <p className="b_1">
              The Service Provider may use the information you provided to
              contact you from time to time to provide you with important
              information, required notices and marketing promotions.
            </p>
            <p className="b_1">
              For a better experience, while using the Application, the Service
              Provider may require you to provide us with certain personally
              identifiable information, including but not limited to name,
              surname, ID number, gender, username, password, email, phone
              number, date of birth. The information that the Service Provider
              request will be retained by them and used as described in this
              privacy policy.
            </p>
          </div>
          <div className="policy">
            <h6 className="h_8">Third Party Access</h6>
            <p className="b_1">
              Only aggregated, anonymized data is periodically transmitted to
              external services to aid the Service Provider in improving the
              Application and their service. The Service Provider may share your
              information with third parties in the ways that are described in
              this privacy statement.
            </p>
            <p className="b_1">
              Please note that the Application utilizes third-party services
              that have their own Privacy Policy about handling data. Below are
              the links to the Privacy Policy of the third-party service
              providers used by the Application:
            </p>
            <p className="b_1">Google Play Services</p>
            <p className="b_1">AdMob</p>
            <p className="b_1">Google Analytics for Firebase</p>
            <p className="b_1">Facebook</p>
            <p className="b_1">Expo</p>
            <p className="b_1">
              The Service Provider may disclose User Provided and Automatically
              Collected Information:
            </p>
            <p className="b_1">
              as required by law, such as to comply with a subpoena, or similar
              legal process; when they believe in good faith that disclosure is
              necessary to protect their rights, protect your safety or the
              safety of others, investigate fraud, or respond to a government
              request; with their trusted services providers who work on their
              behalf, do not have an independent use of the information we
              disclose to them, and have agreed to adhere to the rules set forth
              in this privacy statement.
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">Opt-Out Rights</h6>
            <h6 className="b_1">
              You can stop all collection of information by the Application
              easily by uninstalling it. You may use the standard uninstall
              processes as may be available as part of your mobile device or via
              the mobile application marketplace or network.
            </h6>
          </div>
          <div className="policy half">
            <h6 className="h_8">Data Retention Policy</h6>
            <p className="b_1">
              The Service Provider will retain User Provided data for as long as
              you use the Application and for a reasonable time thereafter. If
              you'd like them to delete User Provided Data that you have
              provided via the Application, please contact them at
              info@1m1.com.tr and they will respond in a reasonable time.
            </p>
          </div>
          <div className="policy">
            <h6 className="h_8">Children</h6>
            <p className="b_1">
              The Service Provider does not use the Application to knowingly
              solicit data from or market to children under the age of 13.
            </p>
            <p className="b_1">
              The Application does not address anyone under the age of 13. The
              Service Provider does not knowingly collect personally
              identifiable information from children under 13 years of age. In
              the case the Service Provider discover that a child under 13 has
              provided personal information, the Service Provider will
              immediately delete this from their servers. If you are a parent or
              guardian and you are aware that your child has provided us with
              personal information, please contact the Service Provider
              (info@1m1.com.tr) so that they will be able to take the necessary
              actions.
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">Security</h6>
            <h6 className="b_1">
              The Service Provider is concerned about safeguarding the
              confidentiality of your information. The Service Provider provides
              physical, electronic, and procedural safeguards to protect
              information the Service Provider processes and maintains.
            </h6>
          </div>
          <div className="policy half">
            <h6 className="h_8">Changes</h6>
            <p className="b_1">
              This Privacy Policy may be updated from time to time for any
              reason. The Service Provider will notify you of any changes to the
              Privacy Policy by updating this page with the new Privacy Policy.
              You are advised to consult this Privacy Policy regularly for any
              changes, as continued use is deemed approval of all changes.
            </p>
            <p className="b_1">
              This privacy policy is effective as of 2025-03-26
            </p>
          </div>
          <div className="policy half">
            <h6 className="h_8">Your Consent</h6>
            <h6 className="b_1">
              By using the Application, you are consenting to the processing of
              your information as set forth in this Privacy Policy now and as
              amended by us.
            </h6>
          </div>
          <div className="policy half">
            <h6 className="h_8">Contact Us</h6>
            <p className="b_1">
              If you have any questions regarding privacy while using the
              Application, or have questions about the practices, please contact
              the Service Provider via email at info@1m1.com.tr.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
