import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import initialState from "../../redux/reducers/initialState";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as userActions from "../../redux/actions/userActions";
import * as AppJs from "../../js/app";
import MiniPreload from "../preload/MiniPreload";
import ProgressBar from "../components/ProgressBar";
import {
  URL_LOGIN,
  URL_PREDICTION,
  URL_PROFILE,
  URL_REGISTER,
} from "../../config";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.userInfoRef = createRef();
    this.state = {
      isOpen: false,
      isOpenUserInfo: false,
    };
  }

  componentDidMount() {
    if (AppJs.tokenControl(this.props.token)) {
      this.props.actions.getBasket(this.props.token).then(() => {
        // console.log(this.props.basket.result.basket.length);
      });
      if (this.props.userDetails.length <= 0) {
        this.props.actions.getUserDetails(this.props.token).then(() => {
          // console.log(this.props.userDetails);
        });
      }
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  openUserInfo = () => {
    this.setState({ isOpenUserInfo: true });
  };

  handleClickOutside = (event) => {
    if (
      !this.userInfoRef.current ||
      !this.userInfoRef.current.contains(event.target)
    ) {
      this.setState({ isOpenUserInfo: false }); // Dışarı tıklanınca kapat
    }
  };

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleOptionClick = (option) => {
    // this.props.actions.selectPhoneCode(option);

    this.setState({
      isOpen: false,
    });
  };

  handleLogOut = () => {
    this.props.actions.logout();
  };

  render() {
    return (
      <nav>
        <div className="logo">
          <Link to="/" reloadDocument>
            <figure>
              <img src="/img/1m1.svg" alt="logo" />
            </figure>
          </Link>
        </div>
        <div className="nav-content">
          <div className="pages">
            <Link to="/" className="b_1" reloadDocument>
              Anasayfa
            </Link>
            <Link className="b_1" reloadDocument>
              Nasıl Oynanır?
            </Link>
            {AppJs.tokenControl(this.props.token) ? (
              <Link
                to={URL_PREDICTION}
                className="b_1 prediction_link"
                reloadDocument
              >
                Tahminlerim
                {this.props.basket.status === 200 &&
                this.props.basket.result.basket &&
                this.props.basket.result.basket.length > 0 ? (
                  <span className="b_4">
                    {this.props.basket.result.basket.length}
                  </span>
                ) : (
                  <></>
                )}
              </Link>
            ) : (
              <></>
            )}
          </div>
          {!AppJs.tokenControl(this.props.token) ? (
            <div className="nav-btns">
              <Link
                to={URL_REGISTER}
                className="b_2 background_btn"
                reloadDocument
              >
                Kayıt Ol
              </Link>
              <Link to={URL_LOGIN} className="b_2 outline_btn" reloadDocument>
                Giriş Yap
              </Link>
            </div>
          ) : (
            <></>
          )}

          {AppJs.tokenControl(this.props.token) ? (
            <div className="user">
              <div className="notifications">
                <figure className="non_select--img">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                    <path d="M40.62,28.34l-.87-.7A2,2,0,0,1,39,26.08V18A15,15,0,0,0,26.91,3.29a3,3,0,0,0-5.81,0A15,15,0,0,0,9,18v8.08a2,2,0,0,1-.75,1.56l-.87.7a9,9,0,0,0-3.38,7V37a4,4,0,0,0,4,4h8.26a8,8,0,0,0,15.47,0H40a4,4,0,0,0,4-4V35.36A9,9,0,0,0,40.62,28.34ZM24,43a4,4,0,0,1-3.44-2h6.89A4,4,0,0,1,24,43Zm16-6H8V35.36a5,5,0,0,1,1.88-3.9l.87-.7A6,6,0,0,0,13,26.08V18a11,11,0,0,1,22,0v8.08a6,6,0,0,0,2.25,4.69l.87.7A5,5,0,0,1,40,35.36Z" />
                  </svg>
                </figure>
              </div>
              <div className="profile">
                <div className="point">
                  <p className="b_2 --b non_select">
                    {AppJs.formatPoint(this.props.userDetails.total_points)}/1M
                  </p>
                  <ProgressBar
                    point={this.props.userDetails.total_points}
                    percent={1000000}
                  />
                </div>
                <div className="profile_photo" onClick={this.openUserInfo}>
                  <figure className="non_select--img">
                    <img
                      src={this.props.userDetails.profile_picture}
                      alt="ProfilePicture"
                    />
                  </figure>
                  {this.state.isOpenUserInfo && (
                    <div className="modal" ref={this.userInfoRef}>
                      <Link to={URL_PROFILE} reloadDocument>
                        <figure>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" />
                          </svg>
                        </figure>
                        <p className="b_2">Profil</p>
                      </Link>
                      <Link>
                        <figure>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 190 190"
                          >
                            <path d="M72.1,189.3l-3.8-30.2c-2.7-.9-5.4-2.1-8.2-3.7-2.8-1.6-5.3-3.3-7.5-5.2l-28,11.8L1.8,122.3l24.2-18.3c-.2-1.5-.4-3-.5-4.4,0-1.5-.1-3-.1-4.4s0-2.8.1-4.3c0-1.5.3-3,.5-4.8L1.8,67.7,24.7,28.2l27.9,11.7c2.4-1.9,4.9-3.6,7.7-5.2,2.7-1.6,5.4-2.8,8-3.8l3.9-30.2h45.8l3.8,30.3c3,1.1,5.7,2.3,8.1,3.8,2.4,1.4,4.8,3.1,7.3,5.1l28.3-11.7,22.9,39.5-24.6,18.6c.4,1.6.5,3.1.6,4.5,0,1.4,0,2.8,0,4.2s0,2.7,0,4.1c0,1.4-.3,3-.7,4.8l24.4,18.4-22.9,39.7-27.9-11.9c-2.5,2-5,3.7-7.5,5.2-2.6,1.5-5.2,2.7-7.9,3.7l-3.8,30.3h-45.8ZM95.1,124.8c8.3,0,15.3-2.9,21.1-8.7,5.8-5.8,8.7-12.8,8.7-21.1s-2.9-15.3-8.7-21.1c-5.8-5.8-12.8-8.7-21.1-8.7s-15.4,2.9-21.1,8.7c-5.7,5.8-8.6,12.8-8.6,21.1s2.9,15.3,8.6,21.1c5.7,5.8,12.8,8.7,21.1,8.7Z" />
                          </svg>
                        </figure>
                        <p className="b_2">Ayarlar</p>
                      </Link>
                      <Link onClick={this.handleLogOut}>
                        <figure>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 224c0 17.7 14.3 32 32 32s32-14.3 32-32l0-224zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z" />
                          </svg>
                        </figure>
                        <p className="b_2">Çıkış</p>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </nav>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
      getUserDetails: bindActionCreators(userActions.userDetails, dispatch),
      logout: bindActionCreators(userActions.logout, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    // predictions: state.predictionsReducer,
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
    userDetails: state.getUserDetailsReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
