import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneDropdown from "../dropdowns/PhoneDropdown";
import $ from "jquery";
import { connect } from "react-redux";
import initialState from "../../redux/reducers/initialState";
import { bindActionCreators } from "redux";
import MaskedInput from "react-text-mask";
import * as userActions from "../../redux/actions/userActions";
import alertify from "alertifyjs";
import { URL_LOGIN, URL_PRIVACY_POLICY, URL_VERIFY_CODE } from "../../config";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPhone: true,
      isAuthenticated: false,
      btnLoad: false,
      showPassword: false,
      showPasswordAgain: false,
      form: {
        phone: "",
        last_phone: "",
        mail: "",
        username: "",
        password: "",
        password_again: "",
        use_agreement: false,
        campaign_notice: false,
        recaptcha_token: null,
        fav_team: null,
      },
      isWrong: {
        phone: null,
        mail: null,
        username: null,
        password: null,
        password_again: null,
        use_agreement: null,
        recaptcha: null,
      },
      terms: {
        phone: {
          term1: false,
        },
        mail: {
          term1: false,
        },
        username: {
          term1: false,
          term2: false,
        },
        password: {
          term1: false,
          term2: false,
          term3: false,
        },
        password_again: {
          term1: false,
        },
      },
      focus: {
        phone: false,
        mail: false,
        username: false,
        password: false,
        password_again: false,
      },
    };
  }

  componentDidMount() {
    // document.title = "Bir Milyonda Bir | Kayıt Ol";
    switch (window.location.hash) {
      case "#email":
        this.setState(() => ({
          isPhone: false,
        }));
        break;
      case "#phone":
        this.setState(() => ({
          isPhone: true,
        }));
        break;
      default:
        this.setState(() => ({
          isPhone: true,
        }));
        break;
    }
  }

  canBeUsedControl = async (key, value) => {
    await this.props.actions.canBeUsedControl(key, value);
  };

  changeInput = (e) => {
    this.setState((prevState) => ({
      isAuthenticated: false,
      form: {
        ...prevState.form,
        phone: "",
        last_phone: "",
        mail: "",
      },
      isWrong: {
        ...prevState.isWrong,
        phone: null,
        mail: null,
      },
    }));
    if (!e.currentTarget.classList.contains("active")) {
      this.setState((prevState) => ({
        isPhone: !prevState.isPhone,
      }));
    }
  };

  phoneControl(value) {
    const phone =
      !value.includes("_") && value !== ""
        ? this.props.selectedPhoneCode.dial_code + value
        : null;
    const phone2 = phone !== null ? phone.replace(/[+\s]/g, "") : null;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        last_phone: phone2,
      },
      terms: {
        ...prevState.terms,
        phone: {
          ...prevState.terms.phone,
          term1: phone2 !== null ? true : false,
        },
      },
    }));
  }

  mailControl(value) {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        mail: {
          ...prevState.terms.mail,
          term1: emailRegex ? true : false,
        },
      },
    }));
  }

  usernameControl(value) {
    const startsWithNumber = /^[0-9]/.test(value);

    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        username: {
          ...prevState.terms.username,
          term1: !startsWithNumber && value !== "" ? true : false,
          term2: value.length >= 6 ? true : false,
        },
      },
    }));
  }

  passwordControl(value) {
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[^A-Za-z0-9]/.test(value);
    const hasRepeatedly = /(.)\1/.test(value);

    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        password: {
          ...prevState.terms.password,
          term1: value.length >= 8 ? true : false,
          term2: hasNumber && hasSpecialChar ? true : false,
          term3: !hasRepeatedly && value !== "" ? true : false,
        },
      },
    }));
  }

  passwordAgainControl(value) {
    this.setState((prevState) => ({
      terms: {
        ...prevState.terms,
        password_again: {
          ...prevState.terms.password,
          term1:
            this.state.form.password === value && value !== "" ? true : false,
        },
      },
    }));
  }

  useAgreementControl() {
    this.setState((prevState) => ({
      isWrong: {
        ...prevState.isWrong,
        use_agreement: !this.state.form.use_agreement,
      },
    }));
  }

  handleChange = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.value,
        },
      }),
      () => {
        switch (e.target.name) {
          case "phone":
            this.phoneControl(e.target.value);
            break;
          case "mail":
            this.mailControl(e.target.value);
            break;
          case "username":
            this.usernameControl(e.target.value);
            break;
          case "password":
            this.passwordControl(e.target.value);
            this.passwordAgainControl();
            break;
          case "password_again":
            this.passwordAgainControl(e.target.value);
            break;
          default:
            break;
        }
      }
    );
  };

  handleCheckbox = (e) => {
    this.setState(
      (prevState) => ({
        form: {
          ...prevState.form,
          [e.target.name]: e.target.checked,
        },
      }),
      () => {
        switch (e.target.id) {
          case "use-agreement":
            this.useAgreementControl();
            break;

          default:
            break;
        }
      }
    );
  };

  handleFocus = (e) => {
    switch (e.currentTarget.id) {
      case "mail":
        this.setState({
          focus: {
            mail: true,
          },
        });
        break;
      case "username":
        this.setState({
          focus: {
            username: true,
          },
        });
        break;
      case "password":
        this.setState({
          focus: {
            password: true,
          },
        });
        break;
      case "password-again":
        this.setState({
          focus: {
            password_again: true,
          },
        });
        break;
      default:
        this.setState({
          focus: {
            mail: false,
            username: false,
            password: false,
            password_again: false,
          },
        });
        break;
    }
  };

  handleBlur = (e) => {
    this.setState({
      focus: {
        phone: false,
        mail: false,
        username: false,
        password: false,
        password_again: false,
      },
    });
    switch (e.currentTarget.id) {
      case "phone":
        this.canBeUsedControl("phone_number", this.state.form.last_phone).then(
          () => {
            this.setState((prevState) => ({
              isWrong: {
                ...prevState.isWrong,
                phone:
                  this.state.terms.phone.term1 &&
                  this.props.canBeUse.phone.valid
                    ? false
                    : true,
              },
            }));
          }
        );
        break;
      case "mail":
        this.canBeUsedControl("email", this.state.form.mail).then(() => {
          this.setState((prevState) => ({
            isWrong: {
              ...prevState.isWrong,
              mail:
                this.state.terms.mail.term1 && this.props.canBeUse.email.valid
                  ? false
                  : true,
            },
          }));
        });
        break;
      case "username":
        this.canBeUsedControl("username", this.state.form.username).then(() => {
          this.setState(
            (prevState) => ({
              isWrong: {
                ...prevState.isWrong,
                username:
                  this.state.terms.username.term1 &&
                  this.state.terms.username.term2 &&
                  this.props.canBeUse.username.valid
                    ? false
                    : true,
              },
            }),
            () => {
              // Yeni state güncellendikten sonra çalışacak
            }
          );
        });
        break;
      case "password":
        this.setState(
          (prevState) => ({
            isWrong: {
              ...prevState.isWrong,
              password:
                this.state.terms.password.term1 &&
                this.state.terms.password.term2 &&
                this.state.terms.password.term3
                  ? false
                  : true,
            },
          }),
          () => {
            // Yeni state güncellendikten sonra çalışacak
          }
        );
        break;
      case "password-again":
        this.setState(
          (prevState) => ({
            isWrong: {
              ...prevState.isWrong,
              password_again: this.state.terms.password_again.term1
                ? false
                : true,
            },
          }),
          () => {
            // Yeni state güncellendikten sonra çalışacak
          }
        );
        break;
      default:
        break;
    }
  };

  send = (e) => {
    e.preventDefault();
    this.useAgreementControl();
    window.grecaptcha.reset();
    if (
      ((!this.state.isWrong.phone && this.state.isWrong.phone !== null) ||
        (!this.state.isWrong.mail && this.state.isWrong.mail !== null)) &&
      !this.state.isWrong.username &&
      this.state.isWrong.username !== null &&
      !this.state.isWrong.password &&
      this.state.isWrong.password !== null &&
      !this.state.isWrong.password_again &&
      this.state.isWrong.password_again !== null &&
      !this.state.isWrong.use_agreement &&
      this.state.isWrong.use_agreement !== null &&
      !this.state.isWrong.recaptcha &&
      this.state.isWrong.recaptcha !== null
    ) {
      this.setState({ btnLoad: true });
      if (this.state.form.mail !== "") {
        this.props.actions
          .verifyEmailCode(
            this.state.form.mail,
            this.state.form.recaptcha_token
          )
          .then(() => {
            this.props.actions.sendFormObject(this.state.form);
            this.setState({ isAuthenticated: true, btnLoad: false });
          });
      } else if (this.state.form.last_phone !== "") {
        this.props.actions
          .verifyPhoneCode(
            this.state.form.last_phone,
            this.state.form.recaptcha_token
          )
          .then(() => {
            this.props.actions.sendFormObject(this.state.form);
            this.setState({ isAuthenticated: true, btnLoad: false });
          });
      }
    } else {
      // console.log("hata var");
    }
  };

  render() {
    if (this.state.isAuthenticated) {
      return <Navigate to={URL_VERIFY_CODE} />;
    }

    return (
      <div className="form">
        <div className="third-party-register">
          <Link className="third_party_btns btn_apple">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
            </svg>
            {/* <p className="b_3">Apple ile Kayıt ol</p> */}
          </Link>
          <Link className="third_party_btns btn_google">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 30">
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M15.5 12.2726V18.0817H23.5727C23.2182 19.9499 22.1545 21.5318 20.559 22.5954L25.4272 26.3727C28.2635 23.7546 29.8999 19.9091 29.8999 15.341C29.8999 14.2774 29.8045 13.2545 29.6272 12.2728L15.5 12.2726Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M7.09376 17.8551L5.9958 18.6955L2.10938 21.7228C4.57755 26.6182 9.63627 30 15.4999 30C19.5498 30 22.9452 28.6637 25.427 26.3728L20.5589 22.5955C19.2225 23.4955 17.518 24.041 15.4999 24.041C11.5999 24.041 8.28634 21.4092 7.09989 17.8637L7.09376 17.8551Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M2.10897 8.27728C1.0863 10.2954 0.5 12.5727 0.5 14.9999C0.5 17.4271 1.0863 19.7045 2.10897 21.7226C2.10897 21.7361 7.09997 17.8498 7.09997 17.8498C6.79997 16.9498 6.62265 15.9953 6.62265 14.9998C6.62265 14.0042 6.79997 13.0497 7.09997 12.1497L2.10897 8.27728Z"
              />
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M15.5002 5.97272C17.7093 5.97272 19.6729 6.73634 21.2411 8.20909L25.5365 3.91369C22.9319 1.48646 19.5502 0 15.5002 0C9.63657 0 4.57755 3.36818 2.10938 8.27728L7.10022 12.15C8.28651 8.60452 11.6002 5.97272 15.5002 5.97272Z"
              />
            </svg>
            {/* <p className="b_3">Google ile Kayıt ol</p> */}
          </Link>
          <Link className="third_party_btns btn_facebook">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
            </svg>
            {/* <p className="b_3">Facebook ile Kayıt ol</p> */}
          </Link>
        </div>
        <div className="divider">
          <hr />
          <p className="b_2">E-posta ile kayıt olun</p>
          <hr />
        </div>
        <form onSubmit={this.send}>
          <div className="mail-username">
            <div className="mail-phone">
              <div className="links">
                <Link
                  to="#phone"
                  className={"b_3 " + (this.state.isPhone ? "active" : "")}
                  onClick={this.changeInput}
                >
                  Telefon
                </Link>
                <Link
                  to="#email"
                  className={"b_3 " + (this.state.isPhone ? "" : "active")}
                  onClick={this.changeInput}
                >
                  E-posta
                </Link>
              </div>
              <div
                className="phone"
                style={
                  this.state.isPhone
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div
                  className={
                    "phone-input " +
                    (this.state.form.phone !== ""
                      ? !this.state.isWrong.phone
                        ? "correct"
                        : "wrong"
                      : "")
                  }
                >
                  <PhoneDropdown />
                  <MaskedInput
                    mask={[
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                    ]}
                    name="phone"
                    id="phone"
                    className={"b_2 "}
                    placeholder="555 333 44 44"
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    value={this.state.form.phone}
                    onChange={this.handleChange}
                    autoComplete="false"
                  />
                  <div
                    className={
                      "terms non_select " +
                      // (!this.props.canBeUse.phone &&
                      // this.props.canBeUse.phone !== null
                      //   ? "active"
                      //   : "")
                      (this.state.form.phone !== ""
                        ? this.props.canBeUse.phone &&
                          this.props.canBeUse.phone.valid !== 200
                          ? "active"
                          : ""
                        : "")
                    }
                  >
                    <div className="term">
                      <p className={"b_4 wrong"}>
                        {this.props.canBeUse.phone
                          ? this.props.canBeUse.phone.message
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mail"
                style={
                  this.state.isPhone
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                <input
                  type="email"
                  id="mail"
                  name="mail"
                  className={
                    "b_2 input " +
                    (this.state.form.mail !== ""
                      ? !this.state.isWrong.mail
                        ? "correct"
                        : "wrong"
                      : "")
                  }
                  placeholder="********@gmail.com"
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  value={this.state.form.mail}
                  onChange={this.handleChange}
                  autoComplete="true"
                />
                <div
                  className={
                    "terms non_select " +
                    (this.props.canBeUse.email &&
                    this.props.canBeUse.email.valid !== 200
                      ? "active"
                      : "")
                  }
                >
                  <div className="term">
                    <p className={"b_4 wrong"}>
                      {this.props.canBeUse.email
                        ? this.props.canBeUse.email.message
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="username">
              <div className="label">
                <label htmlFor="username" className="b_2">
                  Kullanıcı Adı
                </label>
                <div className="info non_select">
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 128 512"
                    >
                      <path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" />
                    </svg>
                  </figure>

                  <div className="modal">
                    <p className="b_4">Bu Alan Daha Sonra Değiştirilemez</p>
                  </div>
                </div>
              </div>
              <input
                type="text"
                id="username"
                maxLength="16"
                className={
                  "b_2 input " +
                  (this.state.form.username !== ""
                    ? !this.state.isWrong.username
                      ? "correct"
                      : "wrong"
                    : "")
                }
                placeholder="user1234"
                name="username"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={this.state.form.username}
                onChange={(e) => {
                  if (
                    e.target.value.length <= 16 &&
                    !e.target.value.includes(" ")
                  ) {
                    this.handleChange(e);
                  }
                }}
                autoComplete="true"
              />
              <div
                className={
                  "terms non_select " +
                  (this.state.focus.username && !this.props.canBeUse.username
                    ? "active"
                    : this.state.focus.username &&
                      this.props.canBeUse.username.valid === true
                    ? "active"
                    : "")
                }
              >
                <div className="term">
                  <figure>
                    {this.state.terms.username.term1 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b_4 " +
                      (this.state.terms.username.term1 ? "correct" : "wrong")
                    }
                  >
                    Sayı İle Başlayamaz.
                  </p>
                </div>
                <div className="term">
                  <figure>
                    {this.state.terms.username.term2 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b_4 " +
                      (this.state.terms.username.term2 ? "correct" : "wrong")
                    }
                  >
                    En Az 6 Karakterden Oluşmalıdır
                  </p>
                </div>
              </div>
              <div
                className={
                  "terms non_select " +
                  //   !this.props.canBeUse.username &&
                  // this.props.canBeUse.username !== null
                  (this.state.isWrong.username
                    ? //   &&
                      // !this.state.terms.username.term1 &&
                      // !this.state.terms.username.term2
                      "active"
                    : "")
                }
              >
                <div className="term">
                  <p className={"b_4 wrong"}>
                    {this.props.canBeUse.username
                      ? this.props.canBeUse.username.message
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="passwords">
            <div className="password">
              <label htmlFor="password" className="b_2">
                Şifre
              </label>
              <div className="show_pass">
                <input
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  className={
                    "b_2 input " +
                    (this.state.terms.password.term1 &&
                    this.state.terms.password.term2 &&
                    this.state.terms.password.term3
                      ? "correct"
                      : this.state.form.password !== ""
                      ? "wrong"
                      : "")
                  }
                  placeholder="********"
                  name="password"
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  value={this.state.form.password}
                  onChange={this.handleChange}
                  autoComplete="false"
                />
                <figure
                  className="non_select"
                  onClick={() =>
                    this.setState((prevState) => ({
                      showPassword: !prevState.showPassword,
                    }))
                  }
                >
                  {!this.state.showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 210 140"
                    >
                      <path d="M105,110.6c11.3,0,20.9-4,28.8-11.9,7.9-7.9,11.8-17.5,11.8-28.8s-4-20.9-11.9-28.8c-7.9-7.9-17.5-11.8-28.8-11.8s-20.9,4-28.8,11.9c-7.9,7.9-11.8,17.5-11.8,28.8s4,20.9,11.9,28.8c7.9,7.9,17.5,11.8,28.8,11.8ZM105,96.9c-7.5,0-13.8-2.6-19.1-7.8s-7.8-11.6-7.8-19.1,2.6-13.8,7.8-19.1,11.6-7.8,19.1-7.8,13.8,2.6,19.1,7.8,7.8,11.6,7.8,19.1-2.6,13.8-7.8,19.1-11.6,7.8-19.1,7.8ZM105,139.8c-22.9,0-43.8-6.3-62.7-19C23.5,108.2,9.6,91.2.7,70,9.6,48.8,23.5,31.8,42.3,19.2,61.2,6.5,82.1.2,105,.2s43.8,6.3,62.7,19c18.8,12.6,32.7,29.6,41.6,50.8-8.9,21.2-22.8,38.2-41.6,50.8-18.8,12.6-39.7,19-62.6,19ZM105,124.8c18.8,0,36-4.9,51.7-14.8,15.7-9.9,27.7-23.2,36-40-8.3-16.8-20.3-30.1-36-40-15.7-9.9-32.9-14.8-51.7-14.8s-36,4.9-51.7,14.8c-15.7,9.9-27.7,23.2-36,40,8.3,16.8,20.3,30.1,36,40,15.7,9.9,32.9,14.8,51.7,14.8Z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 210 190"
                    >
                      <path d="M142.8,99.5l-11.3-11.3c1.5-8.3-.9-15.8-7.1-22.4-6.2-6.6-13.9-9.2-23-7.7l-11.3-11.3c2.3-1,4.6-1.8,7-2.3,2.4-.5,5-.8,7.8-.8,11.4,0,21,4,28.9,11.9,7.9,7.9,11.9,17.6,11.9,28.9s-.3,5.5-.8,7.9c-.5,2.4-1.3,4.7-2.3,6.9ZM174.7,130.6l-11.1-10.4c6.3-4.8,12-10.1,16.9-15.9,4.9-5.8,9.1-12.3,12.6-19.7-8.3-16.9-20.3-30.3-35.9-40.2-15.6-9.9-33-14.9-52.2-14.9s-9.6.3-14.3,1c-4.7.7-9.3,1.7-13.8,3l-11.7-11.7c6.3-2.5,12.8-4.4,19.4-5.6,6.6-1.2,13.4-1.8,20.3-1.8,23.5,0,44.7,6.5,63.5,19.4,18.9,12.9,32.6,29.9,41.3,50.7-3.7,9-8.5,17.3-14.4,25.1-5.9,7.8-12.8,14.7-20.8,20.9ZM182.8,188.6l-40.5-40.2c-5.1,1.9-10.8,3.5-17.1,4.7-6.2,1.2-13,1.8-20.1,1.8-23.5,0-44.7-6.5-63.5-19.4C22.7,122.5,8.9,105.6.2,84.7c3.7-8.8,8.5-17.1,14.3-24.8,5.9-7.7,12.3-14.3,19.3-19.9L6.1,12,16.7,1.4l176.6,176.6s-10.6,10.6-10.6,10.6ZM44.4,50.6c-5.3,4.2-10.4,9.3-15.4,15.2-5,5.9-9.1,12.2-12.1,18.9,8.3,16.9,20.3,30.3,35.9,40.2,15.6,9.9,33,14.9,52.2,14.9s9.1-.4,13.6-1.2c4.5-.8,8.4-1.6,11.5-2.4l-12.7-13c-1.7.7-3.7,1.2-5.9,1.7-2.2.4-4.4.6-6.5.6-11.4,0-21-4-28.9-11.9-7.9-7.9-11.9-17.6-11.9-28.9s.2-4.2.6-6.4c.4-2.2,1-4.2,1.7-6.1,0,0-22-21.7-22-21.7Z" />
                    </svg>
                  )}
                </figure>
              </div>
              <div
                className={
                  "terms non_select " +
                  (this.state.focus.password ? "active" : "")
                }
              >
                <div className="term">
                  <figure>
                    {this.state.terms.password.term1 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b_4 " +
                      (this.state.terms.password.term1 ? "correct" : "wrong")
                    }
                  >
                    En Az 8 Karakter Uzunluğunda Olmalı.
                  </p>
                </div>
                <div className="term">
                  <figure>
                    {this.state.terms.password.term2 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b_4 " +
                      (this.state.terms.password.term2 ? "correct" : "wrong")
                    }
                  >
                    Sayı ve Özel Karakter Ekleyin.
                  </p>
                </div>
                <div className="term">
                  <figure>
                    {this.state.terms.password.term3 ? (
                      <svg
                        className="correct"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    ) : (
                      <svg
                        className="xmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                      </svg>
                    )}
                  </figure>
                  <p
                    className={
                      "b_4 " +
                      (this.state.terms.password.term3 ? "correct" : "wrong")
                    }
                  >
                    Aynı Karakterleri Art Arda Kullanmayın.
                  </p>
                </div>
              </div>
            </div>
            <div className="password-again">
              <label htmlFor="password-again" className="b_2">
                Tekrar Şifre
              </label>
              <div className="show_pass">
                <input
                  type={this.state.showPasswordAgain ? "text" : "password"}
                  id="password-again"
                  className={
                    "b_2 input " +
                    (this.state.terms.password_again.term1
                      ? "correct"
                      : this.state.form.password_again !== ""
                      ? "wrong"
                      : "")
                  }
                  placeholder="********"
                  name="password_again"
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  value={this.state.form.password_again}
                  onChange={this.handleChange}
                  autoComplete="false"
                />
                <figure
                  className="non_select"
                  onClick={() =>
                    this.setState((prevState) => ({
                      showPasswordAgain: !prevState.showPasswordAgain,
                    }))
                  }
                >
                  {!this.state.showPasswordAgain ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 210 140"
                    >
                      <path d="M105,110.6c11.3,0,20.9-4,28.8-11.9,7.9-7.9,11.8-17.5,11.8-28.8s-4-20.9-11.9-28.8c-7.9-7.9-17.5-11.8-28.8-11.8s-20.9,4-28.8,11.9c-7.9,7.9-11.8,17.5-11.8,28.8s4,20.9,11.9,28.8c7.9,7.9,17.5,11.8,28.8,11.8ZM105,96.9c-7.5,0-13.8-2.6-19.1-7.8s-7.8-11.6-7.8-19.1,2.6-13.8,7.8-19.1,11.6-7.8,19.1-7.8,13.8,2.6,19.1,7.8,7.8,11.6,7.8,19.1-2.6,13.8-7.8,19.1-11.6,7.8-19.1,7.8ZM105,139.8c-22.9,0-43.8-6.3-62.7-19C23.5,108.2,9.6,91.2.7,70,9.6,48.8,23.5,31.8,42.3,19.2,61.2,6.5,82.1.2,105,.2s43.8,6.3,62.7,19c18.8,12.6,32.7,29.6,41.6,50.8-8.9,21.2-22.8,38.2-41.6,50.8-18.8,12.6-39.7,19-62.6,19ZM105,124.8c18.8,0,36-4.9,51.7-14.8,15.7-9.9,27.7-23.2,36-40-8.3-16.8-20.3-30.1-36-40-15.7-9.9-32.9-14.8-51.7-14.8s-36,4.9-51.7,14.8c-15.7,9.9-27.7,23.2-36,40,8.3,16.8,20.3,30.1,36,40,15.7,9.9,32.9,14.8,51.7,14.8Z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 210 190"
                    >
                      <path d="M142.8,99.5l-11.3-11.3c1.5-8.3-.9-15.8-7.1-22.4-6.2-6.6-13.9-9.2-23-7.7l-11.3-11.3c2.3-1,4.6-1.8,7-2.3,2.4-.5,5-.8,7.8-.8,11.4,0,21,4,28.9,11.9,7.9,7.9,11.9,17.6,11.9,28.9s-.3,5.5-.8,7.9c-.5,2.4-1.3,4.7-2.3,6.9ZM174.7,130.6l-11.1-10.4c6.3-4.8,12-10.1,16.9-15.9,4.9-5.8,9.1-12.3,12.6-19.7-8.3-16.9-20.3-30.3-35.9-40.2-15.6-9.9-33-14.9-52.2-14.9s-9.6.3-14.3,1c-4.7.7-9.3,1.7-13.8,3l-11.7-11.7c6.3-2.5,12.8-4.4,19.4-5.6,6.6-1.2,13.4-1.8,20.3-1.8,23.5,0,44.7,6.5,63.5,19.4,18.9,12.9,32.6,29.9,41.3,50.7-3.7,9-8.5,17.3-14.4,25.1-5.9,7.8-12.8,14.7-20.8,20.9ZM182.8,188.6l-40.5-40.2c-5.1,1.9-10.8,3.5-17.1,4.7-6.2,1.2-13,1.8-20.1,1.8-23.5,0-44.7-6.5-63.5-19.4C22.7,122.5,8.9,105.6.2,84.7c3.7-8.8,8.5-17.1,14.3-24.8,5.9-7.7,12.3-14.3,19.3-19.9L6.1,12,16.7,1.4l176.6,176.6s-10.6,10.6-10.6,10.6ZM44.4,50.6c-5.3,4.2-10.4,9.3-15.4,15.2-5,5.9-9.1,12.2-12.1,18.9,8.3,16.9,20.3,30.3,35.9,40.2,15.6,9.9,33,14.9,52.2,14.9s9.1-.4,13.6-1.2c4.5-.8,8.4-1.6,11.5-2.4l-12.7-13c-1.7.7-3.7,1.2-5.9,1.7-2.2.4-4.4.6-6.5.6-11.4,0-21-4-28.9-11.9-7.9-7.9-11.9-17.6-11.9-28.9s.2-4.2.6-6.4c.4-2.2,1-4.2,1.7-6.1,0,0-22-21.7-22-21.7Z" />
                    </svg>
                  )}
                </figure>
              </div>
              <div
                className={
                  "terms non_select " +
                  (this.state.focus.password_again &&
                  this.state.form.password_again !== ""
                    ? "active"
                    : "")
                }
              >
                <div className="term">
                  <p
                    className={"b_4 wrong"}
                    style={
                      this.state.terms.password_again.term1
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    Girdiğiniz Şifreler Eşleşmiyor
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              "recaptcha "
              // +
              // (((!this.state.isWrong.phone &&
              //   this.state.isWrong.phone !== null) ||
              //   (!this.state.isWrong.mail &&
              //     this.state.isWrong.mail !== null)) &&
              // !this.state.isWrong.username &&
              // this.state.isWrong.username !== null &&
              // !this.state.isWrong.password &&
              // this.state.isWrong.password !== null &&
              // !this.state.isWrong.password_again &&
              // this.state.isWrong.password_again !== null
              //   ? "active "
              //   : "") +
              // (this.state.isWrong.recaptcha ||
              // this.state.isWrong.recaptcha === null
              //   ? "wrong"
              //   : "")
            }
          >
            <ReCAPTCHA
              sitekey="6LckKIEqAAAAAJHKnh4otAd_B0XtexJbNAklNAi1"
              onChange={(val) => {
                this.setState((prevState) => ({
                  form: {
                    ...prevState.form,
                    recaptcha_token: val,
                  },
                  isWrong: {
                    ...prevState.isWrong,
                    recaptcha: !val,
                  },
                }));
              }}
            />
            <p className="b_3 non_select">
              Lütfen robot olmadığınızı doğrulayın.
            </p>
          </div>

          <div className="agreements">
            <div className="agreement">
              <label
                htmlFor="use-agreement"
                className={
                  "custom-checkbox b_2 non_select " +
                  (this.state.isWrong.use_agreement ? "wrong" : "correct")
                }
              >
                <Link to={URL_PRIVACY_POLICY} target="_blank">
                  Kullanım Sözleşmesini Kabul Ediyorum.
                </Link>
                <input
                  type="checkbox"
                  name="use_agreement"
                  id="use-agreement"
                  value={this.state.form.use_agreement}
                  onChange={this.handleCheckbox}
                />
                <span className="checkmark">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                </span>
              </label>
            </div>
            <div className="agreement">
              <label
                htmlFor="campaign-notice"
                className="custom-checkbox b_2 non_select"
              >
                Özel fırsatlar, kampanyalar ve duyurular hakkında bilgilendirme
                almak istiyorum.
                <input
                  type="checkbox"
                  name="campaign_notice"
                  id="campaign-notice"
                  value={this.state.form.campaign_notice}
                  onChange={this.handleCheckbox}
                />
                <span className="checkmark">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                </span>
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="background_btn"
            disabled={this.state.btnLoad ? true : false}
            data-loader={this.state.btnLoad ? true : false}
          >
            <span className="btn-loader"></span>
            <h4 className="b_1">Kayıt Ol</h4>
          </button>
          <Link to={URL_LOGIN} className="redirect b_1" reloadDocument>
            Hesabın Varsa Giriş Yap
          </Link>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      verifyEmailCode: bindActionCreators(
        userActions.verifyEmailCode,
        dispatch
      ),
      verifyPhoneCode: bindActionCreators(
        userActions.verifyPhoneCode,
        dispatch
      ),
      sendFormObject: bindActionCreators(userActions.sendFormObject, dispatch),
      canBeUsedControl: (key, value) =>
        dispatch(userActions.canBeUsedControl(key, value)),
    },
  };
}

function mapStateToProps(state) {
  return {
    canBeUse: state.canBeUsedReducer,
    selectedPhoneCode: state.selectPhoneCodeReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
