import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import * as AppJs from "../../../js/app";

export default class LeaderboardCard extends Component {
  constructor(props) {
    super(props);
    this.fadeRef = createRef();
    this.state = {
      fade: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (!this.fadeRef.current || !this.fadeRef.current.contains(event.target)) {
      this.setState({ fade: null }); // Dışarı tıklanınca kapat
    }
  };

  toggleFlip = () => {
    this.setState((prevState) => ({ fade: !prevState.fade }));
  };

  render() {
    const { user, openModal, myDetail, total } = this.props;

    return (
      <div
        className={`leaderboard_card ${
          myDetail && myDetail.id === user.id ? "my" : ""
        }`}
        ref={this.fadeRef}
      >
        <div className="leaderboard_card_inner">
          <div
            className={`leaderboard_card_front ${
              this.state.fade ? "hidden" : "visible"
            }`}
          >
            <div className="left" onClick={this.toggleFlip}>
              <div className="queue">
                <p className="b_1 --b">
                  #
                  {user.position && user.position.toString().padEnd(total, " ")}
                </p>
              </div>
              <figure>
                <img src={user.profile_picture} alt="User Profile" />
              </figure>
              <p className="name b_1">{user.username}</p>
            </div>
            <div className="right">
              <p className="point b_1" onClick={this.toggleFlip}>
                <span>{AppJs.formatPoint(user.points)}</span>Puan
              </p>
              <div className="report">
                <figure>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512">
                    <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                  </svg>
                </figure>
                <div className="content">
                  <Link className="b_1">Profile Git</Link>
                  <Link className="b_1" onClick={openModal}>
                    Şikayet Et
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`leaderboard_card_back ${
              this.state.fade ? "visible" : "hidden"
            }`}
            onClick={this.toggleFlip}
          >
            <div className="percents">
              <p className="b_3 --m">
                Ev:
                <span className="--bb">{`%${user.correct_home_bet_percentage}`}</span>
              </p>
              <p className="b_3 --m">
                Skor:
                <span className="--bb">{`%${user.correct_score_bet_percentage}`}</span>
              </p>
            </div>
            <div className="percents">
              <p className="b_3 --m">
                Berabere:
                <span className="--bb">{`%${user.correct_draw_bet_percentage}`}</span>
              </p>
              <p className="b_3 --m">
                Paket:
                <span className="--bb">{`%${user.correct_bet_percentage}`}</span>
              </p>
            </div>
            <div className="percents">
              <p className="b_3 --m">
                Deplasman:
                <span className="--bb">{`%${user.correct_away_bet_percentage}`}</span>
              </p>
              {/* <p className="b_4 --m">
                Paket:
                <span className="--bb">{`%${user.correct_bet_percentage}`}</span>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
