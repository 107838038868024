import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MiniPreload from "../preload/MiniPreload";
import * as appJs from "../../js/app";
import StatCard from "../components/stat/StatCard";
import AchievementCard from "../components/achievements/AchievementCard";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";

class Profile extends Component {
  componentDidMount() {
    this.props.actions.getStats(this.props.token).then(() => {
      // console.log(this.props.stats);
    });
    this.props.actions.getAchievements(this.props.token).then(() => {
      // console.log(this.props.achievements);
    });
    this.props.actions.getPointHistory(this.props.token, 1).then(() => {
      // console.log(this.props.pointHistory);
    });
  }

  render() {
    return (
      <div className="profile_page">
        {this.props.userDetails ? (
          <>
            <div className="profile">
              <div className="left">
                <figure className="logo">
                  <img
                    src={this.props.userDetails.profile_picture}
                    alt="Profile"
                  />
                </figure>
                <div className="txt">
                  <div className="name_info">
                    <h6 className="h_6">{this.props.userDetails.username}</h6>
                    <p className="b_3">{`${
                      this.props.userDetails.fav_team
                        ? this.props.userDetails.fav_team
                        : "1M1"
                    } Fanı`}</p>
                  </div>
                  <div className="when_play_league">
                    <div className="when_play">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 180 180"
                        >
                          <path d="M90,179.8c-22.9,0-42.9-7.6-60-22.8C13,141.7,3.2,122.7.7,100h20.4c2.3,17.3,10,31.6,23.1,42.9,13,11.3,28.3,17,45.7,17s35.9-6.8,49.5-20.3c13.5-13.5,20.3-30,20.3-49.5s-6.8-35.9-20.3-49.5c-13.5-13.5-30-20.3-49.5-20.3s-22.2,2.7-32.2,8c-10,5.3-18.4,12.6-25.2,21.9h27.4v19.9H.2V10.2h19.9v23.4c8.5-10.6,18.8-18.9,31-24.7C63.4,3.2,76.4.2,90,.2s24.1,2.4,35,7.1,20.4,11.1,28.4,19.2c8.1,8.1,14.5,17.5,19.2,28.4,4.7,10.9,7.1,22.6,7.1,35s-2.4,24.1-7.1,35c-4.7,10.9-11.1,20.4-19.2,28.4-8.1,8.1-17.5,14.5-28.4,19.2-10.9,4.7-22.6,7.1-35,7.1ZM117.9,131.9l-37.9-37.9v-53.9h19.9v45.9l31.9,31.9-14,14Z" />
                        </svg>
                      </figure>
                      <p className="b_3 --m">
                        {`${appJs.dayCalculate(
                          this.props.userDetails.created_at
                        )} Gündür Oynuyor`}
                      </p>
                    </div>
                    <div className="user_league">
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 360 320"
                        >
                          <path d="M89.6,312.2c-24.7,0-45.7-8.7-63.2-26.2C8.9,268.5.2,247.4.2,222.7s8.5-45.1,25.5-62.5c17-17.4,37.6-26.3,61.9-26.8,2.6,0,5.1.2,7.7.6,2.6.4,5.1.8,7.7,1.3L37.1,3.2h95.3l47.6,95.3L227.6,3.2h95.3l-65.3,130.8c2.3-.5,4.7-.9,7.1-1.2,2.4-.3,4.9-.4,7.5-.4,24.5.6,45.2,9.6,62.1,27,17,17.4,25.5,38.3,25.5,62.8s-8.7,46.2-26.2,63.7c-17.5,17.5-38.7,26.2-63.7,26.2s-7.6-.2-11.5-.6c-3.9-.4-7.6-1.2-11.2-2.4,15.3-7.5,26.2-19.4,32.8-35.8,6.6-16.4,9.9-33.4,9.9-51.1,0-30.7-10.6-56.6-31.9-77.9-21.3-21.3-47.3-31.9-77.9-31.9s-56.6,10.6-77.9,31.9c-21.3,21.3-31.9,47.3-31.9,77.9s3.1,35.5,9.2,52.1c6.1,16.6,17.3,28.2,33.4,34.7-3.8,1.2-7.6,2-11.4,2.4-3.8.4-7.7.6-11.7.6ZM180.2,316.8c-26.2,0-48.5-9.2-67-27.5-18.5-18.3-27.7-40.6-27.7-66.8s9.2-48.5,27.5-67c18.3-18.5,40.6-27.7,66.8-27.7s48.5,9.2,67,27.5c18.5,18.3,27.7,40.6,27.7,66.8s-9.2,48.5-27.5,67c-18.3,18.5-40.6,27.7-66.8,27.7ZM148.8,268l31.2-23.7,31.2,23.7-11.7-38.5,31.2-22.2h-38.5l-12.2-40.7-12.2,40.7h-38.5l31.2,22.2s-11.7,38.5-11.7,38.5Z" />
                        </svg>
                      </figure>
                      <p className="b_3 --m">
                        {this.props.userDetails.user_league}
                      </p>
                    </div>
                  </div>
                  <div className="desc">
                    <p className="b_3">
                      {this.props.userDetails.bio === ""
                        ? "Bio bulunmamaktadır"
                        : this.props.userDetails.bio}
                    </p>
                  </div>
                </div>
              </div>
              <div className="right">
                <Link>
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 190 190"
                    >
                      <path d="M72.1,189.3l-3.8-30.2c-2.7-.9-5.4-2.1-8.2-3.7-2.8-1.6-5.3-3.3-7.5-5.2l-28,11.8L1.8,122.3l24.2-18.3c-.2-1.5-.4-3-.5-4.4,0-1.5-.1-3-.1-4.4s0-2.8.1-4.3c0-1.5.3-3,.5-4.8L1.8,67.7,24.7,28.2l27.9,11.7c2.4-1.9,4.9-3.6,7.7-5.2,2.7-1.6,5.4-2.8,8-3.8l3.9-30.2h45.8l3.8,30.3c3,1.1,5.7,2.3,8.1,3.8,2.4,1.4,4.8,3.1,7.3,5.1l28.3-11.7,22.9,39.5-24.6,18.6c.4,1.6.5,3.1.6,4.5,0,1.4,0,2.8,0,4.2s0,2.7,0,4.1c0,1.4-.3,3-.7,4.8l24.4,18.4-22.9,39.7-27.9-11.9c-2.5,2-5,3.7-7.5,5.2-2.6,1.5-5.2,2.7-7.9,3.7l-3.8,30.3h-45.8ZM95.1,124.8c8.3,0,15.3-2.9,21.1-8.7,5.8-5.8,8.7-12.8,8.7-21.1s-2.9-15.3-8.7-21.1c-5.8-5.8-12.8-8.7-21.1-8.7s-15.4,2.9-21.1,8.7c-5.7,5.8-8.6,12.8-8.6,21.1s2.9,15.3,8.6,21.1c5.7,5.8,12.8,8.7,21.1,8.7Z" />
                    </svg>
                  </figure>
                  <p className="b_3 --m">Ayarlar</p>
                </Link>
              </div>
            </div>
            <div className="profile_sect stat">
              <div className="profile_sect_hood">
                <h6 className="h_7">İstatistikler</h6>
              </div>
              <div className="cards">
                {this.props.stats ? (
                  Object.entries(this.props.stats)
                    .slice(0, 8)
                    .map(([key, value], i) => (
                      <StatCard key={i} value={[key, value]} />
                    ))
                ) : (
                  <MiniPreload />
                )}
                {/* <StatCard hood="Toplam Tahmin" quant="152" />
                <StatCard hood="Toplam Tahmin" quant="152" />
                <StatCard hood="Toplam Tahmin" quant="152" />
                <StatCard hood="Toplam Tahmin" quant="152" />
                <StatCard hood="Toplam Tahmin" quant="152" /> */}
              </div>
            </div>
            <div className="profile_sect ach">
              <div className="profile_sect_hood">
                <h6 className="h_7">Başarımlarım</h6>
              </div>
              <div className="cards">
                {this.props.achievements ? (
                  this.props.achievements.achievements
                    .reduce((acc, ach) => {
                      const achCards = ach.level_counters.map((counter, i) => (
                        <AchievementCard
                          key={`${ach.achievement_id}-${i}`}
                          ach={ach}
                          id={i}
                        />
                      ));
                      return acc.concat(achCards);
                    }, [])
                    .slice(0, 8)
                ) : (
                  <MiniPreload />
                )}
              </div>
            </div>
            <div className="profile_sect point_history">
              <div className="profile_sect_hood">
                <h6 className="h_7">Puan Geçmişim</h6>
              </div>
              {this.props.pointHistory ? (
                this.props.pointHistory.points_history.length > 0 ? (
                  <table className="point_history_table">
                    <thead>
                      <tr>
                        <th className="b_1">ID</th>
                        <th className="b_1">Tarih</th>
                        <th className="b_1 --b">Tür</th>
                        <th className="b_1">Puan</th>
                        <th className="b_1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.pointHistory.points_history
                        .slice(
                          0,
                          Math.min(
                            5,
                            this.props.pointHistory.points_history.length
                          )
                        )
                        .map((point, index) => (
                          <tr key={index}>
                            <td className="b_1 id">{`#${point.id}`}</td>
                            <td className="b_1">
                              {appJs.formatDate(point.created_at)}
                            </td>
                            <td className="b_1 --b">
                              {appJs.pointHistoryFormatType(point.reason)}
                            </td>
                            <td className="b_1 point">{`+${appJs.formatPoint(
                              point.points
                            )} Puan`}</td>
                            <td className="b_1">
                              <figure>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 320 512"
                                >
                                  <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                                </svg>
                              </figure>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no_data">
                    <p className="b_1 --b" style={{ width: "100%" }}>
                      Henüz Puan Geçmişiniz Bulunmamaktadır.
                    </p>
                  </div>
                )
              ) : (
                <MiniPreload />
              )}
            </div>
          </>
        ) : (
          <MiniPreload />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // predictions: state.predictionsReducer,
    // basket: state.basketReducer,
    // token: state.registerorLoginReducer,
    userDetails: state.getUserDetailsReducer,
    stats: state.getStatsReducer,
    achievements: state.getAchievementsReducer,
    pointHistory: state.getPointHistoryReducer,
    token: state.registerorLoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getStats: bindActionCreators(userActions.getStats, dispatch),
      getAchievements: bindActionCreators(
        userActions.getAchievements,
        dispatch
      ),
      getPointHistory: bindActionCreators(
        userActions.getPointHistory,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
