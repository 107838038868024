import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { withRouter } from "../withRouter";
import * as matchActions from "../../redux/actions/matchActions";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as appJs from "../../js/app";
import MainAdsorImg from "../components/MainAdsorImg";
import Leagues from "../leagues/Leagues";
import MiniPreload from "../preload/MiniPreload";
import ModalPrediction from "../modals/ModalPrediction";
import Match from "../components/matches/Match";

class Matches extends Component {
  componentDidMount() {
    appJs.loadScrollPos();
    window.scrollTo(0, window.history.state.scrollY);

    this.props.actions.getBasket(this.props.token);
    this.props.actions.getMatches(
      window.location.search.split("=")[1],
      this.props.token
    );
  }

  render() {
    return (
      <div className="matches_page">
        <MainAdsorImg />
        <div className="matches_filter"></div>
        {!this.props.matches ||
        !this.props.matches.matches ||
        this.props.matches.status !== 200 ||
        !this.props.basket ||
        !this.props.basket.result ||
        this.props.basket.status !== 200 ? (
          <MiniPreload />
        ) : this.props.matches.matches.length > 0 ? (
          <div className="matches_content">
            <h5 className="h_5 hood">
              {window.location.search.split("=")[1]
                ? this.props.matches.matches[0].league
                : "Tüm Maçlar"}
            </h5>
            <div className="matches">
              {this.props.matches.matches.map((match) => (
                <Match
                  match={match}
                  basket={this.props.basket}
                  deleteToBasket={this.props.actions.deleteToBasket}
                  token={this.props.token}
                  key={match.id}
                  user={this.props.userDetails}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="matches_content no">
            <h3 className="h_3">Burada Henüz Maç Görünmüyor!</h3>
            <h6 className="h_8">Başka Maçlara Bakmaya Ne Dersin?</h6>
            <figure>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
              </svg>
            </figure>
          </div>
        )}

        {this.props.matches.status === 200 &&
        this.props.matches.matches.length > 0 ? (
          <MainAdsorImg />
        ) : (
          <></>
        )}
        {this.props.matches.status === 200 ? <Leagues /> : <></>}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getMatches: bindActionCreators(matchActions.getUpcomingMatches, dispatch),
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
      deleteToBasket: bindActionCreators(
        predictionActions.deleteToBasket,
        dispatch
      ),
    },
  };
}

function mapStateToProps(state) {
  return {
    matches: state.matchesListReducer,
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
    userDetails: state.getUserDetailsReducer,
    // predictions: state.predictionsReducer,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Matches));
