import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function getStatsReducer(state = initialState.stats, action) {
  switch (action.type) {
    case actionTypes.GET_STATS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
