import React, { Component } from "react";
import * as appJs from "../../../js/app";
import ModalPrediction from "../../modals/ModalPrediction";

export default class Match extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      selectedMatch: null,
      btnLoad: false,
    };
  }

  inBasket(match) {
    if (
      this.props.basket.result &&
      this.props.basket.result.basket &&
      this.props.basket.result.basket.some(
        (matchP) => matchP.match_id === match.id
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  openModal = (match) => {
    appJs.saveScrollPos();
    this.setState({
      isModalOpen: true,
      selectedMatch: match,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      selectedMatch: null,
    });
  };

  handleKeyDownCloseModal = (e) => {
    if (e.key === "Escape") {
      this.closeModal();
    }
  };

  deleteToBasket = (matchId) => {
    this.setState({ btnLoad: matchId });
    this.props
      .deleteToBasket({ match_id: matchId }, this.props.token)
      .then(() => {
        this.setState({ btnLoad: false });
        window.scrollTo(0, 0);
        window.location.reload();
      });
  };

  percentageCalc(total, number) {
    if (total !== 0) return ((100 * number) / total).toFixed(2);
    return 0;
  }

  // saveScroll = () => {
  //   window.history.replaceState({ scrollY: window.scrollY }, "");
  // };

  render() {
    const { isModalOpen, selectedMatch } = this.state;
    const {
      match,
      isPrediction,
      isPackageDetail,
      basket,
      deleteToBasket,
      token,
    } = this.props;

    return (
      <>
        {!isPrediction && !isPackageDetail && isModalOpen && (
          <ModalPrediction
            match={selectedMatch}
            handleKey={this.handleKeyDownCloseModal}
            closeModal={this.closeModal}
            user={this.props.user}
          />
        )}
        {/* {!isPrediction && basket.result && ( */}
        <div
          className={`match_component ${
            !isPrediction && !isPackageDetail && this.inBasket(match)
              ? "added"
              : ""
          } ${this.props.class ? this.props.class : ""} ${
            isPrediction || isPackageDetail ? "mini_gap" : ""
          }`}
          // style={
          //   (isPrediction || isPackageDetail) && {
          //     width: "calc((100% / 2) - (36px / 2))",
          //   }
          // }
        >
          {match.existing_bets > 0 && (
            <div
              className="existing_info"
              style={match.is_premium ? { left: "50px" } : {}}
            >
              <figure>
                <p className="b_4">{match.existing_bets}</p>
              </figure>
              <span className="modal b_4 non_select">
                Bu Maça Daha Önce {match.existing_bets} Tahmin Yaptınız
              </span>
            </div>
          )}
          {match.is_premium && (
            <span className="premium">
              <figure className="non_select--img">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6l277.2 0c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" />
                </svg>
              </figure>
            </span>
          )}
          {!isPrediction && !isPackageDetail && this.inBasket(match)
            ? this.props.basket.result.basket.map((matchP) =>
                matchP.match_id === match.id ? (
                  <button
                    className="background_btn btn--delete_to_basket"
                    onClick={() => this.deleteToBasket(matchP.match_id)}
                    disabled={
                      this.state.btnLoad === matchP.match_id ? true : false
                    }
                    data-loader={
                      this.state.btnLoad === matchP.match_id ? true : false
                    }
                    key={matchP.match_id}
                  >
                    <span className="btn-loader s"></span>
                    {!this.state.btnLoad ? (
                      <figure>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                        </svg>
                      </figure>
                    ) : null}
                  </button>
                ) : null
              )
            : null}
          <div className="match_content">
            {/* <h5 className="h_8">
              {match.canceled
                ? "İptal Edildi"
                : `${appJs.matchFormatDate(match.date)}`}
            </h5> */}
            <div className="teams">
              <div className="team_names">
                <div className="team left">
                  <h6 className="h_8">{match.home_short_name}</h6>
                  <div className="team_last_matches left">
                    <p className="b_4">Son 5 Maç</p>
                    <div className="data">
                      {appJs
                        .formatLastMatch(
                          match.home_last_5_matches !== null
                            ? match.home_last_5_matches
                            : "NNNNN"
                        )
                        .map((match, i) => (
                          <span className={"match_info " + match} key={i}>
                            <span className="modal non_select b_4">
                              {`${i + 1}- ${
                                match === "W"
                                  ? "Kazanan"
                                  : match === "L"
                                  ? "Kaybeden"
                                  : match === "D"
                                  ? "Berabere"
                                  : "Maç Yapılmamış"
                              }`}
                            </span>
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
                <span className="vs">
                  <h5 className="h_8">
                    {match.canceled
                      ? "İptal Edildi"
                      : `${appJs.matchFormatDate(match.date)}`}
                  </h5>
                  <p className="b_1 --b">
                    {!match.completed
                      ? "VS"
                      : !isPackageDetail
                      ? "VS"
                      : `${match.home_score} - ${match.away_score}`}
                  </p>
                </span>
                <div className="team right">
                  <h6 className="h_8">{match.away_short_name}</h6>
                  <div className="team_last_matches right">
                    <p className="b_4">Son 5 Maç</p>
                    <div className="data">
                      {appJs
                        .formatLastMatch(
                          match.away_last_5_matches !== null
                            ? match.away_last_5_matches
                            : "NNNNN"
                        )
                        .map((match, i) => (
                          <span className={"match_info " + match} key={i}>
                            <span className="modal non_select b_4">
                              {`${i + 1}- ${
                                match === "W"
                                  ? "Kazanan"
                                  : match === "L"
                                  ? "Kaybeden"
                                  : match === "D"
                                  ? "Berabere"
                                  : "Maç Yapılmamış"
                              }`}
                            </span>
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!isPrediction && !isPackageDetail && (
              <div className="statistics">
                <p className="b_2 --m non_select">
                  {match.total_bet_count === 0
                    ? "Hiç Tahmin Yapılmadı"
                    : `${match.total_bet_count} Tahmin Yapıldı`}
                </p>
                <div className="statistic">
                  <p className="b_3 --b non_select">
                    %
                    {this.percentageCalc(
                      match.total_bet_count,
                      match.home_bet_count
                    )}
                    <span>Ev</span>
                  </p>
                  <p className="b_3 --b non_select">
                    %
                    {this.percentageCalc(
                      match.total_bet_count,
                      match.draw_bet_count
                    )}
                    <span>Berabere</span>
                  </p>
                  <p className="b_3 --b non_select">
                    %
                    {this.percentageCalc(
                      match.total_bet_count,
                      match.away_bet_count
                    )}
                    <span>Deplasman</span>
                  </p>
                  <p className="b_3 --b non_select">
                    %
                    {this.percentageCalc(
                      match.total_bet_count,
                      match.score_bet_count
                    )}
                    <span>Skor</span>
                  </p>
                </div>
              </div>
            )}
          </div>
          {!isPrediction && !isPackageDetail && (
            <button
              className="background_btn"
              onClick={() => this.openModal(match)}
              disabled={this.inBasket(match)}
            >
              <span className="btn-loader"></span>
              <p className="b_3 --b">
                {this.inBasket(match)
                  ? this.props.basket.result.basket.map((matchP) =>
                      matchP.match_id === match.id
                        ? appJs.formatMatchSelection(
                            matchP.bet_type,
                            matchP.bet_home_score,
                            matchP.bet_away_score
                          )
                        : null
                    )
                  : "Tahmin Yap"}
              </p>
            </button>
          )}

          {(isPrediction || isPackageDetail) && (
            <div className="selected_and_delete">
              <div className="selected_and_point">
                <div className="prediction_selected">
                  <p className="h_8">
                    Tahminin
                    <span className="h_7">
                      {appJs.formatMatchSelection(
                        match.bet_type,
                        match.bet_home_score,
                        match.bet_away_score
                      )}
                    </span>
                  </p>
                </div>
                <div className="prediction_point">
                  <p className="h_8">
                    Kazanılacak Puan
                    <span className="h_7">{match.points}</span>
                  </p>
                </div>
              </div>
              {!isPackageDetail && (
                <div className="delete">
                  <button
                    className="background_btn"
                    onClick={() => this.deleteToBasket(match.match_id)}
                    disabled={
                      this.state.btnLoad === match.match_id ? true : false
                    }
                    data-loader={
                      this.state.btnLoad === match.match_id ? true : false
                    }
                  >
                    <span className="btn-loader s"></span>
                    <p className="b_3">Sil</p>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {/* )} */}
      </>
    );
  }
}
