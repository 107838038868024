import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ProgressBar from "../components/ProgressBar";
import * as AppJs from "../../js/app";
import * as predictionActions from "../../redux/actions/predictionActions";
import * as userActions from "../../redux/actions/userActions";
import {
  URL_MATCHES,
  URL_PACKAGES,
  URL_PREDICTION,
  URL_PROFILE,
} from "../../config";

class NavigationMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: window.location.pathname,
    };
  }

  componentDidMount() {
    if (AppJs.tokenControl(this.props.token)) {
      this.props.actions.getBasket(this.props.token);
      if (this.props.userDetails.length <= 0) {
        this.props.actions.getUserDetails(this.props.token);
      }
    }
  }

  handleLogOut = () => {
    this.props.actions.logout();
  };

  render() {
    const { currentPath } = this.state;

    return (
      <nav className="mobile_nav">
        <Link
          to={"/"}
          className={`${currentPath === "/" ? "active" : ""}`}
          reloadDocument
        >
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 170">
              <path d="M15.1,154.4h33.4v-59.3h53v59.3h33.4v-89.9l-59.9-45.1L15.1,64.5v89.9ZM.1,169.3V57L75,.7l74.9,56.4v112.3h-63.4v-59.3h-23v59.3H.1Z" />
            </svg>
          </figure>
        </Link>
        <Link
          to={URL_MATCHES}
          className={`${currentPath.startsWith(URL_MATCHES) ? "active" : ""}`}
          reloadDocument
        >
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
              <path d="M480-100q-78.77 0-148.11-29.96-69.35-29.96-120.66-81.27-51.31-51.31-81.27-120.66Q100-401.23 100-480q0-78.77 29.96-148.11 29.96-69.35 81.27-120.66 51.31-51.31 120.66-81.27Q401.23-860 480-860q78.77 0 148.11 29.96 69.35 29.96 120.66 81.27 51.31 51.31 81.27 120.66Q860-558.77 860-480q0 78.77-29.96 148.11-29.96 69.35-81.27 120.66-51.31 51.31-120.66 81.27Q558.77-100 480-100Zm193.08-468.46 61.69-20.31 17.54-60.92q-32-49.93-78.93-85.39-46.92-35.46-104-52.46L510-745.69v62.92l163.08 114.31Zm-386.16 0L450-682.77v-62.92l-59.38-41.85q-57.08 17-103.62 52.46-46.54 35.46-78.54 85.39L226-588.77l60.92 20.31Zm-47.38 299.54 54.46-4.77 34.62-59.39-58.77-177.07-63.7-22.31L160-498.62q0 67.7 17.62 122.54 17.61 54.85 61.92 107.16ZM480-160q26 0 51.77-4.19 25.77-4.19 52.08-12.58l31.07-66.92-30.61-50.93H375.69l-29.84 50.93 31.07 66.92q25.16 8.39 51.12 12.58Q454-160 480-160Zm-93.85-194.61h188.47l57.53-169.24L480-630.46 329.08-523.85l57.07 169.24Zm334.31 85.69q44.31-52.31 61.92-107.16Q800-430.92 800-498.62l-45.39-31.84-64.46 19.54-58.77 177.84L666-273.69l54.46 4.77Z" />
            </svg>
          </figure>
        </Link>
        <Link
          to={URL_PREDICTION}
          className={`${
            currentPath.startsWith(URL_PREDICTION) ? "active" : ""
          }`}
          reloadDocument
        >
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
              <path d="m480-374.62 191.54-121.53L480-617.69v243.07ZM132.31-116.16q-30.31 0-51.31-21-21-21-21-51.3v-403.08h60v403.08q0 4.61 3.85 8.46 3.84 3.84 8.46 3.84H770v60H132.31Zm140-140q-30.31 0-51.31-20.99-21-21-21-51.31v-407.69h206.15v-71.54q0-30.31 21-51.31 21-21 51.31-21h143.08q30.3 0 51.3 21 21 21 21 51.31v71.54H900v407.69q0 30.31-21 51.31-21 20.99-51.31 20.99H272.31Zm0-59.99h555.38q4.62 0 8.46-3.85 3.85-3.85 3.85-8.46v-347.69H260v347.69q0 4.61 3.84 8.46 3.85 3.85 8.47 3.85Zm193.84-420h167.7v-71.54q0-4.62-3.85-8.46-3.85-3.85-8.46-3.85H478.46q-4.61 0-8.46 3.85-3.85 3.84-3.85 8.46v71.54ZM260-316.15v-360 360Z" />
            </svg>
          </figure>
        </Link>
        <Link to={URL_PROFILE} className={`profile_photo`} reloadDocument>
          <figure>
            <img src="/media/profile_photos/default.png" alt="user" />
          </figure>
          <div className="point">
            <p className="b_4 --b non_select">
              {AppJs.formatPoint(this.props.userDetails.total_points)}/1M
            </p>
            <ProgressBar
              point={this.props.userDetails.total_points}
              percent={1000000}
            />
          </div>
        </Link>
        <Link
          to={URL_PACKAGES}
          className={`${currentPath.startsWith(URL_PACKAGES) ? "active" : ""}`}
          reloadDocument
        >
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 190">
              <path d="M77.6,170.1v-70.8L15.6,63.4v69.1c0,.5.1,1,.4,1.4.3.4.6.8,1.1,1.1l60.5,35ZM92.4,170.1l60.5-35c.5-.3.9-.7,1.1-1.1.3-.4.4-.9.4-1.4V63.4l-62,35.9s0,70.8,0,70.8ZM85,86.5l61.3-35.4-59.7-34.5c-.5-.3-1-.5-1.5-.5s-1,.2-1.5.5L23.7,51.1s61.3,35.4,61.3,35.4ZM9.7,148.1c-2.8-1.6-5-3.8-6.6-6.5-1.6-2.7-2.4-5.7-2.4-9V57.4c0-3.3.8-6.2,2.4-9,1.6-2.7,3.8-4.9,6.6-6.5L76,3.7c2.8-1.6,5.8-2.4,9-2.4,3.2,0,6.1.8,9,2.4l66.4,38.2c2.8,1.6,5,3.8,6.6,6.5,1.6,2.7,2.4,5.7,2.4,9v75.2c0,3.3-.8,6.2-2.4,9-1.6,2.7-3.8,4.9-6.6,6.5l-66.4,38.2c-2.8,1.6-5.8,2.4-9,2.4-3.2,0-6.1-.8-9-2.4L9.7,148.1Z" />
            </svg>
          </figure>
        </Link>
        <Link>
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 170">
              <path d="M42.1,169.6v-14.9h35.4v-34.1c-8.4-1.7-15.9-5.2-22.4-10.7-6.5-5.4-11.1-12.2-13.8-20.2-11.5-1.4-21.2-6.2-29.1-14.6C4.4,66.7.4,56.7.4,45.2v-10c0-4.1,1.5-7.6,4.4-10.5,2.9-2.9,6.4-4.4,10.5-4.4h23.2V.4h93v19.9h23.2c4.1,0,7.6,1.5,10.5,4.4s4.4,6.4,4.4,10.5v10c0,11.5-3.9,21.5-11.8,29.9-7.9,8.4-17.6,13.3-29.1,14.6-2.7,8.1-7.3,14.8-13.8,20.2-6.5,5.4-13.9,9-22.4,10.7v34.1h35.4v14.9H42.1ZM38.5,73.6v-38.4H15.3v10c0,6.9,2.2,13,6.5,18.3,4.4,5.2,9.9,8.6,16.6,10.2ZM85,106.2c8.7,0,16.2-3,22.2-9.1,6.1-6.1,9.1-13.5,9.1-22.2V15.3h-62.8v59.5c0,8.7,3,16.2,9.1,22.2,6.1,6.1,13.5,9.1,22.2,9.1ZM131.5,73.6c6.7-1.5,12.3-4.9,16.6-10.2,4.4-5.2,6.5-11.3,6.5-18.3v-10h-23.2v38.4Z" />
            </svg>
          </figure>
        </Link>
        <Link>
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159 212">
              <path d="M.5,177.5v-15.8h19v-78.6c0-14.2,4.4-26.7,13.1-37.6,8.7-10.9,20-17.8,33.7-20.9v-6.6c0-3.7,1.3-6.8,3.8-9.3,2.6-2.6,5.7-3.8,9.3-3.8s6.8,1.3,9.3,3.8c2.6,2.6,3.8,5.7,3.8,9.3v6.6c13.7,3,24.9,10,33.7,20.9,8.7,10.9,13.1,23.4,13.1,37.6v78.6h19v15.8s-157.9,0-157.9,0ZM79.5,207c-5.2,0-9.7-1.9-13.4-5.6-3.7-3.7-5.6-8.2-5.6-13.4h38.1c0,5.3-1.9,9.7-5.6,13.5-3.7,3.7-8.2,5.6-13.5,5.6ZM35.4,161.7h88.3v-78.6c0-12.2-4.3-22.6-12.9-31.2-8.6-8.6-19-12.9-31.2-12.9s-22.6,4.3-31.2,12.9c-8.6,8.6-12.9,19-12.9,31.2,0,0,0,78.6,0,78.6Z" />
            </svg>
          </figure>
        </Link>
        {/* <Link className="power_off" onClick={this.handleLogOut}>
          <figure>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190 190">
              <path d="M95,189.5c-13.1,0-25.4-2.5-36.9-7.4-11.5-5-21.5-11.7-30-20.2-8.5-8.5-15.2-18.5-20.2-30C3,120.4.5,108.1.5,95s2.5-25.4,7.4-36.9c4.9-11.4,11.7-21.4,20.3-30l10.5,10.5c-7.3,7.3-13,15.7-17.1,25.3-4.1,9.6-6.1,19.9-6.1,31,0,22.2,7.7,41,23.1,56.4,15.4,15.4,34.2,23.1,56.4,23.1s41-7.7,56.4-23.1c15.4-15.4,23.1-34.2,23.1-56.4s-2-21.5-6.1-31c-4.1-9.6-9.8-18-17.1-25.3l10.5-10.5c8.6,8.6,15.3,18.6,20.3,30,4.9,11.4,7.4,23.7,7.4,36.9s-2.5,25.3-7.4,36.8c-5,11.5-11.7,21.5-20.2,30-8.5,8.5-18.5,15.2-30,20.2-11.5,5-23.8,7.4-36.8,7.4ZM87.5,104.9V.5h14.9v104.4h-14.9Z" />
            </svg>
          </figure>
        </Link> */}
      </nav>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBasket: bindActionCreators(predictionActions.getBasket, dispatch),
      getUserDetails: bindActionCreators(userActions.userDetails, dispatch),
      logout: bindActionCreators(userActions.logout, dispatch),
    },
  };
}

function mapStateToProps(state) {
  return {
    // predictions: state.predictionsReducer,
    basket: state.basketReducer,
    token: state.registerorLoginReducer,
    userDetails: state.getUserDetailsReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMobile);
